import React from "react";
import {
  Flex,
  IconButton,
  Editable,
  EditablePreview,
  EditableInput,
  ButtonGroup,
  HStack,
} from "@chakra-ui/react";
import { useEditableControls } from "@chakra-ui/react";
import { EditIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateParishInformation } from "../../features/dataAll/dataBullMyPSlice";
import { getData } from "../../features/user/userSlice";
import { useToast } from "@chakra-ui/toast";
import { notificationData } from "../../util/functions";
import { NotifyIndex } from "../notification/notifyIndex";
import { BiErrorCircle, BiCheckDouble, BiSave } from "react-icons/bi";
import { logoutUser } from "../../features/user/userSlice";
import { useSelector } from "react-redux";

export const InfoEditableInput = (props) => {
  const isUpdating = useSelector((state) => state.dataBullMyP.statusUpdate);
  const [value, setValue] = useState("");
  let { val, name } = props;
  const dispatch = useDispatch();
  const toast = useToast();

  useEffect(() => {
    setValue(val);
  }, []);
  const credentials = useSelector(
    (state) => state.user.credentials.credentials
  );
  const handleButtonCheck = (e) => {
    let updateValue = {
      name: name,
      val: value,
    };
    dispatch(updateParishInformation(updateValue))
      .unwrap()
      .then((originalPromiseResult) => {
        const notificationErrorData = notificationData(
          "Parish Information",
          "Update Complete",
          "green",
          BiCheckDouble
        );

        toast({
          position: "bottom-left",
          render: () => (
            <div>
              {" "}
              <NotifyIndex notificationdata={notificationErrorData} />
            </div>
          ),
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Parish Information",
            rejectedValueOrSerializedError,
            "red",
            BiErrorCircle
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
        } else if (
          rejectedValueOrSerializedError?.code == "auth/id-token-expired"
        ) {
          dispatch(logoutUser());
        }
      });
  };

  const handleOnChange = (e) => {
    setValue(e);
  };
  const handleButtonClose = () => {
    setValue(val);
  };
  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton
          variant={"ghost"}
          _focus={{ boxShadow: "none" }}
          color="brand.500"
          icon={<CheckIcon />}
          {...getSubmitButtonProps()}
          // onClick={handleButtonCheck}
        />
        <IconButton icon={<CloseIcon />} {...getCancelButtonProps()} />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center">
        <IconButton
          variant={"ghost"}
          _focus={{ boxShadow: "none" }}
          color="brand.200"
          size="sm"
          icon={<EditIcon />}
          {...getEditButtonProps()}
        />
      </Flex>
    );
  }

  return (
    <Editable
      textAlign="center"
      value={value}
      onSubmit={handleButtonCheck}
      onCancel={handleButtonClose}
      onChange={handleOnChange}
      fontSize="md"
      borderRadius={"2"}
      isPreviewFocusable={false}
    >
      <HStack>
        <EditablePreview borderRadius={"2"} bg={"brand.50"} width={"full"} />
        <EditableInput borderRadius={"2"} _focus={{ boxShadow: "xs" }} />
        <EditableControls />
      </HStack>
    </Editable>
  );
};
