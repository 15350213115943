import React, { useEffect } from "react";
import {
  Flex,
  Box,
  Text,
  Icon,
  SlideFade,
  HStack,
  IconButton,
  Stack,
  Input,
  FormControl,
  Button,
  SimpleGrid,
  Spinner,
  Heading,
  useColorModeValue as mode,
  Skeleton,
  Divider,
  Link,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Redirect, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { RiMenuFoldFill, RiMenuUnfoldFill } from "react-icons/ri";
import { useForm } from "react-hook-form";
import { useToast } from "@chakra-ui/toast";
import { notificationData, OccassionDate } from "../util/functions";
import { NotifyIndex } from "../components/notification/notifyIndex";
import { BiErrorCircle, BiCheckDouble } from "react-icons/bi";
import { logoutUser } from "../features/user/userSlice";
import { SkeletonMessage } from "../components/common/skeleton";
import {
  getOccassion,
  removeOccassion,
} from "../features/dataAll/dataMegAnnEvtSlice";
import {
  getBulletin,
  isErrorBulletin,
  removeBulletinError,
  sendBulletin,
  sendPDF,
} from "../features/dataAll/dataBullMyPSlice";
import { SentBulletin } from "../components/bulletin/sentBulletin";

export function Bulletin() {
  const isError = useSelector((state) => state.dataBullMyP.errorBulletin);
  const isGetPending = useSelector((state) => state.dataBullMyP.statusGet);
  const isStatusOccassion = useSelector(
    (state) => state.dataMegAnnEvt.statusGetOccassion
  );
  const isformSubmitting = useSelector(
    (state) => state.dataBullMyP.statusBulletin
  );
  const isOccassion = useSelector((state) => state.dataMegAnnEvt.occassion);
  const userSubcription = useSelector(
    (state) => state.user.credentials.subscription
  );

  const [showPanel, setShowPanel] = useState(true);
  const [bullSundayDate, setBullSundayDate] = useState(null);
  const [pdfName, setPDFName] = useState(null);
  const [isDisabledSendBulletin, setIsDisabledSendBulletin] = useState(false);

  // const { handleSubmit } = useForm();

  const toast = useToast();
  // const history = useHistory();

  const dispatch = useDispatch();
  const { search } = useLocation();
  const match = search.match(/status=(.*)/);
  const status = match?.[1];

  useEffect(() => {
    dispatch(getBulletin());
  }, []);

  const base1 = () => {
    if (showPanel === true) {
      return "block";
    } else {
      return "none";
    }
  };

  const base2 = () => {
    if (showPanel === true) {
      return "none";
    } else {
      return "block";
    }
  };

  function rightSideOnclick() {
    setShowPanel(false);
  }

  function rightSideOffclick() {
    setShowPanel(true);
  }
  function onSubmitClick() {
    //e.preventDefault();
    const formData = new FormData();
    const theSundayDate = bullSundayDate == null ? "" : bullSundayDate;
    const occassion = isOccassion;
    const occassionString = occassion.toString();
    const newBulletin = {
      sundayDate: theSundayDate,
      occassion: occassionString,
    };
    // const selectedPdf = pdfName.blob();
    //var blob = new Blob([pdfName], { type: pdfName?.type });
    //formData.append(blob, pdfName?.name);
    console.log(pdfName.size);
    if (newBulletin.sundayDate == "") {
      dispatch(isErrorBulletin({ sundayDate: "Sunday Date cannot be empty" }));
    } else if (newBulletin.sundayDate.getDay() != 0) {
      dispatch(isErrorBulletin({ sundayDate: "Should be a Sunday Date" }));
    } else if (pdfName === null) {
      dispatch(isErrorBulletin({ pdf: "Load File" }));
    } else if (pdfName.type !== "application/pdf") {
      dispatch(isErrorBulletin({ pdf: "File Should only be PDF" }));
    } else if (pdfName.size > 2097152) {
      dispatch(
        isErrorBulletin({ pdf: "File Size should not be more than 2MB" })
      );
    } else {
      //console.log(formData.files.size);
      formData.append("pdf", pdfName, pdfName.name);
      setIsDisabledSendBulletin(true);
      dispatch(sendBulletin(newBulletin))
        .unwrap()
        .then((originalPromiseResult) => {
          formData.bulletinId = originalPromiseResult;
          dispatch(sendPDF(formData))
            .unwrap()
            .then((originalPromiseResult) => {
              const notificationErrorData = notificationData(
                "Sunday Bulletin",
                "Sent",
                "green",
                BiCheckDouble
              );
              toast({
                position: "bottom-left",
                render: () => (
                  <div>
                    {" "}
                    <NotifyIndex notificationdata={notificationErrorData} />
                  </div>
                ),
              });
              setBullSundayDate(null);
              setPDFName(null);
              dispatch(removeOccassion());

              document.getElementById("fileid").value = null;
              setIsDisabledSendBulletin(false);
              // history.pushState("/bulletin?status=bulletin");
            })
            .catch((rejectedValueOrSerializedError) => {});
        })
        .catch((rejectedValueOrSerializedError) => {
          if (typeof rejectedValueOrSerializedError != "object") {
            const notificationErrorData = notificationData(
              "Sunday Bulletin",
              rejectedValueOrSerializedError,
              "red",
              BiErrorCircle
            );
            toast({
              position: "bottom-left",
              render: () => (
                <div>
                  {" "}
                  <NotifyIndex notificationdata={notificationErrorData} />
                </div>
              ),
            });
            setIsDisabledSendBulletin(false);
          } else if (
            rejectedValueOrSerializedError?.code == "auth/id-token-expired"
          ) {
            dispatch(logoutUser());
            //IsBackEndSessionExpired();
          }
        });
    }
  }

  function HandleSundayDate(e) {
    setBullSundayDate(e);
    if (e.getDay() != 0) {
      dispatch(removeOccassion());
      dispatch(isErrorBulletin({ sundayDate: "Should be a Sunday Date" }));
    } else {
      dispatch(removeBulletinError());
      const theData = {
        sundayDate: OccassionDate(e),
      };
      dispatch(getOccassion(theData))
        .unwrap()
        .then((originalPromiseResult) => {})
        .catch((rejectedValueOrSerializedError) => {});
    }
  }
  const uploadClick = (e) => {
    const theFileName = document.getElementById("fileid");
    theFileName.click();
  };

  const handleFileNameChange = () => {
    const theFileName = document.getElementById("fileid");
    if (theFileName.files[0].type === "application/pdf") {
      setPDFName(theFileName.files[0]);
      dispatch(removeBulletinError());
    } else {
      dispatch(isErrorBulletin({ pdf: "File Should only be PDF" }));
      // let selectedPdf = theFileName.files[0];
      // setPDFName(selectedPdf);
      // setPDFName(theFileName.value.split("\\").pop());
    }
  };

  return (
    <div>
      {status === "bulletin" && userSubcription?.bulletin ? (
        <Flex>
          <Flex
            flex="1"
            bg="brand.20"
            h={{ base: "94vh", md: "90vh" }}
            display={{
              base: base1,
              md: "block",
            }}
          >
            <Box rounded="base" h="full" w="full">
              <Box py="2" bg="white" boxShadow={"sm"}>
                <HStack height={{ base: "16", md: "12" }}>
                  <Box display="flex" justifyContent={"center"} width="full">
                    <Heading size={"sm"} color="brand.800">
                      Send Sunday Bulletin to Parishioners
                    </Heading>
                  </Box>

                  <IconButton
                    size="lg"
                    colorScheme="brand.100"
                    onClick={rightSideOnclick}
                    display={{ md: "none" }}
                  >
                    <Icon color="brand.900" as={RiMenuFoldFill}></Icon>
                  </IconButton>
                </HStack>
              </Box>
              <Box m="0.5" h="full" bg="white">
                <Box pl="10" pr="10" pt="4">
                  <Box
                    m="0.5"
                    h={{ base: "70vh", md: "60vh" }}
                    overflowY="auto"
                  >
                    <Stack>
                      <FormControl>
                        <SimpleGrid
                          columns={{ base: 1, md: 2 }}
                          spacing={{ base: 4, md: 10 }}
                          mb={{ base: "0", md: "2" }}
                          mt={{ base: "2", md: "2" }}
                        >
                          <Stack>
                            {
                              <Text as="sub" color={"red.500"}>
                                {isError?.sundayDate}
                                {isError?.occassion}
                              </Text>
                            }
                            <Box
                              borderColor={"gray.300"}
                              borderWidth={"1px"}
                              maxWidth={"fit-content"}
                            >
                              <DatePicker
                                selected={bullSundayDate}
                                // onChange={(date) => setBullSundayDate(date)}
                                onChange={HandleSundayDate}
                                placeholderText="Select Sunday Date"
                              />
                            </Box>
                          </Stack>

                          <Box
                            justifySelf={{ base: "normal", md: "flex-end" }}
                          ></Box>
                        </SimpleGrid>
                      </FormControl>
                      <FormControl>
                        <Box
                          fontSize={"sm"}
                          color={"geen.500"}
                          mb={{ base: "4", md: "4" }}
                        >
                          {isStatusOccassion == "pending" ? (
                            <Skeleton height="20px" />
                          ) : (
                            isOccassion
                          )}
                        </Box>
                      </FormControl>
                      <HStack justify={"space-between"} spacing={"4"}>
                        <Button type="file" name="meee" onClick={uploadClick}>
                          Upload PDF
                        </Button>
                        <Stack>
                          <Text as="sub" color={"red.500"}>
                            {" "}
                            {isError?.pdf}
                          </Text>
                          <Text noOfLines={1}> {pdfName?.name}</Text>
                        </Stack>
                      </HStack>
                      <Box display={"none"}>
                        {/* <label for="file">Choose file to upload</label> */}
                        <input
                          onChange={handleFileNameChange}
                          type="file"
                          name="you"
                          id="fileid"
                        ></input>
                      </Box>
                      <Button
                        size="lg"
                        type="submit"
                        w="full"
                        bg="brand.700"
                        colorScheme="white"
                        fontSize="md"
                        fontWeight="bold"
                        disabled={isDisabledSendBulletin}
                        onClick={onSubmitClick}
                      >
                        {isformSubmitting === "pending" ? (
                          <Spinner size="xs" />
                        ) : (
                          "SEND "
                        )}
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Flex>

          <Flex
            display={{
              base: base2,
              lg: "block",
            }}
            flex={{ base: "1", md: "none" }}
            // width={{ base: "100vh", md: "10vh", lg: "60vh" }}
            width="96"
            direction="column"
            borderLeftWidth="1px"
            pl={{ base: "10", md: "0" }}
            h="93vh"
          >
            {" "}
            <SlideFade
              offsetY="20px"
              SlideDirection="top"
              in={showPanel == true ? true : true}
            >
              <Box
                rounded="base"
                h={{ base: "88vh", md: "92vh", lg: "92vh" }}
                bg="white"
                shadow={{
                  base: "xl",
                  md: "none",
                }}
                borderRadius={{ base: "none", md: "5" }}
                borderLeftRadius={{ base: "3xl", md: "5" }}
              >
                <Box boxShadow={"sm"} py="2">
                  <HStack height={{ base: "16", md: "12" }}>
                    <Box display="flex" justifyContent={"center"} width="full">
                      <Heading size={"sm"} color="brand.800">
                        Sent Sunday Bulletins
                      </Heading>
                    </Box>

                    <IconButton
                      colorScheme="brand.100"
                      onClick={rightSideOffclick}
                      display={{ md: "none" }}
                      size={"lg"}
                    >
                      <Icon color="brand.900" as={RiMenuUnfoldFill}></Icon>
                    </IconButton>
                  </HStack>
                </Box>
                <Box
                  overflowY="auto"
                  h={{ base: "78vh", md: "84vh", lg: "82vh" }}
                >
                  <Stack m={6}>
                    {isGetPending == "pending" ? (
                      <SkeletonMessage />
                    ) : (
                      <SentBulletin />
                    )}
                  </Stack>
                </Box>
              </Box>
            </SlideFade>
          </Flex>
        </Flex>
      ) : (
        <div>
          <Redirect to="/home"></Redirect>
        </div>
      )}
    </div>
  );
}
