import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ChakraProvider, Box, Flex, useDisclosure } from "@chakra-ui/react";
import MobileTopBar from "./components/MobileTopBar";
import Sidebar from "./components/Sidebar";
import theme from "./theme";
import "../src/theme/styles.css";
import DesktopNavMenu from "./components/DesktopNavMenu";
import Event from "./pages/event";
import SigninPage from "./pages/loginPage";
import { SignupPage } from "./pages/signupPage";
import { ChangePasswordFromEmail } from "./pages/changePasswordFromEmail";
import { ChooseSubcription } from "./pages/chooseSubcription";
import AuthRoute, { AuthRouteLogCon } from "./util/authRoute";
import { logoutUser, getData, authenticated } from "./features/user/userSlice";
import { store } from "./store";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { GetStarted } from "./pages/getStartedPage";
import { ForgotPassword } from "./pages/forgotPasswordPage";
import { SignUpRequest } from "./pages/signupRequestPage";
import IdPageOfSignupRequest from "./components/signupRequest/signupRequestDetails";
import { Home } from "./pages/home";
import { MyParish } from "./pages/myParish";
import { Announcement } from "./pages/announcement";
import { Account } from "./pages/account";
import { Message } from "./pages/message";
import { Bulletin } from "./pages/bulletin";
import PageNotFound from "./pages/pageNotFound";
import {
  getParishSubscribedData,
  getPlanUpdate,
} from "./features/dataAll/dataSubscriptionSlice";
import { Success } from "./pages/success";

const DefaultContainer = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <div>
      <MobileTopBar />
      <Flex flex="1" overflow="hidden">
        <Sidebar display={{ base: "none", md: "flex" }} onClose={onClose} />
        <Box bg="brand.50" w="full" h={{ base: "93vh", md: "100vh" }}>
          <Box
            //F9FCFF
            as="header"
            justify="space-evenly"
            align="center"
            w="full"
            minH="14"
            bg="brand.20"
            px="6"
            display={{ base: "none", md: "flex" }}
            borderBottomWidth="1px"
          >
            <DesktopNavMenu />
          </Box>

          <Box bg="brand.20" w="full" h="full">
            <AuthRoute exact path="/home" component={Home} />
            <AuthRoute exact path="/" component={Home} />
            <AuthRoute exact path="/parishevent" component={Event} />
            <AuthRoute exact path="/myparish" component={MyParish} />
            <AuthRoute exact path="/announcement" component={Announcement} />
            <AuthRoute exact path="/account" component={Account} />
            <AuthRoute exact path="/message" component={Message} />
            <AuthRoute exact path="/bulletin" component={Bulletin} />
            <AuthRoute exact path="/parish/:type" component={SignUpRequest} />
            <AuthRoute
              exact
              path="/parish/:type/:id"
              component={SignUpRequest}
            />
            {/* <AuthRoute exact path="/signupRequest/id/:id" component={IdPageOfSignupRequest} /> */}
            <AuthRoute path="*" component={PageNotFound} />
          </Box>
        </Box>
      </Flex>
    </div>
  );
};

const LoginContainer = () => (
  <div>
    <AuthRouteLogCon exact path="/login" component={SigninPage} />
    <AuthRouteLogCon exact path="/register" component={SignupPage} />
    <AuthRouteLogCon exact path="/getStarted" component={GetStarted} />
    <AuthRouteLogCon
      exact
      path="/requestPasswordReset"
      component={ForgotPassword}
    />
    <AuthRouteLogCon
      exact
      path="/changePassword/:token"
      component={ChangePasswordFromEmail}
    />
    <AuthRouteLogCon
      exact
      path="/chooseSubcription"
      component={ChooseSubcription}
    />
    {/* <Route path="*" component={PageNotFound} /> */}
  </div>
);

const SuccessContainer = () => (
  <div>
    <AuthRoute exact path="/paymentSuccess" component={Success} />

    {/* <Route path="*" component={PageNotFound} /> */}
  </div>
);

function App() {
  const token = localStorage.FBIdToken;
  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      store.dispatch(logoutUser());
      window.location.href = "/login";
    } else {
      store.dispatch(authenticated(true));
      axios.defaults.headers.common["Authorization"] = token;
      store.dispatch(getData());
      store.dispatch(getParishSubscribedData());

      /*
       
      */
    }
  }
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Flex h="100vh" flexDirection="column">
          <Switch>
            <Route exact path="/login" component={LoginContainer} />
            <Route exact path="/register" component={LoginContainer} />
            <Route exact path="/getStarted" component={LoginContainer} />
            <Route exact path="/chooseSubcription" component={LoginContainer} />

            <Route
              exact
              path="/requestPasswordReset"
              component={LoginContainer}
            />
            <Route
              exact
              path="/changePassword/:token"
              component={LoginContainer}
            />
            <Route exact path="/paymentSuccess" component={SuccessContainer} />
            <Route component={DefaultContainer} />
          </Switch>
        </Flex>
      </Router>
    </ChakraProvider>
  );
}

export default App;
