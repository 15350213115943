import {
  extendTheme,
  theme as base,
  withDefaultColorScheme,
  withDefaultVariant,
} from "@chakra-ui/react";

//052F37
//04252C
const theme = extendTheme(
  {
    colors: {
      transparent: "transparent",
      black: "#000",
      white: "#fff",
      //F2F9FF
      brand: {
        10: "#F2FFFA", //light green
        20: "#FBFDFF",
        30: "#E5F1FC", //home card bg
        50: "#F2F9FF", //hover
        100: "#DEEEFD",
        200: "#FA9292", //red
        300: "#055C18", //green
        400: "#C8E2FA", //select
        500: "#0987A0",
        600: "#0987A0",
        700: "#3C85A6",
        800: "#0E455E",
        900: "#053347",
      },
    },
    fonts: {
      heading: `Roboto,${base.fonts?.heading} `,
      body: `inter,${base.fonts.body}`,
    },
    components: {
      Input: {
        variants: {
          outline: {
            field: {
              border: "1px solid",
              _focus: {
                borderColor: "brand.800",
                boxShadow: "none",
              },
            },
          },
        },
        sizes: {
          lg: {
            field: {
              borderRadius: "none",
            },
          },
        },
      },
      Select: {
        variants: {
          outline: {
            field: {
              border: "1px solid",
              _focus: {
                borderColor: "brand.800",
                boxShadow: "none",
              },
            },
          },
        },
        sizes: {
          lg: {
            field: {
              borderRadius: "none",
            },
          },
        },
      },
      Button: {
        baseStyle: {
          borderRadius: "2px",
        },
        variants: {
          solid: {
            _focus: {
              borderColor: "brand.800",
              bg: "brand.700",
              boxShadow: "none",
              color: "white",
            },
            _hover: {
              borderColor: "brand.800",
              bg: "brand.600",
              boxShadow: "none",
              color: "white",
            },
          },
        },
      },
    },
  },
  withDefaultColorScheme({
    colorScheme: "brand",
    components: ["Input"],
  }),
  withDefaultVariant({
    variant: "outline",
    components: ["Input", "Select", "Checkbox"],
  })
);
export default theme;
