import { React } from "react";
import {
  Text,
  HStack,
  IconButton,
  Stack,
  Divider,
  Link,
  useColorModeValue as mode,
  Button,
  Spinner,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";

import { DeleteIcon } from "@chakra-ui/icons";
import { useDisclosure } from "@chakra-ui/hooks";
import { useState } from "react";
import { useToast } from "@chakra-ui/toast";
import {
  ConvertStartEndDate,
  DateConvert,
  notificationData,
} from "../../util/functions";
import { NotifyIndex } from "../notification/notifyIndex";
import { BiErrorCircle, BiCheckDouble } from "react-icons/bi";
import { logoutUser } from "../../features/user/userSlice";
import "react-quill/dist/quill.snow.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  deleteBulletin,
  isSelectedBulletin,
  removeBulletinError,
} from "../../features/dataAll/dataBullMyPSlice";

export const SentBulletin = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bulletins = useSelector((state) => state.dataBullMyP.bulletins);
  const selected = useSelector((state) => state.dataBullMyP.selectedBulletin);
  //const isUpdating = useSelector((state) => state.dataBullMyP.statusUpdate);
  const isDeleting = useSelector((state) => state.dataBullMyP.statusDelete);
  //const isError = useSelector((state) => state.dataBullMyP.errorBulletin);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();

  // const [selectedAnnouncement, setSelectedAnnouncement] = useState({
  //   announcementId: "",
  // });
  // const [sundayDate, setSundayDate] = useState(null);
  // const [quillValue, setQuillValue] = useState("");

  //handle click of posted bulletins
  const postedClick = (bulletin, e) => {
    const thisIsSelected = {
      bulletinId: bulletin.bulletinId,
    };
    dispatch(isSelectedBulletin(thisIsSelected));
    // setSelectedAnnouncement({
    //   ...selectedAnnouncement,
    //   announcementId: announcement.announcementId,
    // });
    // setSundayDate(new Date(announcement.sundayDate));
    // setQuillValue(announcement.announcementDetails);
    dispatch(removeBulletinError());
    setConfirmDelete(true);
    onOpen();
  };

  //count the characters and set its quantity to useState
  //check if characters is less than 250
  // const count = (e) => {
  //   let inputValue = e.target.value;
  //   setSelectedAnnouncement({
  //     ...selectedAnnouncement,
  //     countMessageBody: inputValue.length,
  //   });
  //   if (inputValue.length > 250) {
  //     setIsDisableAnnouncement(true);
  //   } else {
  //     setIsDisableAnnouncement(false);
  //   }
  // };
  // function EditChangeTitle(announcement) {
  //   //e.preventDefault();
  //   setSelectedAnnouncement({
  //     ...selectedAnnouncement,
  //     eventTitle: announcement.target.value,
  //   });
  // }

  // function EditChangeMessage(event) {
  //   //e.preventDefault();
  //   setSelectedAnnouncement({
  //     ...selectedAnnouncement,
  //     eventDetails: event.target.value,
  //   });
  // }

  // function EditSend(e) {
  //   e.preventDefault();
  //   const theSundayDate = sundayDate == null ? "" : sundayDate;
  //   const theQuillValue = quillValue === "<p><br></p>" ? "" : quillValue;
  //   const newAnnouncement = {
  //     announcementDetails: theQuillValue,
  //     sundayDate: theSundayDate,
  //     announcementId: selectedAnnouncement.announcementId,
  //   };

  //   dispatch(updateAnnouncement(newAnnouncement))
  //     .unwrap()
  //     .then((originalPromiseResult) => {
  //       if (typeof rejectedValueOrSerializedError != "object") {
  //         const notificationErrorData = notificationData(
  //           "Sunday Announcement",
  //           "Update Complete",
  //           "green",
  //           BiCheckDouble
  //         );

  //         toast({
  //           position: "bottom-left",
  //           render: () => (
  //             <div>
  //               {" "}
  //               <NotifyIndex notificationdata={notificationErrorData} />
  //             </div>
  //           ),
  //         });
  //         onClose();
  //       }
  //     })
  //     .catch((rejectedValueOrSerializedError) => {
  //       if (typeof rejectedValueOrSerializedError != "object") {
  //         const notificationErrorData = notificationData(
  //           "Sunday Announcement",
  //           rejectedValueOrSerializedError,
  //           "red",
  //           BiErrorCircle
  //         );

  //         toast({
  //           position: "bottom-left",
  //           render: () => (
  //             <div>
  //               {" "}
  //               <NotifyIndex notificationdata={notificationErrorData} />
  //             </div>
  //           ),
  //         });
  //       } else if (
  //         rejectedValueOrSerializedError?.code == "auth/id-token-expired"
  //       ) {
  //         dispatch(logoutUser());
  //       }
  //     });
  // }
  function CloseModel() {
    // setIsDisableAnnouncement(false);
    setConfirmDelete(false);
    dispatch(removeBulletinError());
    onClose();
  }
  function IsDelete() {
    setConfirmDelete(true);
  }
  function YesDelete() {
    const newBulletin = {
      bulletinId: selected.bulletinId,
    };
    dispatch(deleteBulletin(newBulletin))
      .unwrap()
      .then((originalPromiseResult) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Sunday Bulletin",
            "Delete Complete",
            "green",
            BiCheckDouble
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });

          setConfirmDelete(false);
          onClose();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Sunday Bulletin",
            rejectedValueOrSerializedError,
            "red",
            BiErrorCircle
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
        } else if (
          rejectedValueOrSerializedError?.code === "auth/id-token-expired"
        ) {
          dispatch(logoutUser());
        }
      });
  }

  function GetDateOccassion(date) {
    // dispatch(getOccassion(OccassionDate(date)));
  }

  return (
    <>
      {bulletins.map((bulletin) => (
        <Link
          key={bulletin.bulletinId}
          _hover={{ bg: "brand.50" }}
          // bg={
          //   signupRequestData.requestId === request.requestId &&
          //   "brand.400"
          // }
          _active={{
            bg: mode("gray.200", "gray.700"),
            color: "inherit",
          }}
          bg="brand.100"
          borderRadius={5}
          onClick={(e) => postedClick(bulletin, e)}
        >
          <Stack p={4}>
            <HStack>
              <Text fontWeight={"semibold"} fontSize="xs">
                Occassion:
              </Text>
              <Text isTruncated color={"brand.900"} fontSize="xs">
                {bulletin.occassion}
              </Text>
            </HStack>
            {/* <Text fontWeight={"semibold"} fontSize="xs">
              Details:
            </Text>
            <Text color={"brand.900"} fontSize="xs" noOfLines={3}>
              {bulletin.bulletinDetails.replace(/<[^>]+>/g, "")}
            </Text> */}
            <HStack>
              <Text fontWeight={"semibold"} fontSize="xs">
                Sunday Date:
              </Text>
              <Text isTruncated color={"brand.900"} fontSize="xs">
                {ConvertStartEndDate(bulletin.sundayDate)}
              </Text>
            </HStack>

            <Divider />
            <HStack justifyContent={"space-between"}>
              <Text color={"brand.900"} fontSize="xs">
                Crated on: {DateConvert(bulletin.createdAt)}
              </Text>
              <IconButton
                variant={"ghost"}
                size={"sm"}
                aria-label="Delete"
                icon={<DeleteIcon />}
                onClick={IsDelete}
              />
            </HStack>
          </Stack>
        </Link>
      ))}
      {/* {modal} */}
      <Modal isOpen={isOpen} onClose={CloseModel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {" "}
            {confirmDelete === true
              ? "Confirm Delete"
              : "Eidt Announcement"}{" "}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {confirmDelete == true ? (
              <div>
                <Stack spacing={"4"} justifyContent={"center"}>
                  <Text py="4">
                    Are you sure you want to Delete this Bulletin?
                  </Text>
                  <HStack>
                    <Button
                      variant={"outline"}
                      color="brand.900"
                      mr={3}
                      width={"full"}
                      onClick={CloseModel}
                      borderColor={"brand.900"}
                    >
                      NO
                    </Button>
                    <Button
                      variant={"outline"}
                      color="brand.900"
                      mr={3}
                      width={"full"}
                      onClick={YesDelete}
                    >
                      {isDeleting === "pending" ? <Spinner size="sm" /> : "YES"}
                    </Button>
                  </HStack>
                </Stack>
              </div>
            ) : (
              <div></div>
            )}
          </ModalBody>

          <ModalFooter>
            {confirmDelete === false && (
              <div></div>
              // <HStack width={"full"}>
              //   {" "}
              //   <Button
              //     variant={"outline"}
              //     color="brand.900"
              //     mr={3}
              //     onClick={CloseModel}
              //   >
              //     Close
              //   </Button>
              //   {/* <Button
              //     variant={"outline"}
              //     color="brand.900"
              //     mr={3}
              //     onClick={IsDelete}
              //   >
              //     Delete
              //   </Button> */}
              //   <Button
              //     colorScheme="brand.700"
              //     bg="brand.700"
              //     isDisabled={isDisableAnnouncement}
              //     onClick={EditSend}
              //     w="full"
              //   >
              //     {isUpdating == "pending" ? (
              //       <Spinner size="sm" />
              //     ) : (
              //       "Save Edit"
              //     )}
              //   </Button>
              // </HStack>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

// (
//   bulletins.map((message) => (
//     <Link
//       key={message.messageId}
//       _hover={{ bg: "brand.50" }}
//       // bg={
//       //   signupRequestData.requestId === request.requestId &&
//       //   "brand.400"
//       // }
//       _active={{
//         bg: mode("gray.200", "gray.700"),
//         color: "inherit",
//       }}
//       bg="brand.100"
//       borderRadius={5}
//       onClick={(e) => postedClick(message, e)}
//     >
//       <Stack p={4}>
//         <HStack>
//           <Text fontWeight={"semibold"} fontSize="xs">
//             Title:
//           </Text>
//           <Text
//             isTruncated
//             color={"brand.900"}
//             fontSize="xs"
//           >
//             {message.messageTitle}
//           </Text>
//         </HStack>
//         <Text fontWeight={"semibold"} fontSize="xs">
//           Details:
//         </Text>
//         <Text
//           color={"brand.900"}
//           fontSize="xs"
//           noOfLines={3}
//         >
//           {message.messageBody}
//         </Text>
//         <Divider />
//         <HStack justifyContent={"space-between"}>
//           <Text color={"brand.900"} fontSize="xs">
//             Crated on: {message.createdAt}
//           </Text>
//           <IconButton
//             variant={"ghost"}
//             size={"sm"}
//             aria-label="Delete"
//             icon={<DeleteIcon />}
//           />
//         </HStack>
//       </Stack>
//     </Link>
//   ))
// )
