import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  IconButton,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useBreakpointValue,
  Link,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { SignupRequestSkeleton } from "../components/common/skeleton";
import { getSignupRequest } from "../features/dataAdmin/dataAdminSlice";
import { readSignupRequestData } from "../features/ui/uiSlice";
import SignupRequestDetails from "../components/signupRequest/signupRequestDetails";
import { ArrowLeftIcon } from "@chakra-ui/icons";
// import { Link } from "react-router-dom";

export const SignUpRequest = () => {
  const [showPanel, setShowPanel] = useState(true);
  const {
    formState: { errors, isSubmitting },
  } = useForm();
  const history = useHistory();
  const { type, id } = useParams();
  const requests = useSelector((state) => state.dataAdmin.getSignupRequests);
  const signupRequestData = useSelector(
    (state) => state.ui.readSignupRequestData
  );
  const status = useSelector((state) => state.dataAdmin.statusSignRequest);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSignupRequest())
      .unwrap()
      .then((originalPromiseResult) => {})
      .catch((rejectedValueOrSerializedError) => {});
  }, []);

  const base1 = () => {
    if (showPanel === true) {
      return "block";
    } else {
      return "none";
    }
  };

  const base2 = () => {
    if (showPanel === true) {
      return "none";
    } else {
      return "block";
    }
  };

  function onclick(a, e) {
    e.preventDefault();
    setShowPanel(false);
    dispatch(readSignupRequestData(a));
    history.push(`/parish/signupRequest/${a.requestId}`);
  }

  function backButton() {
    setShowPanel(true);
  }

  return (
    <div>
      <Flex>
        <Flex
          width={{ base: "100vh", md: "40vh", lg: "60vh" }}
          h="90vh"
          flex={{ base: "1", md: "none" }}
          direction="column"
          overflowY="auto"
          // borderRightWidth="1px"
          // borderLeftWidth="1px"
          p="0"
          display={{
            base: base1,
            md: "block",
          }}
        >
          <Box
            borderWidth="1px"
            rounded="base"
            borderColor="brand.50"
            h="full"
            bg="white"
            overflowY="auto"
          >
            <Box py="2" boxShadow={"sm"}>
              <InputGroup height={{ base: "16", md: "12" }}>
                <Input
                  name="search"
                  size="md"
                  mt="2"
                  mx="2"
                  variant="flushed"
                  type="text"
                  placeholder="Search"
                  // mx="6"
                />
                <InputRightElement mt="2" mx="2">
                  <SearchIcon color="gray.500" />
                </InputRightElement>
              </InputGroup>
            </Box>
            {status === "pending" && <SignupRequestSkeleton />}
            {type === "signupRequest" && (
              <Stack spacing={0}>
                {requests.map((request) => (
                  <Link
                    key={request.requestId}
                    _hover={{ bg: "brand.50" }}
                    bg={
                      signupRequestData.requestId === request.requestId &&
                      "brand.400"
                    }
                    _active={{
                      bg: mode("gray.200", "gray.700"),
                      color: "inherit",
                    }}
                    onClick={(e) => onclick(request, e)}
                  >
                    <Stack lineHeight="1" spacing={0} py="3">
                      <HStack justifyContent="space-between" mx="6">
                        <Checkbox></Checkbox>
                        <Stack pl="4" flex="1">
                          <Text
                            fontSize="small"
                            fontWeight="semibold"
                            color="gray.800"
                          >
                            {" "}
                            {request.parishName}
                          </Text>
                          <Text
                            fontSize="small"
                            fontWeight="semibold"
                            color="brand.600"
                          >
                            {request.parishEmail}
                          </Text>
                          <HStack justifyContent="space-between">
                            <Text
                              fontSize="small"
                              fontWeight="normal"
                              color="gray.600"
                            >
                              {request.parishCityTown}
                            </Text>
                            <Text
                              fontSize="xs"
                              fontWeight="normal"
                              color="brand.600"
                            >
                              {request.createdAt}
                            </Text>
                          </HStack>
                        </Stack>
                      </HStack>
                    </Stack>
                    <Divider />
                  </Link>
                ))}
              </Stack>
            )}
          </Box>
        </Flex>

        <Flex
          flex="1"
          pr="0"
          pt="0"
          pl={{ base: "0", md: "0" }}
          pb="0"
          h={{ base: "100vh", md: "90vh" }}
          direction="column"
          overflowY="auto"
          borderRightWidth="1px"
          display={{
            base: base2,
            md: "block",
          }}
        >
          <Box rounded="base" h="full" w="full" bg="white">
            {id === signupRequestData.requestId && (
              <Flex width="full" bg="brand.20" h="full" direction="column">
                <Box width="full" bg="white" py="2" boxShadow={"sm"}>
                  <HStack height={{ base: "16", md: "12" }}>
                    <IconButton
                      bg="white"
                      h="full"
                      w="40"
                      icon={<ArrowLeftIcon />}
                      onClick={backButton}
                      display={{ md: "none" }}
                    />
                    <Box
                      display="flex"
                      justifyContent={{ base: "start", md: "center" }}
                      width="full"
                    >
                      <Text color={"brand.900"}>
                        {" "}
                        {signupRequestData.parishName}
                      </Text>
                    </Box>
                  </HStack>
                </Box>

                {showPanel === false ? (
                  <SignupRequestDetails />
                ) : (
                  <SignupRequestDetails />
                )}
              </Flex>
            )}

            {/* <p>{signupRequestData.plan}</p> */}
          </Box>
        </Flex>
      </Flex>
    </div>
  );
};
