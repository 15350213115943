import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import * as React from "react";
import { HiOutlineMenu } from "react-icons/hi";
import Logo from "../util/logo";
import Sidebar from "./Sidebar";
import useMobileMenuState from "./useMobileMenuState";
import { NavMenuItem } from "./NavMenuItem";
import { MdOutlineLogout, MdHomeFilled } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { IsSessionExpired } from "../util/functions";
import { activeLink } from "../features/ui/uiSlice";
import { removeOccassion } from "../features/dataAll/dataMegAnnEvtSlice";
import { Link } from "react-router-dom";

const MobileTopBar = () => {
  const { isOpen, onClose, onOpen } = useMobileMenuState();
  const dispatch = useDispatch();
  const history = useHistory();

  const logoClick = () => {
    IsSessionExpired();
    dispatch(activeLink("Home"));
    dispatch(removeOccassion());
    history.push("/Home");
  };
  return (
    <Flex
      align="center"
      justify="space-between"
      py="2"
      px="4"
      bg={mode("brand.50", "brand.50")}
      display={{
        base: "flex",
        md: "none",
      }}
      borderBottomWidth="1px"
    >
      <IconButton
        onClick={onOpen}
        variant="unstyled"
        display="flex"
        cursor="pointer"
        aria-label="Menu"
        icon={<HiOutlineMenu fontSize="1.5rem" />}
      />
      <Link to="/Home">
        <Logo h="6" iconColor="brand.700" onClick={logoClick} />
      </Link>
      {/* <Text>PARISHIONER</Text> */}
      <NavMenuItem.Desktop icon={<MdOutlineLogout />} label="Logout" />
      <Drawer
        size="xs"
        placement="left"
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent
          bg={mode("brand.800", "gray.800")}
          shadow="none"
          position="relative"
          maxW="64"
        >
          <Sidebar
            width="full"
            height="full"
            bg="inherit"
            border="0"
            onClose={onClose}
          />
          <DrawerCloseButton
            bg="brand.100"
            _hover={{
              bg: "brand.200",
            }}
            _active={{
              bg: "brand.700",
            }}
            rounded="0"
            position="absolute"
            color="brand.700"
            right="-8"
            top="0"
          />
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};
export default MobileTopBar;
