import { React } from "react";
import {
  Text,
  HStack,
  IconButton,
  Stack,
  Divider,
  Link,
  useColorModeValue as mode,
  Input,
  Button,
  Spinner,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  Box,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { DeleteIcon } from "@chakra-ui/icons";
import { useDisclosure } from "@chakra-ui/hooks";
import { useState } from "react";
import { useToast } from "@chakra-ui/toast";
import { DateConvert, notificationData } from "../../util/functions";
import { NotifyIndex } from "../notification/notifyIndex";
import { BiErrorCircle, BiCheckDouble } from "react-icons/bi";
import { logoutUser } from "../../features/user/userSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Announcement } from "../../pages/announcement";
import {
  deleteConfessionTime,
  deleteMassTime,
  removeErrorConfessionTime,
  removeErrorMassTime,
  updateConfessionTime,
  updateMassTime,
} from "../../features/dataAll/dataBullMyPSlice";

export const SentMassConf = () => {
  const massTime = useSelector((state) => state.dataBullMyP.massTime);
  const confessionTime = useSelector(
    (state) => state.dataBullMyP.confessionTime
  );
  const [isDisableAnnouncement, setIsDisableAnnouncement] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [quillValue, setQuillValue] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [isTab, setIsTab] = useState("");
  const dispatch = useDispatch();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isUpdating = useSelector((state) => state.dataBullMyP.statusUpdate);
  const isDeleting = useSelector((state) => state.dataBullMyP.statusDelete);
  const isErrorMassTime = useSelector(
    (state) => state.dataBullMyP.errorMassTime
  );
  const isErrorConfessionTime = useSelector(
    (state) => state.dataBullMyP.errorConfessionTime
  );

  //handle click of posted announcements
  const postedClick = (name) => {
    setIsTab(name);
    if (name === "mass") {
      setQuillValue(massTime?.massTimes);
      setCreatedAt(massTime?.createdAt);
    } else {
      setQuillValue(confessionTime?.confessionTime);
      setCreatedAt(confessionTime?.createdAt);
    }

    //dispatch(removeErrorAnnouncement());
    onOpen();
  };

  function EditSend(e) {
    e.preventDefault();
    const theQuillValue = quillValue === "<p><br></p>" ? "" : quillValue;
    var rawQuillValue = quillValue
      .replace(/<[^>]+>/g, "")
      .replace(/\&nbsp;/g, "");
    if (isTab === "mass") {
      const newMassTimes = {
        massTimes: theQuillValue,
        createdAt: createdAt,
        rawMassTimes: rawQuillValue,
      };

      dispatch(updateMassTime(newMassTimes))
        .unwrap()
        .then((originalPromiseResult) => {
          const notificationErrorData = notificationData(
            "Mass Time",
            "Update Complete",
            "green",
            BiCheckDouble
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
          onClose();
        })
        .catch((rejectedValueOrSerializedError) => {
          if (typeof rejectedValueOrSerializedError != "object") {
            const notificationErrorData = notificationData(
              "Mass Time",
              rejectedValueOrSerializedError,
              "red",
              BiErrorCircle
            );

            toast({
              position: "bottom-left",
              render: () => (
                <div>
                  {" "}
                  <NotifyIndex notificationdata={notificationErrorData} />
                </div>
              ),
            });
          } else if (
            rejectedValueOrSerializedError?.code == "auth/id-token-expired"
          ) {
            dispatch(logoutUser());
          }
        });
    } else {
      const newConfessionTime = {
        confessionTime: theQuillValue,
        createdAt: createdAt,
        rawConfessionTime: rawQuillValue,
      };

      dispatch(updateConfessionTime(newConfessionTime))
        .unwrap()
        .then((originalPromiseResult) => {
          const notificationErrorData = notificationData(
            "Confession Time",
            "Update Complete",
            "green",
            BiCheckDouble
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
          onClose();
        })
        .catch((rejectedValueOrSerializedError) => {
          if (typeof rejectedValueOrSerializedError != "object") {
            const notificationErrorData = notificationData(
              "Confession Time",
              rejectedValueOrSerializedError,
              "red",
              BiErrorCircle
            );

            toast({
              position: "bottom-left",
              render: () => (
                <div>
                  {" "}
                  <NotifyIndex notificationdata={notificationErrorData} />
                </div>
              ),
            });
          } else if (
            rejectedValueOrSerializedError?.code == "auth/id-token-expired"
          ) {
            dispatch(logoutUser());
          }
        });
    }
  }
  function CloseModel() {
    // setIsDisableAnnouncement(false);
    setConfirmDelete(false);
    dispatch(removeErrorMassTime());
    dispatch(removeErrorConfessionTime());
    onClose();
  }
  function IsDelete() {
    setConfirmDelete(true);
  }
  function YesDelete() {
    if (isTab === "mass") {
      dispatch(deleteMassTime())
        .unwrap()
        .then((originalPromiseResult) => {
          const notificationErrorData = notificationData(
            "Mass Time",
            "Delete Complete",
            "green",
            BiCheckDouble
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });

          setConfirmDelete(false);
          onClose();
        })
        .catch((rejectedValueOrSerializedError) => {
          if (typeof rejectedValueOrSerializedError != "object") {
            const notificationErrorData = notificationData(
              "Mass Time",
              rejectedValueOrSerializedError,
              "red",
              BiErrorCircle
            );

            toast({
              position: "bottom-left",
              render: () => (
                <div>
                  {" "}
                  <NotifyIndex notificationdata={notificationErrorData} />
                </div>
              ),
            });
          } else if (
            rejectedValueOrSerializedError?.code == "auth/id-token-expired"
          ) {
            dispatch(logoutUser());
          }
        });
    } else {
      dispatch(deleteConfessionTime())
        .unwrap()
        .then((originalPromiseResult) => {
          const notificationErrorData = notificationData(
            "Confession Time",
            "Delete Complete",
            "green",
            BiCheckDouble
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });

          setConfirmDelete(false);
          onClose();
        })
        .catch((rejectedValueOrSerializedError) => {
          if (typeof rejectedValueOrSerializedError != "object") {
            const notificationErrorData = notificationData(
              "Confession Time",
              rejectedValueOrSerializedError,
              "red",
              BiErrorCircle
            );

            toast({
              position: "bottom-left",
              render: () => (
                <div>
                  {" "}
                  <NotifyIndex notificationdata={notificationErrorData} />
                </div>
              ),
            });
          } else if (
            rejectedValueOrSerializedError?.code == "auth/id-token-expired"
          ) {
            dispatch(logoutUser());
          }
        });
    }
  }

  return (
    <>
      {Object.entries(massTime).length === 0 ? (
        <div></div>
      ) : (
        <Link
          _hover={{ bg: "brand.50" }}
          // bg={
          //   signupRequestData.requestId === request.requestId &&
          //   "brand.400"
          // }
          _active={{
            bg: mode("gray.200", "gray.700"),
            color: "inherit",
          }}
          bg="brand.100"
          borderRadius={5}
          onClick={(e) => postedClick("mass")}
        >
          <Stack p={4}>
            <HStack>
              <Text fontWeight={"semibold"} fontSize="xs">
                Mass Time:
              </Text>
            </HStack>
            <Text fontWeight={"semibold"} fontSize="xs">
              Details:
            </Text>
            <Text color={"brand.900"} fontSize="xs" noOfLines={6}>
              {massTime?.massTimes
                ?.replace(/<[^>]+>/g, "")
                .replace(/\&nbsp;/g, "")}
            </Text>

            <Divider />
            <HStack justifyContent={"space-between"}>
              <Text color={"brand.900"} fontSize="xs">
                Crated on: {DateConvert(massTime?.createdAt)}
              </Text>
              <IconButton
                variant={"ghost"}
                size={"sm"}
                aria-label="Delete"
                icon={<DeleteIcon />}
                onClick={IsDelete}
              />
            </HStack>
          </Stack>
        </Link>
      )}
      {Object.entries(confessionTime).length === 0 ? (
        <div></div>
      ) : (
        <Link
          _hover={{ bg: "brand.50" }}
          // bg={
          //   signupRequestData.requestId === request.requestId &&
          //   "brand.400"
          // }
          _active={{
            bg: mode("gray.200", "gray.700"),
            color: "inherit",
          }}
          bg="brand.100"
          borderRadius={5}
          onClick={(e) => postedClick("confession")}
        >
          <Stack p={4}>
            <HStack>
              <Text fontWeight={"semibold"} fontSize="xs">
                Confession Time:
              </Text>
            </HStack>
            <Text fontWeight={"semibold"} fontSize="xs">
              Details:
            </Text>
            <Text color={"brand.900"} fontSize="xs" noOfLines={6}>
              {confessionTime?.confessionTime?.replace(/<[^>]+>/g, "")}
            </Text>

            <Divider />
            <HStack justifyContent={"space-between"}>
              <Text color={"brand.900"} fontSize="xs">
                Crated on: {DateConvert(confessionTime?.createdAt)}
              </Text>
              <IconButton
                variant={"ghost"}
                size={"sm"}
                aria-label="Delete"
                icon={<DeleteIcon />}
                onClick={IsDelete}
              />
            </HStack>
          </Stack>
        </Link>
      )}
      {/* {modal} */}
      <Modal isOpen={isOpen} onClose={CloseModel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {" "}
            {confirmDelete == true
              ? "Confirm Delete"
              : "Eidt Announcement"}{" "}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {confirmDelete == true ? (
              <div>
                <Stack spacing={"4"} justifyContent={"center"}>
                  <Text py="4">Are you sure you want to Delete</Text>
                  <HStack>
                    <Button
                      variant={"outline"}
                      color="brand.900"
                      mr={3}
                      width={"full"}
                      onClick={CloseModel}
                      borderColor={"brand.900"}
                    >
                      NO
                    </Button>
                    <Button
                      variant={"outline"}
                      color="brand.900"
                      mr={3}
                      width={"full"}
                      onClick={YesDelete}
                    >
                      {isDeleting == "pending" ? <Spinner size="sm" /> : "YES"}
                    </Button>
                  </HStack>
                </Stack>
              </div>
            ) : (
              <div>
                <Text as="sub" color={"red.500"}>
                  {isErrorMassTime?.massTimes}
                  {isErrorConfessionTime?.confessionTime}
                </Text>
                <ReactQuill
                  modules={Announcement.modules}
                  formats={Announcement.formats}
                  onChange={setQuillValue}
                  theme="snow"
                  value={quillValue}
                  placeholder="Your Sunday Announcement Details"
                />
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            {confirmDelete == false && (
              <HStack width={"full"}>
                {" "}
                <Button
                  variant={"outline"}
                  color="brand.900"
                  mr={3}
                  onClick={CloseModel}
                >
                  Close
                </Button>
                {/* <Button
                  variant={"outline"}
                  color="brand.900"
                  mr={3}
                  onClick={IsDelete}
                >
                  Delete
                </Button> */}
                <Button
                  colorScheme="brand.700"
                  bg="brand.700"
                  isDisabled={isDisableAnnouncement}
                  onClick={EditSend}
                  w="full"
                >
                  {isUpdating == "pending" ? (
                    <Spinner size="sm" />
                  ) : (
                    "Save Edit"
                  )}
                </Button>
              </HStack>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

// (
//   announcements.map((message) => (
//     <Link
//       key={message.messageId}
//       _hover={{ bg: "brand.50" }}
//       // bg={
//       //   signupRequestData.requestId === request.requestId &&
//       //   "brand.400"
//       // }
//       _active={{
//         bg: mode("gray.200", "gray.700"),
//         color: "inherit",
//       }}
//       bg="brand.100"
//       borderRadius={5}
//       onClick={(e) => postedClick(message, e)}
//     >
//       <Stack p={4}>
//         <HStack>
//           <Text fontWeight={"semibold"} fontSize="xs">
//             Title:
//           </Text>
//           <Text
//             isTruncated
//             color={"brand.900"}
//             fontSize="xs"
//           >
//             {message.messageTitle}
//           </Text>
//         </HStack>
//         <Text fontWeight={"semibold"} fontSize="xs">
//           Details:
//         </Text>
//         <Text
//           color={"brand.900"}
//           fontSize="xs"
//           noOfLines={3}
//         >
//           {message.messageBody}
//         </Text>
//         <Divider />
//         <HStack justifyContent={"space-between"}>
//           <Text color={"brand.900"} fontSize="xs">
//             Crated on: {message.createdAt}
//           </Text>
//           <IconButton
//             variant={"ghost"}
//             size={"sm"}
//             aria-label="Delete"
//             icon={<DeleteIcon />}
//           />
//         </HStack>
//       </Stack>
//     </Link>
//   ))
// )
