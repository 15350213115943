import {
  Box,
  Flex,
  Heading,
  VStack,
  HStack,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import * as React from "react";
import { HiOutlineExternalLink } from "react-icons/hi";
import Logo from "../util/logo";
import { SigninForm } from "../components/loginpage/SigninForm";
import { Link } from "../components/common/Link";

const LoginPage = () => {
  return (
    <Flex
      h="100vh"
      direction={{
        base: "column",
        md: "row",
      }}
    >
      <Box
        display={{
          base: "none",
          md: "block",
        }}
        maxW={{
          base: "20rem",
          lg: "40rem",
        }}
        flex="1"
        bg={mode("brand.800", "brand.900")}
        color="white"
        px="10"
        py="8"
      >
        <Box>
          <Logo
            mx="auto"
            h="8"
            mb={{
              base: "10",
              md: "5",
            }}
            iconColor="white"
            color="white"
          />
        </Box>
        <Flex
          direction="column"
          align="center"
          justify="center"
          h="full"
          textAlign="center"
          mt="-10"
        >
          <Box>
            <Text mt="5" maxW="sm" mx="auto">
              Admin Portal Login
            </Text>
          </Box>
          <HStack
            justify="center"
            as="a"
            href="/getStarted"
            minW="2xs"
            py="3"
            fontWeight="semibold"
            px="2"
            mt="5"
            border="2px solid white"
            rounded="lg"
            _hover={{
              bg: "whiteAlpha.200",
            }}
          >
            <Box>Get Started</Box>
            <HiOutlineExternalLink />
          </HStack>
        </Flex>
      </Box>

      <Stack
        flex="1"
        justify="space-between"
        px={{
          base: "6",
          md: "10",
          lg: "16",
          xl: "28",
        }}
        py={{
          base: "5",
          md: "24",
        }}
        bg={{
          md: mode("brand.50", "brand.50"),
        }}
      >
        <Box maxW="xl">
          <VStack justifyContent={"center"}>
            {/* <VStack
              display={{
                base: "block",
                md: "none",
              }}
              mt="10"
              justify="center"
            > */}
            <Logo
              display={{ base: "block", md: "none" }}
              w="auto"
              h="9"
              iconColor="brand.700"
              mt="5"
            />
            <Text
              display={{ base: "block", md: "none" }}
              mt="5"
              maxW="sm"
              mx="auto"
            >
              Admin Portal Login
            </Text>
            {/* </VStack> */}
          </VStack>

          <Box
            minW={{
              md: "420px",
            }}
            mt="10"
            rounded="xl"
            border={{ base: "0px", md: "1px" }}
            borderColor={{ base: "brand.50", md: "brand.600" }}
            bg={{
              md: mode("brand.50", "gray.700"),
            }}
            px={{
              md: "10",
            }}
            pt={{
              base: "8",
              md: "12",
            }}
            pb="8"
          >
            <SigninForm />
          </Box>

          <Text mt="3" color={mode("gray.600", "gray.400")} fontWeight="sm">
            Need an account? <Link href="/register">Register</Link>
          </Text>
        </Box>
        <Box>
          <Text display={{ base: "block", md: "none" }} align={"center"}>
            <Text as="sup" color="brand.800" position={"static"}>
              Note: You need to
            </Text>

            <Link
              rounded="sm"
              _hover={{
                bg: "whiteAlpha.200",
              }}
              href="/getStarted"
            >
              Get Started
            </Link>
            <Text as="sup" color="brand.800">
              {" "}
              to Receive a ParishUserName before you Register
            </Text>
          </Text>
        </Box>
      </Stack>
    </Flex>
  );
};
export default LoginPage;
