import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  LightMode,
  Stack,
  Spinner,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import * as React from "react";
import { UnderlineLink } from "./UnderlineLink";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  loginUser,
  getData,
  authenticated,
} from "../../features/user/userSlice";
import { unwrap } from "@reduxjs/toolkit";
import { useToast } from "@chakra-ui/react";
import { BiErrorCircle } from "react-icons/bi";
import { useHistory } from "react-router";
import { notificationData } from "../../util/functions";
import { activeLink } from "../../features/ui/uiSlice";
import {
  getParishSubscribedData,
  userExpiresAtStatus,
} from "../../features/dataAll/dataSubscriptionSlice";
import { Toast } from "../notification/toast";

export const SigninForm = () => {
  const dispatch = useDispatch();
  const isformSubmitting = useSelector((state) => state.user.status);
  const isGetPending = useSelector((state) => state.dataSubscription.statusGet);
  const { register, handleSubmit } = useForm();

  const hostory = useHistory();

  const onSubmit = (data, e) => {
    // add submit logic here
    e.preventDefault();
    const userData = {
      parishEmail: data.email,
      password: data.password,
    };

    dispatch(loginUser(userData))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(userExpiresAtStatus())
          .unwrap()
          .then((originalPromiseResult) => {
            dispatch(authenticated(true));
            hostory.push("/home");
            dispatch(activeLink("home"));
            dispatch(getData());
            dispatch(getParishSubscribedData());
          })
          .catch((rejectedValueOrSerializedError) => {});
      })
      .catch((rejectedValueOrSerializedError) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          Toast(
            notificationData(
              "Could not login",
              rejectedValueOrSerializedError,
              "red",
              BiErrorCircle
            )
          );
        } else {
          Toast(
            notificationData(
              "Could not login",
              "Something went wrong",
              "red",
              BiErrorCircle
            )
          );
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing="-px">
        <FormControl id="email-address">
          <FormLabel srOnly>Email address</FormLabel>
          <Input
            mb="4"
            size="lg"
            name="email"
            type="email"
            autoComplete="email"
            required
            placeholder="Email address"
            bg={mode("white", "gray.700")}
            fontSize="md"
            roundedBottom="0"
            {...register("email", {
              required: "This is required",
            })}
          />
          {/* {status.status ==='rejected' && <NotifyIndex/> } */}
        </FormControl>
        <FormControl id="password">
          <FormLabel srOnly>Email address</FormLabel>
          <Input
            name="password"
            type="password"
            autoComplete="current-password"
            required
            size="lg"
            bg={mode("white", "gray.700")}
            fontSize="md"
            roundedTop="0"
            placeholder="Password"
            {...register("password", {
              required: "This is required",
            })}
          />
        </FormControl>
      </Stack>
      <Flex align="center" justify="space-between" mt="8">
        <LightMode>
          <Checkbox
            size="lg"
            colorScheme="blue"
            sx={{
              ".chakra-checkbox__control": {
                "&:not([data-checked])": {
                  bg: mode("white", "gray.700"),
                },
                rounded: "base",
                borderWidth: "1px",
              },
              ".chakra-checkbox__label": {
                fontSize: "sm",
              },
            }}
          >
            Remember me
          </Checkbox>
        </LightMode>
        <UnderlineLink fontSize="sm">Forgot Password</UnderlineLink>
      </Flex>
      <LightMode>
        <Button
          size="lg"
          type="submit"
          mt="8"
          w="full"
          bg="brand.700"
          colorScheme="white"
          fontSize="md"
          fontWeight="bold"
        >
          {isformSubmitting === "pending" || isGetPending === "pending" ? (
            <Spinner size="xs" />
          ) : (
            "Login "
          )}
        </Button>
      </LightMode>
    </form>
  );
};

//{isformSubmitting ==="pending"&&(<Spinner size="xs" />)}
