import { Text } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { ConvertStartEndDate } from "../../util/functions";

export const SubscriptionInfo = () => {
  const parishSubscribedData = useSelector(
    (state) => state.dataSubscription.parishSubscribedData
  );

  return (
    <div>
      {parishSubscribedData.channel === "mobile_money" && (
        <Text mt="-1.5" color={"brand.600"} fontWeight="semibold">
          Your subscription to expire on{" "}
          {ConvertStartEndDate(parishSubscribedData.expiresAt)}
        </Text>
      )}
      {parishSubscribedData.channel === "card" && (
        <Text mt="-1.5" color={"brand.600"} fontWeight="semibold">
          Your subscription will auto renew on{" "}
          {ConvertStartEndDate(parishSubscribedData.expiresAt)}
        </Text>
      )}
    </div>
  );
};
