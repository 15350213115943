import {
  Box,
  Text,
  useColorModeValue,
  Button,
  chakra,
  FormControl,
  Spinner,
  Input,
  Stack,
  Tooltip,
  HStack,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  VStack,
  Flex,
} from "@chakra-ui/react";
import * as React from "react";
import { Card } from "../components/common/Card";
import { Link } from "../components/common/Link";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import Logo from "../util/logo";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useToast } from "@chakra-ui/toast";
//import { Link } from "react-router-dom";
import {
  signupUser,
  getData,
  authenticated,
  realTimeParishUsernameCheck,
} from "../features/user/userSlice";
import { notificationData } from "../util/functions";
import { NotifyIndex } from "../components/notification/notifyIndex";
import { BiErrorCircle, BiCheck } from "react-icons/bi";
import { CheckIcon } from "@chakra-ui/icons";
import { GoPrimitiveDot } from "react-icons/go";
import { textColorChange } from "../util/functions";
import { useState } from "react";

export const SignupPage = () => {
  const [brandColor, setBrandColor] = useState("");
  const [inputvaue, setInputvaue] = useState("");
  const { register, handleSubmit, reset, control } = useForm();
  const isformSubmitting = useSelector((state) => state.user.status);
  const isSignupError = useSelector((state) => state.user.error);
  const isCheckingParishUsernameCheck = useSelector(
    (state) => state.user.statusRealTimeCheck
  );
  const parishUsernameCheck = useSelector(
    (state) => state.user.realTimeParishUsernameCheck
  );
  const dispatch = useDispatch();
  const hostory = useHistory();
  const toast = useToast();

  //regular expression
  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  const capital = new RegExp(/[A-Z]/);
  const small = new RegExp(/[a-z]/);
  const num = new RegExp(/[0-9]/);
  const special = new RegExp(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
  const max = new RegExp(/^.{8,16}$/);

  //check if strongRegex is met
  //set the input value to state to be use to check for the other exps.
  //if strongRegex is true setBrandColor to vbrand
  //else setBrandColor to ebrand
  const onChange = (e, vbrand, ebrand, regex) => {
    setInputvaue(e.currentTarget.value);
    if (regex.test(e.currentTarget.value)) {
      setBrandColor(vbrand);
    } else {
      setBrandColor(ebrand);
    }
  };

  const onSubmit = (data, e) => {
    e.preventDefault(); // your login logic here

    const newParish = {
      parishUserName: data.parishUserName,
      parishEmail: data.parishEmail,
      password: data.password,
      comfirmPassword: data.confirmPassword,
    };
    dispatch(signupUser(newParish))
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(authenticated(true));
        hostory.push("/home");
        dispatch(getData());
      })
      .catch((rejectedValueOrSerializedError) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Could not Signup",
            rejectedValueOrSerializedError,
            "red",
            BiErrorCircle
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
        }
      });
  };

  //handle ParishUsernameOnchange
  const handleParishUsernameOnchange = (e) => {
    e.preventDefault();
    const parishUserName = {
      parishUserName: e.target.value,
    };
    dispatch(realTimeParishUsernameCheck(parishUserName));
  };

  return (
    <Stack
      bg={useColorModeValue("brand.800", "brand.900")}
      minH="100vh"
      py="4"
      px={{
        base: "4",
        lg: "8",
      }}
      justify="space-between"
    >
      <Box maxW="md" mx="auto" pt={4} pd={2}>
        <Logo
          mx="auto"
          h="8"
          mb={{
            base: "10",
            md: "5",
          }}
          iconColor="white"
          color="white"
        />

        <Card>
          <Text align="center" maxW="md" my={2} lineHeight={1}>
            Register
          </Text>

          <chakra.form py={4} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing="3">
              <FormControl id="parishUserName">
                <InputGroup>
                  <InputLeftElement
                    my="4px"
                    children={
                      isCheckingParishUsernameCheck === "success" &&
                      parishUsernameCheck === "true" && (
                        <CheckIcon size="sm" color="green.500" />
                      )
                    }
                  />
                  <InputLeftElement
                    my="4px"
                    children={
                      isCheckingParishUsernameCheck === "pending" && (
                        <Spinner size="xs" color="brand.600" />
                      )
                    }
                  />
                  <Input
                    _focus={
                      parishUsernameCheck === "true" ? "brand.300" : "brand.200"
                    }
                    focusBorderColor={
                      parishUsernameCheck === "true" ? "brand.300" : "brand.200"
                    }
                    borderColor={
                      parishUsernameCheck === "true" ? "brand.300" : "brand.200"
                    }
                    name="parishUserName"
                    type="'text'"
                    required
                    placeholder="parish UserName"
                    size="lg"
                    {...register("parishUserName", {
                      onChange: (e) => handleParishUsernameOnchange(e),
                    })}
                  />
                  <InputRightElement
                    my="4px"
                    children={
                      <Tooltip
                        hasArrow
                        label="Click on GetStarted to receive a Username"
                        placement="top-end"
                      >
                        <span>
                          <AiOutlineQuestionCircle />
                        </span>
                      </Tooltip>
                    }
                  />
                </InputGroup>
              </FormControl>

              <FormControl id="parishEmail">
                <Input
                  name="parishEmail"
                  type="email"
                  autoComplete="email"
                  required
                  placeholder="Email address"
                  size="lg"
                  {...register("parishEmail")}
                />
              </FormControl>
              <FormControl id="password">
                <Input
                  name="password"
                  type="password"
                  autoComplete="password"
                  required
                  placeholder="password"
                  size="lg"
                  {...register("password", {
                    onChange: (e) =>
                      onChange(e, "brand.300", "brand.200", strongRegex),
                  })}
                  focusBorderColor={brandColor}
                />
              </FormControl>

              <Stack spacing="1">
                {textColorChange(
                  inputvaue,
                  capital,
                  "brand.300",
                  "gray.800"
                ) === "brand.300" ? (
                  <HStack>
                    <GoPrimitiveDot color="green" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="black"
                    >
                      One uppercase letter
                    </Text>
                  </HStack>
                ) : (
                  <HStack>
                    <GoPrimitiveDot color="grey" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="gray.300"
                    >
                      One uppercase letter
                    </Text>
                  </HStack>
                )}
                {textColorChange(inputvaue, small, "brand.300", "gray.800") ===
                "brand.300" ? (
                  <HStack>
                    <GoPrimitiveDot color="green" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="black"
                    >
                      One lowercase letter
                    </Text>
                  </HStack>
                ) : (
                  <HStack>
                    <GoPrimitiveDot color="grey" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="gray.300"
                    >
                      One lowercase letter
                    </Text>
                  </HStack>
                )}
                {textColorChange(inputvaue, num, "brand.300", "gray.800") ===
                "brand.300" ? (
                  <HStack>
                    <GoPrimitiveDot color="green" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="black"
                    >
                      One number
                    </Text>
                  </HStack>
                ) : (
                  <HStack>
                    <GoPrimitiveDot color="grey" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="gray.300"
                    >
                      One number
                    </Text>
                  </HStack>
                )}
                {textColorChange(
                  inputvaue,
                  special,
                  "brand.300",
                  "gray.800"
                ) === "brand.300" ? (
                  <HStack>
                    <GoPrimitiveDot color="green" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="black"
                    >
                      One special character
                    </Text>
                  </HStack>
                ) : (
                  <HStack>
                    <GoPrimitiveDot color="grey" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="gray.300"
                    >
                      One special character
                    </Text>
                  </HStack>
                )}
                {textColorChange(inputvaue, max, "brand.300", "gray.800") ===
                "brand.300" ? (
                  <HStack>
                    <GoPrimitiveDot color="green" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="black"
                    >
                      8 characters minimum
                    </Text>
                  </HStack>
                ) : (
                  <HStack>
                    <GoPrimitiveDot color="grey" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="gray.300"
                    >
                      8 characters minimum
                    </Text>
                  </HStack>
                )}
              </Stack>

              {brandColor === "brand.300" && (
                <FormControl id="confirmPassword">
                  <Text fontSize="sm" color="brand.200">
                    {isSignupError?.comfirmPassword}
                  </Text>
                  <Input
                    name="confirmPassword"
                    type="password"
                    autoComplete="confirmPassword"
                    required
                    placeholder="Confirm Password"
                    size="lg"
                    {...register("confirmPassword")}
                  />
                </FormControl>
              )}

              <Button
                type="submit"
                bg="brand.700"
                colorScheme="white"
                size="lg"
                fontSize="md"
              >
                {isformSubmitting === "pending" ? (
                  <Spinner size="xs" />
                ) : (
                  "Register "
                )}
              </Button>
            </Stack>
          </chakra.form>
        </Card>
        <Text mt="1" mb="2" align="center" maxW="md" fontWeight="medium">
          <Text as="span" color="brand.50">
            Already have account?
          </Text>
          <Link href="/login">Login</Link>
        </Text>
        <Text
          as="sup"
          color="brand.100"
          display={{ base: "none", md: "block" }}
          mt="5"
        >
          Note: You need to {<Link href="/getStarted">Get Started</Link>} to
          Receive a ParishUserName before Register
        </Text>
      </Box>
      <Box>
        <Text display={{ base: "block", md: "none" }} align={"center"}>
          <Text as="sup" color="brand.100" position={"static"}>
            Note: You need to
          </Text>

          <Link
            rounded="sm"
            _hover={{
              bg: "whiteAlpha.200",
            }}
            href="/getStarted"
          >
            Get Started
          </Link>
          <Text as="sup" color="brand.100">
            {" "}
            to Receive a ParishUserName before you Register
          </Text>
        </Text>
      </Box>
    </Stack>
  );
};
