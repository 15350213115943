import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/user/userSlice";
import uiReducer from "./features/ui/uiSlice";
import dataAdminReducer from "./features/dataAdmin/dataAdminSlice";
import dataMegAnnEvtSlice from "./features/dataAll/dataMegAnnEvtSlice";
import dataBullMyPSlice from "./features/dataAll/dataBullMyPSlice";
import dataSubscriptionSlice from "./features/dataAll/dataSubscriptionSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    dataAdmin: dataAdminReducer,
    dataMegAnnEvt: dataMegAnnEvtSlice,
    dataBullMyP: dataBullMyPSlice,
    dataSubscription: dataSubscriptionSlice,
    ui: uiReducer,
  },
});
