import React from "react";
import {
  Input,
  SimpleGrid,
  GridItem,
  FormControl,
  FormLabel,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";

export const TopAccordionPanel = () => {
  const credentials = useSelector((state) => state.user.credentials);
  const col = useBreakpointValue({ base: "1", md: "2" });
  return (
    <div>
      <SimpleGrid columns={col} columnGap={3} rowGap={6} w="full">
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel fontSize="xs" color="brand.900" lineHeight={"0"}>
              {" "}
              Parish Name
            </FormLabel>

            <Input
              bg="gray.100"
              size="sm"
              variant="goast"
              isReadOnly={true}
              defaultValue={credentials.credentials?.parishName}
            ></Input>
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel fontSize="xs" color="brand.900" lineHeight={"0"}>
              {" "}
              Parish Username
            </FormLabel>

            <Input
              bg="gray.100"
              size="sm"
              variant="goast"
              isReadOnly={true}
              defaultValue={credentials.credentials?.parishUserName}
            ></Input>
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel fontSize="xs" color="brand.900" lineHeight={"0"}>
              {" "}
              Parish Email
            </FormLabel>

            <Input
              bg="gray.100"
              size="sm"
              variant="goast"
              isReadOnly={true}
              defaultValue={credentials.credentials?.parishEmail}
            ></Input>
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel fontSize="xs" color="brand.900" lineHeight={"0"}>
              {" "}
              Plan
            </FormLabel>

            <Input
              bg="gray.100"
              size="sm"
              variant="goast"
              isReadOnly={true}
              defaultValue={credentials.credentials?.plan}
            ></Input>
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel fontSize="xs" color="brand.900" lineHeight={"0"}>
              {" "}
              Country
            </FormLabel>

            <Input
              bg="gray.100"
              size="sm"
              variant="goast"
              isReadOnly={true}
              defaultValue={credentials.credentials?.country}
            ></Input>
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel fontSize="xs" color="brand.900" lineHeight={"0"}>
              {" "}
              Parish City/Town
            </FormLabel>

            <Input
              bg="gray.100"
              size="sm"
              variant="goast"
              isReadOnly={true}
              defaultValue={credentials.credentials?.parishLocationName}
            ></Input>
          </FormControl>
        </GridItem>
      </SimpleGrid>
    </div>
  );
};
