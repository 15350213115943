import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Textarea,
  SimpleGrid,
  GridItem,
  Input,
  Text,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useDisclosure } from "@chakra-ui/hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createParishUser,
  getStartedReject,
} from "../../features/user/userSlice";
import { removeSignupRequestFromState } from "../../features/dataAdmin/dataAdminSlice";
import { BiErrorCircle, BiCheckDouble } from "react-icons/bi";
import { NotifyIndex } from "../notification/notifyIndex";
import { notificationData } from "../../util/functions";
import { useToast } from "@chakra-ui/toast";
import { readSignupRequestDataSetToEmpty } from "../../features/ui/uiSlice";

function ModelGenApproveReject() {
  const signupRequestData = useSelector(
    (state) => state.ui.readSignupRequestData
  );
  const error = useSelector((state) => state.user.error);
  const isformSubmitting = useSelector((state) => state.user.status);
  const dispatch = useDispatch();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showModel, setShowModel] = useState("");
  const { register, getValues, reset } = useForm();
  const [parishUserName, setParishUserName] = useState("");

  useEffect(() => {
    setParishUserName("");
  }, [signupRequestData.requestId]);

  const setEmptySignupRequestData = {
    plan: "",
    parishName: "",
    parishEmail: "",
    parishAddress: "",
    parishCityTown: "",
    country: "",
    parishPriestName: "",
    priestPhoneNumber: "",
  };

  const onCloseModel = () => {
    reset();
    onClose();
  };
  const onReject = () => {
    setShowModel("reject");
    onOpen();
  };
  const onApprove = () => {
    setShowModel("approve");
    onOpen();
  };

  const sendRejectMail = () => {
    const values = getValues();
    const newData = {
      plan: signupRequestData.plan,
      selectedPlan: signupRequestData.selectedPlan,
      parishName: signupRequestData.parishName,
      parishEmail: signupRequestData.parishEmail,
      parishAddress: signupRequestData.parishAddress,
      parishCityTown: signupRequestData.parishCityTown,
      country: signupRequestData.country,
      parishPriestName: signupRequestData.parishPriestName,
      priestPhoneNumber: signupRequestData.priestPhoneNumber,
      rejectMessage: values.reject,
      requestId: signupRequestData.requestId,
    };
    dispatch(getStartedReject(newData))
      .unwrap()
      .then((originalPromiseResult) => {
        if (typeof originalPromiseResult != "object") {
          dispatch(removeSignupRequestFromState(originalPromiseResult));
          const notificationErrorData = notificationData(
            "Signup Request",
            "Mail Sent",
            "green",
            BiCheckDouble
          );
          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
          dispatch(readSignupRequestDataSetToEmpty(setEmptySignupRequestData));
          onCloseModel();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Signup Request",
            rejectedValueOrSerializedError,
            "red",
            BiErrorCircle
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
          onCloseModel();
        }
      });
  };

  const sendApproveMail = () => {
    const values = getValues();
    const newData = {
      plan: signupRequestData.plan,
      selectedPlan: signupRequestData.selectedPlan,
      parishName: signupRequestData.parishName,
      parishEmail: signupRequestData.parishEmail,
      parishAddress: signupRequestData.parishAddress,
      parishCityTown: signupRequestData.parishCityTown,
      country: signupRequestData.country,
      parishPriestName: signupRequestData.parishPriestName,
      priestPhoneNumber: signupRequestData.priestPhoneNumber,
      parishUserName: parishUserName,
      approveMessage: values.approve,
      requestId: signupRequestData.requestId,
    };
    dispatch(createParishUser(newData))
      .unwrap()
      .then((originalPromiseResult) => {
        if (typeof originalPromiseResult != "object") {
          dispatch(removeSignupRequestFromState(originalPromiseResult));
          const notificationErrorData = notificationData(
            "Signup Request",
            "Parish User Created; Mail Sent",
            "green",
            BiCheckDouble
          );
          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
          dispatch(readSignupRequestDataSetToEmpty(setEmptySignupRequestData));
          onCloseModel();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Signup Request",
            rejectedValueOrSerializedError,
            "red",
            BiErrorCircle
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
          onCloseModel();
        }
      });
  };

  function generateUserName() {
    const randid = Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);

    var x = signupRequestData.parishName;
    var y = x.split(" ").slice(0, 2).join("");
    setParishUserName(y + randid);
  }

  return (
    <>
      <SimpleGrid columns={2} columnGap={3} rowGap={2} w="full" my="2">
        <GridItem colSpan={1}>
          <Text mt="2" fontSize="sm" color="brand.900">
            Parish UserName:
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Input
            size="sm"
            variant="outline"
            isReadOnly={true}
            value={parishUserName}
          ></Input>
        </GridItem>
      </SimpleGrid>
      <Button
        borderRadius="2"
        size="sm"
        colorScheme="green"
        mb="3"
        onClick={generateUserName}
      >
        Generate UserName
      </Button>

      <HStack justifyContent="end" mb="2" spacing={4}>
        <Button
          onClick={onReject}
          borderRadius="2"
          size="sm"
          w="100px"
          bg="brand.700"
          colorScheme="white"
        >
          REJECT
        </Button>
        <Button
          onClick={onApprove}
          disabled={parishUserName === "" ? true : false}
          colorScheme="green"
          w="100px"
          borderRadius="2"
          //w="30"
          size="sm"
        >
          APPROVE
        </Button>
      </HStack>

      <Modal isOpen={isOpen} onClose={onCloseModel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {showModel === "reject" ? (
              <Text fontSize="sm">REJECT PARISH CREATION </Text>
            ) : (
              <Text fontSize="sm">CREATE NEW PARISH</Text>
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {showModel === "reject" ? (
              <div>
                <Text color={error?.rejectMessage && "red"}>
                  Reason {error?.rejectMessage && "is required"}
                </Text>
                <Textarea
                  {...register("reject", { required: true })}
                  placeholder="State Reason of your reject"
                  size="sm"
                />
              </div>
            ) : (
              <div>
                <Text>Reason</Text>
                <Textarea
                  name="approve"
                  {...register("approve", { required: "This is required" })}
                  value="You are welcome to Parishioner"
                  size="sm"
                />
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            {showModel === "reject" ? (
              <div>
                <Button
                  borderRadius="2"
                  size="sm"
                  w="90px"
                  bg="brand.700"
                  colorScheme="white"
                  mr={3}
                  onClick={onCloseModel}
                >
                  Close
                </Button>
                <Button
                  borderRadius="2"
                  size="sm"
                  w="90px"
                  onClick={sendRejectMail}
                  colorScheme="green"
                >
                  {isformSubmitting === "pending" ? (
                    <Spinner size="xs" />
                  ) : (
                    "Send Email"
                  )}
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  borderRadius="2"
                  size="sm"
                  w="90px"
                  bg="brand.700"
                  colorScheme="white"
                  mr={3}
                  onClick={onCloseModel}
                >
                  Close
                </Button>
                <Button
                  borderRadius="2"
                  size="sm"
                  w="90px"
                  onClick={sendApproveMail}
                  colorScheme="green"
                >
                  {isformSubmitting === "pending" ? (
                    <Spinner size="xs" />
                  ) : (
                    "Send Email"
                  )}
                </Button>
              </div>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModelGenApproveReject;
