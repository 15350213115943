import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

//send PDF
export const sendBulletin = createAsyncThunk(
  "dataBullMyP/sendBulletin",
  async (bulletin, { rejectWithValue }) => {
    try {
      const res = await axios.post("/bulletin", bulletin);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//send Bulletin
export const sendPDF = createAsyncThunk(
  "dataBullMyP/sendPDF",
  async (bulletin, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `/bulletin/${bulletin.bulletinId}/bulletinPdf`,
        bulletin
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//get All Bulletin
export const getBulletin = createAsyncThunk(
  "dataBullMyP/getBulletin",
  async (id = null, { rejectWithValue }) => {
    try {
      const res = await axios.get("/bulletins");
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//delete Bulletin
export const deleteBulletin = createAsyncThunk(
  "dataBullMyP/deleteBulletin",
  async (bulletin, { rejectWithValue }) => {
    try {
      const res = await axios.delete(
        `/bulletin/${bulletin.bulletinId}/dbulletinPdf`
      );

      return bulletin;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//send Mass Time
export const sendMassTime = createAsyncThunk(
  "dataBullMyP/sendMassTime",
  async (massTime, { rejectWithValue }) => {
    try {
      const res = await axios.post("/myparish/massTimes", massTime);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//send Confession Time
export const sendConfessionTime = createAsyncThunk(
  "dataBullMyP/ConfessionTime",
  async (confessionTime, { rejectWithValue }) => {
    try {
      const res = await axios.post("/myparish/confessiontime", confessionTime);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//get Mass Time
export const getMassTime = createAsyncThunk(
  "dataBullMyP/getMassTime",
  async (id = null, { rejectWithValue }) => {
    try {
      const res = await axios.get("/myparish/getmassTime");
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//get Confession Time
export const getConfessionTime = createAsyncThunk(
  "dataBullMyP/getConfessionTime",
  async (id = null, { rejectWithValue }) => {
    try {
      const res = await axios.get("/myparish/getconfessiontime");
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//update Mass Time
export const updateMassTime = createAsyncThunk(
  "dataBullMyP/updateMassTime",
  async (massTime, { rejectWithValue }) => {
    try {
      const res = await axios.post("/myparish/updateMassTime", massTime);
      return massTime;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//update Confession Time
export const updateConfessionTime = createAsyncThunk(
  "dataBullMyP/updateConfessionTime",
  async (confessionTime, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        "/myparish/updateconfessiontime",
        confessionTime
      );
      return confessionTime;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//update Parish Information
export const updateParishInformation = createAsyncThunk(
  "dataBullMyP/updateParishInformation",
  async (parishInformation, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        "/parishuser/updateParishInformation",
        parishInformation
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//delete Mass Time
export const deleteMassTime = createAsyncThunk(
  "dataBullMyP/deleteMassTime",
  async (id = null, { rejectWithValue }) => {
    try {
      const res = await axios.delete("/myparish/deletemasstime");
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//delete Confession Time
export const deleteConfessionTime = createAsyncThunk(
  "dataBullMyP/deleteConfessionTime",
  async (id = null, { rejectWithValue }) => {
    try {
      const res = await axios.delete("/myparish/deleteconfessiontime");
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  statusBulletin: null,
  statusMassTime: null,
  statusConfessionTime: null,
  statusGet: null,
  statusUpdate: null,
  statusDelete: null,
  bulletins: [],
  massTime: {},
  confessionTime: {},
  errorBulletin: null,
  errorMassTime: null,
  errorConfessionTime: null,
  errorParishInformation: null,
  selectedBulletin: {},
};

export const dataBullMyPSlice = createSlice({
  name: "dataBullMyP",
  initialState,
  reducers: {
    isErrorBulletin: (state, action) => {
      state.errorBulletin = action.payload;
    },
    removeBulletinError: (state, action) => {
      state.errorBulletin = null;
    },
    isErrorMassTime: (state, action) => {
      state.errorMassTime = action.payload;
    },
    removeErrorMassTime: (state, action) => {
      state.errorMassTime = null;
    },
    isErrorConfessionTime: (state, action) => {
      state.errorConfessionTime = action.payload;
    },
    removeErrorConfessionTime: (state, action) => {
      state.errorConfessionTime = null;
    },
    isSelectedBulletin: (state, action) => {
      state.selectedBulletin = action.payload;
    },
  },
  extraReducers: {
    //send PDF
    [sendBulletin.pending]: (state, action) => {
      state.statusBulletin = "pending";
    },
    [sendBulletin.fulfilled]: (state, action) => {
      state.statusBulletin = "success";
    },
    [sendBulletin.rejected]: (state, action) => {
      state.statusBulletin = "rejected";
      state.errorBulletin = action.payload;
    },

    //send Bulletin
    [sendPDF.pending]: (state, action) => {
      state.statusBulletin = "pending";
    },
    [sendPDF.fulfilled]: (state, action) => {
      state.statusBulletin = "success";

      if (state.bulletins.length >= 5) {
        state.bulletins.pop();
        state.bulletins.unshift(action.payload);
      } else {
        state.bulletins.unshift(action.payload);
      }
    },
    [sendPDF.rejected]: (state, action) => {
      state.statusBulletin = "rejected";
      state.errorBulletin = action.payload;
    },

    //get bulletin
    [getBulletin.pending]: (state, action) => {
      state.statusGet = "pending";
    },
    [getBulletin.fulfilled]: (state, action) => {
      state.statusGet = "success";
      state.bulletins = action.payload;
    },
    [getBulletin.rejected]: (state, action) => {
      state.statusGet = "rejected";
      state.errorBulletin = action.payload;
    },

    //delete Bulletin
    [deleteBulletin.pending]: (state, action) => {
      state.statusDelete = "pending";
    },
    [deleteBulletin.fulfilled]: (state, action) => {
      state.statusDelete = "success";
      let new_array = state.bulletins.filter(
        (bull) => bull.bulletinId !== action.payload.bulletinId
      );
      state.bulletins = new_array;
    },
    [deleteBulletin.rejected]: (state, action) => {
      state.statusDelete = "rejected";
      state.errorBulletin = action.payload;
    },

    //send Mass Times
    [sendMassTime.pending]: (state, action) => {
      state.statusMassTime = "pending";
    },
    [sendMassTime.fulfilled]: (state, action) => {
      state.statusMassTime = "success";
      state.massTime = action.payload;
    },
    [sendMassTime.rejected]: (state, action) => {
      state.statusMassTime = "rejected";
      state.errorMassTime = action.payload;
    },

    //send Confession Time
    [sendConfessionTime.pending]: (state, action) => {
      state.statusConfessionTime = "pending";
    },
    [sendConfessionTime.fulfilled]: (state, action) => {
      state.statusConfessionTime = "success";
      state.confessionTime = action.payload;
    },
    [sendConfessionTime.rejected]: (state, action) => {
      state.statusConfessionTime = "rejected";
      state.errorConfessionTime = action.payload;
    },

    //get Mass Time
    [getMassTime.pending]: (state, action) => {
      state.statusGet = "pending";
    },
    [getMassTime.fulfilled]: (state, action) => {
      state.statusGet = "success";
      state.massTime = action.payload;
    },
    [getMassTime.rejected]: (state, action) => {
      state.statusGet = "rejected";
      state.errorMassTime = action.payload;
    },

    //get Confession Time
    [getConfessionTime.pending]: (state, action) => {
      state.statusGet = "pending";
    },
    [getConfessionTime.fulfilled]: (state, action) => {
      state.statusGet = "success";
      state.confessionTime = action.payload;
    },
    [getConfessionTime.rejected]: (state, action) => {
      state.statusGet = "rejected";
      state.errorConfessionTime = action.payload;
    },

    //update Mass Time
    [updateMassTime.pending]: (state, action) => {
      state.statusUpdate = "pending";
    },
    [updateMassTime.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      state.massTime = action.payload;
    },
    [updateMassTime.rejected]: (state, action) => {
      state.statusUpdate = "rejected";
      state.errorMassTime = action.payload;
    },

    //update Confession Time
    [updateConfessionTime.pending]: (state, action) => {
      state.statusUpdate = "pending";
    },
    [updateConfessionTime.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      state.confessionTime = action.payload;
    },
    [updateConfessionTime.rejected]: (state, action) => {
      state.statusUpdate = "rejected";
      state.errorConfessionTime = action.payload;
    },

    //update parishInformation
    [updateParishInformation.pending]: (state, action) => {
      state.statusUpdate = "pending";
    },
    [updateParishInformation.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
    },
    [updateParishInformation.rejected]: (state, action) => {
      state.statusUpdate = "rejected";
      state.errorParishInformation = action.payload;
    },

    //delete Mass Time
    [deleteMassTime.pending]: (state, action) => {
      state.statusDelete = "pending";
    },
    [deleteMassTime.fulfilled]: (state, action) => {
      state.statusDelete = "success";
      state.massTime = {};
    },
    [deleteMassTime.rejected]: (state, action) => {
      state.statusDelete = "rejected";
      state.errorMassTime = action.payload;
    },

    //delete Confession Time
    [deleteConfessionTime.pending]: (state, action) => {
      state.statusDelete = "pending";
    },
    [deleteConfessionTime.fulfilled]: (state, action) => {
      state.statusDelete = "success";
      state.confessionTime = {};
    },
    [deleteConfessionTime.rejected]: (state, action) => {
      state.statusDelete = "rejected";
      state.errorConfessionTime = action.payload;
    },
  },
});
export const {
  isErrorBulletin,
  removeBulletinError,
  isSelectedBulletin,
  isErrorMassTime,
  removeErrorMassTime,
  isErrorConfessionTime,
  removeErrorConfessionTime,
} = dataBullMyPSlice.actions;
export default dataBullMyPSlice.reducer;
