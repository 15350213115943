import { React } from "react";
import {
  Text,
  HStack,
  IconButton,
  Stack,
  Divider,
  Link,
  useColorModeValue as mode,
  Button,
  Spinner,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  Box,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteAnnouncement,
  isSelectedMessage,
  removeErrorAnnouncement,
  updateAnnouncement,
} from "../../features/dataAll/dataMegAnnEvtSlice";
import { DeleteIcon } from "@chakra-ui/icons";
import { useDisclosure } from "@chakra-ui/hooks";
import { useState } from "react";
import { useToast } from "@chakra-ui/toast";
import {
  ConvertStartEndDate,
  DateConvert,
  notificationData,
} from "../../util/functions";
import { NotifyIndex } from "../notification/notifyIndex";
import { BiErrorCircle, BiCheckDouble } from "react-icons/bi";
import { logoutUser } from "../../features/user/userSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Announcement } from "../../pages/announcement";

export const SentAnnouncement = () => {
  const [isDisableAnnouncement, setIsDisableAnnouncement] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const announcements = useSelector(
    (state) => state.dataMegAnnEvt.announcements
  );
  //const selected = useSelector((state) => state.dataMegAnnEvt.selectedMessage);
  const isUpdating = useSelector((state) => state.dataMegAnnEvt.statusUpdate);
  const isDeleting = useSelector((state) => state.dataMegAnnEvt.statusDelete);
  const isError = useSelector((state) => state.dataMegAnnEvt.errorAnnouncement);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState({
    announcementId: "",
  });
  const [sundayDate, setSundayDate] = useState(null);
  const [quillValue, setQuillValue] = useState("");

  //handle click of posted announcements
  const postedClick = (announcement, e) => {
    const thisIsSelected = {
      announcementDetails: announcement.announcementDetails,
      sundayDate: announcement.sundayDate,
      announcementId: announcement.announcementId,
    };
    dispatch(isSelectedMessage(thisIsSelected));
    setSelectedAnnouncement({
      ...selectedAnnouncement,
      announcementId: announcement.announcementId,
    });
    setSundayDate(new Date(announcement.sundayDate));
    setQuillValue(announcement.announcementDetails);
    dispatch(removeErrorAnnouncement());
    onOpen();
  };

  //count the characters and set its quantity to useState
  //check if characters is less than 250
  // const count = (e) => {
  //   let inputValue = e.target.value;
  //   setSelectedAnnouncement({
  //     ...selectedAnnouncement,
  //     countMessageBody: inputValue.length,
  //   });
  //   if (inputValue.length > 250) {
  //     setIsDisableAnnouncement(true);
  //   } else {
  //     setIsDisableAnnouncement(false);
  //   }
  // };
  // function EditChangeTitle(announcement) {
  //   //e.preventDefault();
  //   setSelectedAnnouncement({
  //     ...selectedAnnouncement,
  //     eventTitle: announcement.target.value,
  //   });
  // }

  // function EditChangeMessage(event) {
  //   //e.preventDefault();
  //   setSelectedAnnouncement({
  //     ...selectedAnnouncement,
  //     eventDetails: event.target.value,
  //   });
  // }

  function EditSend(e) {
    e.preventDefault();
    const theSundayDate = sundayDate == null ? "" : sundayDate;
    const theQuillValue = quillValue === "<p><br></p>" ? "" : quillValue;
    var rawQuillValue = quillValue
      .replace(/<[^>]+>/g, "")
      .replace(/\&nbsp;/g, "");
    const newAnnouncement = {
      announcementDetails: theQuillValue,
      sundayDate: theSundayDate,
      announcementId: selectedAnnouncement.announcementId,
      rawAnnouncementDetails: rawQuillValue,
    };

    dispatch(updateAnnouncement(newAnnouncement))
      .unwrap()
      .then((originalPromiseResult) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Sunday Announcement",
            "Update Complete",
            "green",
            BiCheckDouble
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
          onClose();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Sunday Announcement",
            rejectedValueOrSerializedError,
            "red",
            BiErrorCircle
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
        } else if (
          rejectedValueOrSerializedError?.code === "auth/id-token-expired"
        ) {
          dispatch(logoutUser());
        }
      });
  }
  function CloseModel() {
    setIsDisableAnnouncement(false);
    setConfirmDelete(false);
    dispatch(removeErrorAnnouncement());
    onClose();
  }
  function IsDelete() {
    setConfirmDelete(true);
  }
  function YesDelete() {
    const newAnnouncement = {
      announcementId: selectedAnnouncement.announcementId,
    };
    dispatch(deleteAnnouncement(newAnnouncement))
      .unwrap()
      .then((originalPromiseResult) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Sunday Announcement",
            "Delete Complete",
            "green",
            BiCheckDouble
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });

          setConfirmDelete(false);
          onClose();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Sunday Announcement",
            rejectedValueOrSerializedError,
            "red",
            BiErrorCircle
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
        } else if (
          rejectedValueOrSerializedError?.code === "auth/id-token-expired"
        ) {
          dispatch(logoutUser());
        }
      });
  }

  return (
    <>
      {announcements.map((announcement) => (
        <Link
          key={announcement.announcementId}
          _hover={{ bg: "brand.50" }}
          // bg={
          //   signupRequestData.requestId === request.requestId &&
          //   "brand.400"
          // }
          _active={{
            bg: mode("gray.200", "gray.700"),
            color: "inherit",
          }}
          bg="brand.100"
          borderRadius={5}
          onClick={(e) => postedClick(announcement, e)}
        >
          <Stack p={4}>
            <HStack>
              <Text fontWeight={"semibold"} fontSize="xs">
                Occassion:
              </Text>
              <Text isTruncated color={"brand.900"} fontSize="xs">
                {announcement.occassion}
              </Text>
            </HStack>
            <Text fontWeight={"semibold"} fontSize="xs">
              Details:
            </Text>
            <Text color={"brand.900"} fontSize="xs" noOfLines={3}>
              {announcement.announcementDetails
                .replace(/<[^>]+>/g, "")
                .replace(/\&nbsp;/g, "")}
            </Text>
            <HStack>
              <Text fontWeight={"semibold"} fontSize="xs">
                Sunday Date:
              </Text>
              <Text isTruncated color={"brand.900"} fontSize="xs">
                {ConvertStartEndDate(announcement.sundayDate)}
              </Text>
            </HStack>

            <Divider />
            <HStack justifyContent={"space-between"}>
              <Text color={"brand.900"} fontSize="xs">
                Crated on: {DateConvert(announcement.createdAt)}
              </Text>
              <IconButton
                variant={"ghost"}
                size={"sm"}
                aria-label="Delete"
                icon={<DeleteIcon />}
                onClick={IsDelete}
              />
            </HStack>
          </Stack>
        </Link>
      ))}
      {/* {modal} */}
      <Modal isOpen={isOpen} onClose={CloseModel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {" "}
            {confirmDelete == true
              ? "Confirm Delete"
              : "Eidt Announcement"}{" "}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {confirmDelete === true ? (
              <div>
                <Stack spacing={"4"} justifyContent={"center"}>
                  <Text py="4">
                    Are you sure you want to Delete this Announcement
                  </Text>
                  <HStack>
                    <Button
                      variant={"outline"}
                      color="brand.900"
                      mr={3}
                      width={"full"}
                      onClick={CloseModel}
                      borderColor={"brand.900"}
                    >
                      NO
                    </Button>
                    <Button
                      variant={"outline"}
                      color="brand.900"
                      mr={3}
                      width={"full"}
                      onClick={YesDelete}
                    >
                      {isDeleting === "pending" ? <Spinner size="sm" /> : "YES"}
                    </Button>
                  </HStack>
                </Stack>
              </div>
            ) : (
              <div>
                <Stack mb={"6"}>
                  {
                    <Text as="sub" color={"red.500"}>
                      {isError?.sundayDate}
                    </Text>
                  }
                  <Box
                    borderColor={"gray.300"}
                    borderWidth={"1px"}
                    maxWidth={"fit-content"}
                  >
                    <DatePicker
                      selected={sundayDate}
                      onChange={(date) => setSundayDate(date)}
                      disabled
                      placeholderText="Select Sunday Date"
                    />
                  </Box>
                </Stack>
                <Text as="sub" color={"red.500"}>
                  {isError?.announcementDetails}
                </Text>
                <ReactQuill
                  modules={Announcement.modules}
                  formats={Announcement.formats}
                  onChange={setQuillValue}
                  theme="snow"
                  value={quillValue}
                  placeholder="Your Sunday Announcement Details"
                />
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            {confirmDelete === false && (
              <HStack width={"full"}>
                {" "}
                <Button
                  variant={"outline"}
                  color="brand.900"
                  mr={3}
                  onClick={CloseModel}
                >
                  Close
                </Button>
                {/* <Button
                  variant={"outline"}
                  color="brand.900"
                  mr={3}
                  onClick={IsDelete}
                >
                  Delete
                </Button> */}
                <Button
                  colorScheme="brand.700"
                  bg="brand.700"
                  isDisabled={isDisableAnnouncement}
                  onClick={EditSend}
                  w="full"
                >
                  {isUpdating === "pending" ? (
                    <Spinner size="sm" />
                  ) : (
                    "Save Edit"
                  )}
                </Button>
              </HStack>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

// (
//   announcements.map((message) => (
//     <Link
//       key={message.messageId}
//       _hover={{ bg: "brand.50" }}
//       // bg={
//       //   signupRequestData.requestId === request.requestId &&
//       //   "brand.400"
//       // }
//       _active={{
//         bg: mode("gray.200", "gray.700"),
//         color: "inherit",
//       }}
//       bg="brand.100"
//       borderRadius={5}
//       onClick={(e) => postedClick(message, e)}
//     >
//       <Stack p={4}>
//         <HStack>
//           <Text fontWeight={"semibold"} fontSize="xs">
//             Title:
//           </Text>
//           <Text
//             isTruncated
//             color={"brand.900"}
//             fontSize="xs"
//           >
//             {message.messageTitle}
//           </Text>
//         </HStack>
//         <Text fontWeight={"semibold"} fontSize="xs">
//           Details:
//         </Text>
//         <Text
//           color={"brand.900"}
//           fontSize="xs"
//           noOfLines={3}
//         >
//           {message.messageBody}
//         </Text>
//         <Divider />
//         <HStack justifyContent={"space-between"}>
//           <Text color={"brand.900"} fontSize="xs">
//             Crated on: {message.createdAt}
//           </Text>
//           <IconButton
//             variant={"ghost"}
//             size={"sm"}
//             aria-label="Delete"
//             icon={<DeleteIcon />}
//           />
//         </HStack>
//       </Stack>
//     </Link>
//   ))
// )
