import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getSignupRequest = createAsyncThunk(
  "dataAdmin/getSignupRequest",
  async (id = null, { rejectWithValue }) => {
    try {
      const res = await axios.get("/getSignupRequest");
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  getSignupRequests: [],
  statusSignRequest: null,
  errorSignRequest: null,
};

export const dataAdminSlice = createSlice({
  name: "dataAdmin",
  initialState,
  reducers: {
    removeSignupRequestFromState: (state, action) => {
      let index = state.getSignupRequests.findIndex(
        (getSignupRequest) => getSignupRequest.requestId === action.payload
      );
      state.getSignupRequests.splice(index, 1);
    },
  },
  extraReducers: {
    //Get UserData
    [getSignupRequest.pending]: (state, action) => {
      state.statusSignRequest = "pending";
    },
    [getSignupRequest.fulfilled]: (state, action) => {
      state.statusSignRequest = "success";
      state.getSignupRequests = action.payload;
    },
    [getSignupRequest.rejected]: (state, action) => {
      state.statusSignRequest = "rejected";
      state.errorSignRequest = action.payload;
    },
  },
});
export const { removeSignupRequestFromState } = dataAdminSlice.actions;
export default dataAdminSlice.reducer;
