import {
  Box,
  Button,
  Select,
  SimpleGrid,
  GridItem,
  HStack,
  Text,
  Divider,
} from "@chakra-ui/react";
import React from "react";
import { useBreakpointValue } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  isDisableMomoPay,
  isMomoAmount,
  isNoMonths,
  selectedSubscription,
} from "../../features/dataAll/dataSubscriptionSlice";
import { useEffect } from "react";
import { PayWithMomo } from "./payWithMomo";
import { PayWithCard } from "./payWithCard";

export const SelectPayment = () => {
  const col = useBreakpointValue({ base: "1", md: "3" });
  const [paymentType, setpaymentType] = useState();
  const credentials = useSelector(
    (state) => state.user.credentials.credentials
  );
  const selectedPlan = useSelector(
    (state) => state.dataSubscription.selectedPlan
  );
  const subscribeData = useSelector(
    (state) => state.dataSubscription.selectedSubData
  );
  const dataSubscription = useSelector((state) => state.dataSubscription);
  const noMonths = useSelector((state) => state.dataSubscription.noMonths);
  const momoAmount = useSelector((state) => state.dataSubscription.momoAmount);
  const [disableMomoPay, setdisableMomoPay] = useState(true);
  const { register, handleSubmit, reset, control } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    const fromparishSubscribedData = {
      plan: dataSubscription.parishSubscribedData.plan,
      amount: dataSubscription.parishSubscribedData.uiAmount,
    };
    dispatch(selectedSubscription(fromparishSubscribedData));
  }, []);
  useEffect(() => {
    if (momoAmount !== "") {
      setdisableMomoPay(false);
    }
    // const fromparishSubscribedData = {
    //   plan: dataSubscription.parishSubscribedData.plan,
    //   amount: dataSubscription.parishSubscribedData.uiAmount,
    // };
    // dispatch(selectedSubscription(fromparishSubscribedData));
  }, [momoAmount]);

  const handleOnchange = (e) => {
    // setpaymentType(data?.payment);
    setpaymentType(e.target.value);
    dispatch(isMomoAmount(""));
    dispatch(isDisableMomoPay(true));
    //setMomoAmount("");
    setdisableMomoPay(true);
    console.log(e.target.value);
  };

  function calculateAmount(params, type) {
    if (type === "card") {
      const amount = parseInt(params) * 100;
      return amount.toString();
    }
    if (type === "momo") {
      const amount = parseInt(momoAmount) * 100;
      return amount.toString();
    }
  }

  function selectPeriod(params) {
    dispatch(isNoMonths(params));
    dispatch(isMomoAmount(subscribeData.amount * params));
  }

  return (
    <Box my="4">
      <Box>
        {selectedPlan === "Standard" && (
          <SimpleGrid columns={col} columnGap={3} rowGap={2} w="full">
            <GridItem colSpan={1}>
              {/* <Button onClick={(e) => handleOnchange("CreditCard")}>
                Credit / Debit Card
              </Button>
            </GridItem>
            <GridItem colSpan={1}>
              <Button onClick={(e) => handleOnchange("Momo")}>
                Mobile Money
              </Button> */}

              <Select
                name="payment"
                type="text"
                placeholder="Select Payment Type"
                my="3"
                {...register("payment")}
                // onClick={handleSubmit(handleOnchange)}
                onChange={(e) => handleOnchange(e)}
              >
                <option value="CreditCard">Credit / Debit Card</option>
                <option value="Momo">Mobile Money</option>
              </Select>
            </GridItem>

            <GridItem colSpan={2}>
              <Box>
                <div>
                  {" "}
                  {paymentType === "Momo" || paymentType === "CreditCard" ? (
                    <Box>
                      {paymentType === "Momo" && (
                        <Box my="3" px={{ base: "0", md: "4", lg: "8" }}>
                          <Text pb="2">Select Subscription period</Text>
                          <HStack justifyContent={"space-between"}>
                            {" "}
                            <Button
                              bg="brand.100"
                              color="brand.900"
                              size={"sm"}
                              onClick={(e) => selectPeriod(1)}
                            >
                              <Text fontSize={"xs"}>1 Month</Text>
                            </Button>{" "}
                            <Button
                              bg="brand.100"
                              color="brand.900"
                              size={"sm"}
                              onClick={(e) => selectPeriod(3)}
                            >
                              <Text fontSize={"xs"}>3 Months</Text>
                            </Button>
                            <Button
                              bg="brand.100"
                              color="brand.900"
                              size={"sm"}
                              onClick={(e) => selectPeriod(6)}
                            >
                              <Text fontSize={"xs"}>6 Months</Text>
                            </Button>
                            <Button
                              bg="brand.100"
                              color="brand.900"
                              size={"sm"}
                              onClick={(e) => selectPeriod(12)}
                            >
                              <Text fontSize={"xs"}>1 Year</Text>
                            </Button>
                          </HStack>{" "}
                        </Box>
                      )}

                      <Divider pt="2"></Divider>
                      <Box px={{ base: "0", md: "8" }}>
                        {paymentType === "Momo" ? (
                          <HStack justifyContent={"space-between"}>
                            <Text bg="gray.200" p="2">
                              Amt: GHS {momoAmount}
                            </Text>
                            <PayWithMomo></PayWithMomo>
                          </HStack>
                        ) : (
                          <HStack justifyContent={"space-between"}>
                            <Text bg="gray.200" p="2">
                              Amt: GHS {subscribeData?.amount}
                            </Text>
                            <PayWithCard></PayWithCard>
                          </HStack>
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <div></div>
                  )}
                </div>
              </Box>
            </GridItem>
          </SimpleGrid>
        )}
      </Box>
    </Box>
  );
};

/*

 

*/
