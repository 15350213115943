import React from "react";
import {
  Flex,
  Box,
  Text,
  Icon,
  SlideFade,
  HStack,
  IconButton,
  Stack,
  Input,
  FormControl,
  Button,
  SimpleGrid,
  Spinner,
  Heading,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { Redirect, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { RiMenuFoldFill, RiMenuUnfoldFill } from "react-icons/ri";
import { DeleteIcon } from "@chakra-ui/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getParishEvent,
  removeErrorParishEvent,
  sendParishEvent,
} from "../features/dataAll/dataMegAnnEvtSlice";
import { useToast } from "@chakra-ui/toast";
import { IsBackEndSessionExpired, notificationData } from "../util/functions";
import { NotifyIndex } from "../components/notification/notifyIndex";
import { BiErrorCircle, BiCheckDouble } from "react-icons/bi";
import { logoutUser } from "../features/user/userSlice";
import { SkeletonMessage } from "../components/common/skeleton";
import { SentParishEvent } from "../components/parishEvent/sentParishEvent";
import { useForm } from "react-hook-form";

const Event = () => {
  const [showPanel, setShowPanel] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [quillValue, setQuillValue] = useState("");
  const toast = useToast();
  const userSubcription = useSelector(
    (state) => state.user.credentials.subscription
  );
  const isGetParishEventPending = useSelector(
    (state) => state.dataMegAnnEvt.statusGet
  );
  const isformSubmitting = useSelector(
    (state) => state.dataMegAnnEvt.statusParishEvent
  );
  const { register, reset, handleSubmit } = useForm();
  const isError = useSelector((state) => state.dataMegAnnEvt.errorParishEvent);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const match = search.match(/status=(.*)/);
  const status = match?.[1];

  useEffect(() => {
    dispatch(getParishEvent());
  }, []);

  const base1 = () => {
    if (showPanel === true) {
      return "block";
    } else {
      return "none";
    }
  };

  const base2 = () => {
    if (showPanel === true) {
      return "none";
    } else {
      return "block";
    }
  };

  function rightSideOnclick() {
    setShowPanel(false);
  }

  function rightSideOffclick() {
    setShowPanel(true);
  }

  function onSubmit(data, e) {
    e.preventDefault();
    const theStartDate = startDate == null ? "" : startDate;
    const theEndDate = endDate == null ? "" : endDate;
    var rawQuillValue = quillValue
      .replace(/<[^>]+>/g, "")
      .replace(/\&nbsp;/g, "");
    // console.log(strippedHtml);
    const newEvent = {
      eventTitle: data.title,
      startDate: theStartDate,
      endDate: theEndDate,
      eventDetails: quillValue,
      rawEventDetails: rawQuillValue,
    };
    dispatch(sendParishEvent(newEvent))
      .unwrap()
      .then((originalPromiseResult) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Parish Event",
            "Sent",
            "green",
            BiCheckDouble
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
          setDateRange([null, null]);
          setQuillValue("");
          reset();
          dispatch(removeErrorParishEvent());
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Parish Message",
            rejectedValueOrSerializedError,
            "red",
            BiErrorCircle
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
        } else if (
          rejectedValueOrSerializedError?.code == "auth/id-token-expired"
        ) {
          dispatch(logoutUser());
          //IsBackEndSessionExpired();
        }
      });
  }

  return (
    <div>
      {status === "parishEvent" && userSubcription?.parishEvent ? (
        <Flex>
          <Flex
            flex="1"
            bg="brand.20"
            h={{ base: "94vh", md: "90vh" }}
            display={{
              base: base1,
              md: "block",
            }}
          >
            <Box rounded="base" h="full" w="full">
              <Box py="2" bg="white" boxShadow={"sm"}>
                <HStack height={{ base: "16", md: "12" }}>
                  <Box display="flex" justifyContent={"center"} width="full">
                    <Heading size={"sm"} color="brand.800">
                      Send Event to Parishioners
                    </Heading>
                  </Box>

                  <IconButton
                    size="lg"
                    colorScheme="brand.100"
                    onClick={rightSideOnclick}
                    display={{ md: "none" }}
                  >
                    <Icon color="brand.900" as={RiMenuFoldFill}></Icon>
                  </IconButton>
                </HStack>
              </Box>
              <Box m="0.5" h="full" bg="white">
                <Box pl="10" pr="10" pt="4">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                      m="0.5"
                      h={{ base: "60vh", md: "60vh" }}
                      overflowY="auto"
                    >
                      <Stack>
                        <FormControl id="title">
                          <Input
                            mb="4"
                            size="lg"
                            name="title"
                            type="text"
                            required
                            placeholder="Event Title"
                            bg={mode("white", "gray.700")}
                            fontSize="md"
                            {...register("title")}
                          />
                        </FormControl>
                        <FormControl>
                          <SimpleGrid
                            columns={{ base: 1, md: 2 }}
                            spacing={{ base: 4, md: 10 }}
                            mb={{ base: "0", md: "5" }}
                          >
                            <Stack>
                              {
                                <Text as="sub" color={"red.500"}>
                                  {isError?.endDate}
                                </Text>
                              }
                              <Box
                                borderColor={"gray.300"}
                                borderWidth={"1px"}
                                maxWidth={"fit-content"}
                              >
                                <DatePicker
                                  selectsRange={true}
                                  startDate={startDate}
                                  endDate={endDate}
                                  placeholderText="Start and End Date"
                                  onChange={(update) => {
                                    setDateRange(update);
                                  }}
                                  isClearable={true}
                                />
                              </Box>
                            </Stack>

                            <Box
                              justifySelf={{ base: "normal", md: "flex-end" }}
                            >
                              <Box
                                borderColor={"gray.300"}
                                borderWidth={"1px"}
                                maxWidth={"fit-content"}
                              ></Box>
                            </Box>
                          </SimpleGrid>
                        </FormControl>
                        <FormControl>
                          <Text as="sub" color={"red.500"}>
                            {isError?.eventDetails}
                          </Text>
                          <ReactQuill
                            value={quillValue}
                            modules={Event.modules}
                            formats={Event.formats}
                            onChange={setQuillValue}
                            theme="snow"
                            placeholder="Parish Event Details"
                          />
                        </FormControl>
                      </Stack>
                    </Box>
                    <Button
                      size="lg"
                      type="submit"
                      mt="8"
                      w="full"
                      bg="brand.700"
                      colorScheme="white"
                      fontSize="md"
                      fontWeight="bold"
                    >
                      {isformSubmitting === "pending" ? (
                        <Spinner size="xs" />
                      ) : (
                        "SEND "
                      )}
                    </Button>
                  </form>
                </Box>
              </Box>
            </Box>
          </Flex>

          <Flex
            display={{
              base: base2,
              lg: "block",
            }}
            flex={{ base: "1", md: "none" }}
            width="96"
            direction="column"
            borderLeftWidth="1px"
            pl={{ base: "10", md: "0" }}
            h="93vh"
          >
            {" "}
            <SlideFade
              offsetY="20px"
              SlideDirection="top"
              in={showPanel == true ? true : true}
            >
              <Box
                rounded="base"
                h={{ base: "88vh", md: "92vh", lg: "92vh" }}
                bg="white"
                shadow={{
                  base: "xl",
                  md: "none",
                }}
                borderRadius={{ base: "none", md: "5" }}
                borderLeftRadius={{ base: "3xl", md: "5" }}
              >
                <Box boxShadow={"sm"} py="2">
                  <HStack height={{ base: "16", md: "12" }}>
                    <Box display="flex" justifyContent={"center"} width="full">
                      <HStack spacing={1}>
                        <Heading size={"sm"} color="brand.800">
                          Sent Parish Events
                        </Heading>
                        <Text as="i" fontSize="8">
                          {"(first 5)"}
                        </Text>
                      </HStack>
                    </Box>

                    <IconButton
                      colorScheme="brand.100"
                      onClick={rightSideOffclick}
                      display={{ md: "none" }}
                      size={"lg"}
                    >
                      <Icon color="brand.900" as={RiMenuUnfoldFill}></Icon>
                    </IconButton>
                  </HStack>
                </Box>
                <Box
                  overflowY="auto"
                  h={{ base: "78vh", md: "84vh", lg: "82vh" }}
                >
                  <Stack m={6}>
                    {isGetParishEventPending == "pending" ? (
                      <SkeletonMessage />
                    ) : (
                      <SentParishEvent />
                    )}
                  </Stack>
                </Box>
              </Box>
            </SlideFade>
          </Flex>
        </Flex>
      ) : (
        <div>
          <Redirect to="/home"></Redirect>
        </div>
      )}
    </div>
  );
};

export default Event;
//marginTop={{base:"none",md:"16"}}

Event.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill Event formats
 * See https://quilljs.com/docs/formats/
 */
Event.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];
