import React from "react";
import { Box, Button, Text, Spinner } from "@chakra-ui/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isErrorConfessionTime,
  removeErrorConfessionTime,
  removeErrorMassTime,
  sendConfessionTime,
  sendMassTime,
} from "../../features/dataAll/dataBullMyPSlice";
import { NotifyIndex } from "../notification/notifyIndex";
import { notificationData } from "../../util/functions";
import { useToast } from "@chakra-ui/toast";
import { BiErrorCircle, BiCheckDouble } from "react-icons/bi";
import { logoutUser } from "../../features/user/userSlice";

export const ContentInput = (props) => {
  //   const [quillValue, setQuillValue] = useState({
  //     massValue: "",
  //     confessionValue: "",
  //   });
  const isMassTimeSubmitting = useSelector(
    (state) => state.dataBullMyP.statusMassTime
  );
  const isConfessionTimeSubmitting = useSelector(
    (state) => state.dataBullMyP.statusConfessionTime
  );
  const isErrorMassTime = useSelector(
    (state) => state.dataBullMyP.errorMassTime
  );
  const isErrorConfessionTime = useSelector(
    (state) => state.dataBullMyP.errorConfessionTime
  );

  const [quillValue, setQuillValue] = useState("");
  const dispatch = useDispatch();
  const toast = useToast();

  const handleOnchange = (e) => {
    if (props.name === "mass") {
      //   setQuillValue((prev) => {
      //     return { ...prev, massValue: e };
      //   });
      return setQuillValue(e);
    } else {
      //   setQuillValue((prev) => {
      //     return { ...prev, confessionValue: e };
      //   });
      return setQuillValue(e);
    }
  };
  const handleButtonClick = () => {
    const theQuillValue = quillValue === "<p><br></p>" ? "" : quillValue;
    var rawQuillValue = quillValue
      .replace(/<[^>]+>/g, "")
      .replace(/&nbsp;/g, " ");
    if (props.name === "mass") {
      const newMassTimes = {
        massTimes: theQuillValue,
        rawMassTimes: rawQuillValue,
      };
      dispatch(sendMassTime(newMassTimes))
        .unwrap()
        .then((originalPromiseResult) => {
          const notificationErrorData = notificationData(
            "Mass Time",
            "Sent",
            "green",
            BiCheckDouble
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
          setQuillValue("");
        })
        .catch((rejectedValueOrSerializedError) => {
          if (typeof rejectedValueOrSerializedError != "object") {
            const notificationErrorData = notificationData(
              "Mass Time",
              rejectedValueOrSerializedError,
              "red",
              BiErrorCircle
            );

            toast({
              position: "bottom-left",
              render: () => (
                <div>
                  {" "}
                  <NotifyIndex notificationdata={notificationErrorData} />
                </div>
              ),
            });
          } else if (
            rejectedValueOrSerializedError?.code == "auth/id-token-expired"
          ) {
            dispatch(logoutUser());
          }
        });
      dispatch(removeErrorMassTime());
    } else {
      const newConfessionTime = {
        confessionTime: theQuillValue,
        rawConfessionTime: rawQuillValue,
      };
      dispatch(sendConfessionTime(newConfessionTime))
        .unwrap()
        .then((originalPromiseResult) => {
          const notificationErrorData = notificationData(
            "Confession Time",
            "Sent",
            "green",
            BiCheckDouble
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
          setQuillValue("");
        })
        .catch((rejectedValueOrSerializedError) => {
          if (typeof rejectedValueOrSerializedError != "object") {
            const notificationErrorData = notificationData(
              "Confession Time",
              rejectedValueOrSerializedError,
              "red",
              BiErrorCircle
            );

            toast({
              position: "bottom-left",
              render: () => (
                <div>
                  {" "}
                  <NotifyIndex notificationdata={notificationErrorData} />
                </div>
              ),
            });
          } else if (
            rejectedValueOrSerializedError?.code == "auth/id-token-expired"
          ) {
            dispatch(logoutUser());
          }
        });
      dispatch(removeErrorConfessionTime());
    }
  };
  //   const handleValue = () => {
  //     if (props.name === "mass") {
  //       //   return quillValue.massValue;
  //       return quillValue;
  //     } else {
  //       //   return quillValue.confessionValue;
  //       return quillConfValue;
  //     }
  //   };
  return (
    <Box>
      <Text as="sub" color={"red.500"}>
        {isErrorConfessionTime?.confessionTime}
        {isErrorMassTime?.massTimes}
      </Text>
      <ReactQuill
        value={quillValue}
        modules={ContentInput.modules}
        formats={ContentInput.formats}
        onChange={handleOnchange}
        theme="snow"
        placeholder=" Enter Your Details Here"
      />
      <Button
        size="lg"
        type="submit"
        mt="8"
        w="full"
        bg="brand.700"
        colorScheme="white"
        fontSize="md"
        fontWeight="bold"
        onClick={handleButtonClick}
      >
        {isMassTimeSubmitting === "pending" ||
        isConfessionTimeSubmitting === "pending" ? (
          <Spinner size="xs" />
        ) : (
          "SEND "
        )}
      </Button>
    </Box>
  );
};

ContentInput.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill ContentInput formats
 * See https://quilljs.com/docs/formats/
 */
ContentInput.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];
