import React from "react";
import {
  Spinner,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  Center,
} from "@chakra-ui/react";
import { useEffect } from "react";
import {
  createSubscriptionPayStack,
  isDisableMomoPay,
} from "../../features/dataAll/dataSubscriptionSlice";
import { useDispatch, useSelector } from "react-redux";

export const PayWithCard = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();
  const dispatch = useDispatch();
  const credentials = useSelector(
    (state) => state.user.credentials.credentials
  );
  const selectedPlan = useSelector(
    (state) => state.dataSubscription.selectedPlan
  );
  const subscribeData = useSelector(
    (state) => state.dataSubscription.selectedSubData
  );
  const momoAmount = useSelector((state) => state.dataSubscription.momoAmount);
  const noMonths = useSelector((state) => state.dataSubscription.noMonths);
  const disableMomoPay = useSelector(
    (state) => state.dataSubscription.disableMomoPay
  );
  const isGetPending = useSelector((state) => state.dataSubscription.statusGet);

  useEffect(() => {
    if (momoAmount !== "") {
      //setdisableMomoPay(false);
      dispatch(isDisableMomoPay(false));
    }
  }, [momoAmount]);

  function calculateAmount(params, type) {
    if (type === "card") {
      const amount = parseInt(params) * 100;
      return amount.toString();
    }
    if (type === "momo") {
      const amount = parseInt(momoAmount) * 100;
      return amount.toString();
    }
  }
  function payWithCard() {
    setIsOpen(true);
    const amount = calculateAmount(subscribeData.amount, "card");

    // const paramsPlan = {
    //   plan: subscribeData.plan,
    //   amount: amount,
    // };
    // dispatch(createPlan(paramsPlan))
    //   .unwrap()
    //   .then((originalPromiseResult) => {
    //     if (typeof originalPromiseResult != "object") {
    const params = {
      // planCode: originalPromiseResult,
      //subscribeData.plan
      email: credentials.parishEmail,
      amount: amount,
      currency: "GHS",
      reference: new Date().getTime().toString(),
      channels: ["card"],
      //plan: "Plus",
      plan: subscribeData.plan,
      parishUserName: credentials.parishUserName,
      parishName: credentials.parishName,
      noMonths: 1,
      uiAmount: subscribeData.amount,
    };
    dispatch(createSubscriptionPayStack(params))
      .unwrap()
      .then((originalPromiseResult) => {
        if (typeof originalPromiseResult != "object") {
          dispatch(isDisableMomoPay(false));
          window.location.href = originalPromiseResult;
        }
      })
      .catch((rejectedValueOrSerializedError) => {});
  }

  return (
    <>
      <Button
        color="white"
        bg="brand.700"
        px="2"
        onClick={() => {
          payWithCard();
        }}
      >
        Continue to Pay
        {/* {isGetPending === "pending" && <Spinner size="xs" ml="2" />} */}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay justifyContent={"center"}>
          <Center h="100vh">
            <Spinner color="brand.600"></Spinner>
          </Center>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
