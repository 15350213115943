import {
  Box,
  HStack,
  Link,
  Text,
  VStack,
  SimpleGrid,
  useBreakpointValue,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import React, { useState } from "react";
import FeaturesText from "./featuresText";
import { useDispatch } from "react-redux";
import {
  selectedSubscription,
  selectPlan,
} from "../../features/dataAll/dataSubscriptionSlice";

const SubscriptionCard = (props) => {
  const { name, isActive } = props;
  const dispatch = useDispatch();
  // const [isColor, setColor] = useState("brand.100");
  // const subscribeData = useSelector(
  //   (state) => state.dataSubscription.selectedSubData
  // );
  const col = useBreakpointValue({ base: "2", md: "2" });

  //   useEffect(() => {
  //     if(props.name ==="name")
  //   }, []);

  //   _activeLink={{
  //     bg: mode("brand.600", "gray.700"),
  //     color: "inherit",
  //   }}

  //text black
  //   _activeLink={{
  //     color: mode("brand.900", "gray.700"),,
  //   }}

  const HandleCard = (name) => {
    dispatch(selectedSubscription(name));
    dispatch(selectPlan(name?.plan));
  };

  return (
    <div>
      <VStack spacing={"0"} w="full">
        <Link
          bg="brand.100"
          _activeLink={{
            bg: mode("brand.800", "gray.700"),
          }}
          _hover={{
            bg: mode("gray.100", "gray.700"),
            color: mode("gray.900", "white"),
          }}
          pb="4"
          width={"inherit"}
          aria-current={isActive ? "page" : undefined}
          onClick={(e) => HandleCard(name, e)}
        >
          <Box
            h="2"
            w="full"
            bg="gray.200"
            _activeLink={{
              bg: mode("brand.600", "gray.700"),
            }}
            aria-current={isActive ? "page" : undefined}
          ></Box>
          <HStack justify={"space-between"} my="4">
            <Box>
              <Text
                ml="4"
                color="brand.800"
                _activeLink={{
                  color: mode("brand.50", "gray.700"),
                }}
                aria-current={isActive ? "page" : undefined}
                fontWeight={"semibold"}
              >
                {name.plan}
              </Text>
            </Box>{" "}
            <Box
              borderLeftRadius={"3"}
              boxShadow={"md"}
              h="10"
              w="20"
              bg="brand.100"
            >
              <HStack mx="1.5" spacing={"1"}>
                <Text
                  fontWeight={"semibold"}
                  pt="2.5"
                  fontSize="xs"
                  color={"brand.900"}
                >
                  GHS
                </Text>
                <Text
                  fontWeight={"semibold"}
                  pt="2.5"
                  fontSize="xs"
                  color={"brand.900"}
                >
                  {name.amount}
                </Text>
              </HStack>
            </Box>
          </HStack>{" "}
          <SimpleGrid mx="4" columns={col} columnGap={1} rowGap={1} w="full">
            {/* <GridItem colSpan={1}>
              {" "}
              <Text
                fontSize={"sm"}
                color={"brand.900"}
                text
                white
                _activeLink={{
                  color: mode("brand.50", "gray.700"),
                }}
                aria-current={isActive ? "page" : undefined}
              >
                <Icon name="check-circle" color="green.500" size={2} />{" "}
                {name?.available[0]}
              </Text>
            </GridItem>
            <GridItem colSpan={1}>
              {" "}
              <Text
                fontSize={"sm"}
                color={"brand.900"}
                text
                white
                _activeLink={{
                  color: mode("brand.50", "gray.700"),
                }}
              >
                <Icon name="check-circle" color="green.500" size={2} /> Parish
                {name?.available[1]}
              </Text>
            </GridItem>
            <GridItem colSpan={1}>
              {" "}
              <Text
                fontSize={"sm"}
                color={"brand.900"}
                text
                white
                _activeLink={{
                  color: mode("brand.50", "gray.700"),
                }}
              >
                <Icon name="check-circle" color="green.500" size={2} /> Daily
                {name?.available[2]}
              </Text>
            </GridItem>
            <GridItem colSpan={1}>
              {" "}
              <Text
                fontSize={"sm"}
                color={"brand.900"}
                text
                white
                _activeLink={{
                  color: mode("brand.50", "gray.700"),
                }}
              >
                <Icon name="check-circle" color="green.500" size={2} />{" "}
                {name?.available[3]}
              </Text>
            </GridItem>
            <GridItem colSpan={1}>
              {" "}
              <Text
                fontSize={"sm"}
                color={"brand.900"}
                text
                white
                _activeLink={{
                  color: mode("brand.50", "gray.700"),
                }}
              >
                <Icon name="check-circle" color="green.500" size={2} /> My
                {name?.available[4]}
              </Text>
            </GridItem> */}
            <FeaturesText featureName={name.features} isActive={isActive} />
          </SimpleGrid>
        </Link>
      </VStack>
    </div>
  );
};

export default SubscriptionCard;
