import React from "react";
import {
  Flex,
  Box,
  Text,
  SlideFade,
  HStack,
  Button,
  TabList,
  Tab,
  TabPanel,
  Tabs,
  TabPanels,
  Stack,
  Heading,
} from "@chakra-ui/react";
import { Redirect, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Icon, IconButton } from "@chakra-ui/react";
import { RiMenuFoldFill, RiMenuUnfoldFill } from "react-icons/ri";
import { useState, useEffect } from "react";
import { ContentInput } from "../components/myParish/contentInput";
import { SkeletonMessage } from "../components/common/skeleton";
import { SentMassConf } from "../components/myParish/sentMassConf";
import {
  getConfessionTime,
  getMassTime,
  removeErrorConfessionTime,
  removeErrorMassTime,
} from "../features/dataAll/dataBullMyPSlice";
import { AccordionParishInfo } from "../components/myParish/accordionParishInfo";

export function MyParish() {
  const userSubcription = useSelector(
    (state) => state.user.credentials.subscription
  );
  const [showPanel, setShowPanel] = useState(true);

  const { search } = useLocation();
  const match = search.match(/status=(.*)/);
  const status = match?.[1];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMassTime());
    dispatch(getConfessionTime());
  }, []);
  const isGetPending = useSelector((state) => state.dataBullMyP.statusGet);
  const base1 = () => {
    if (showPanel === true) {
      return "block";
    } else {
      return "none";
    }
  };

  const base2 = () => {
    if (showPanel === true) {
      return "none";
    } else {
      return "block";
    }
  };

  function rightSideOnclick() {
    setShowPanel(false);
  }

  function rightSideOffclick() {
    setShowPanel(true);
  }
  function TabMassTime() {
    dispatch(removeErrorConfessionTime());
  }
  function TabConfessionTime() {
    dispatch(removeErrorMassTime());
  }

  return (
    <div>
      {status === "myparish" && userSubcription?.myParish ? (
        <Flex>
          <Flex
            flex="1"
            bg="brand.20"
            h={{ base: "94vh", md: "90vh" }}
            display={{
              base: base1,
              md: "block",
            }}
          >
            <Box rounded="base" h="full" w="full">
              <Box py="2" bg="white" boxShadow={"sm"}>
                <HStack height={{ base: "16", md: "12" }}>
                  <Box display="flex" justifyContent={"center"} width="full">
                    <Heading size={"sm"} color="brand.800">
                      My Parish
                    </Heading>
                  </Box>

                  <IconButton
                    size="lg"
                    colorScheme="brand.100"
                    onClick={rightSideOnclick}
                    display={{ md: "none" }}
                  >
                    <Icon color="brand.900" as={RiMenuFoldFill}></Icon>
                  </IconButton>
                </HStack>
              </Box>

              <Box m="0.5" h="full" bg="white" overflowY="auto">
                <Box pl="2" pr="2" pt="4" marginBottom="20">
                  <Tabs isFitted variant="enclosed">
                    <TabList mb="1em">
                      <Tab
                        _selected={{ color: "white", bg: "brand.700" }}
                        _focus={{ borderColor: "white", boxShadow: "none" }}
                        onClick={TabMassTime}
                      >
                        Mass Time
                      </Tab>
                      <Tab
                        _selected={{ color: "white", bg: "brand.700" }}
                        _focus={{ borderColor: "white", boxShadow: "none" }}
                        onClick={TabConfessionTime}
                      >
                        Confession Time
                      </Tab>
                      <Tab
                        _selected={{ color: "white", bg: "brand.700" }}
                        _focus={{ borderColor: "white", boxShadow: "none" }}
                      >
                        Parish Information
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <ContentInput name="mass" />
                      </TabPanel>
                      <TabPanel>
                        <ContentInput name="confession" />
                      </TabPanel>
                      <TabPanel>
                        <AccordionParishInfo />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Box>
              </Box>
            </Box>
          </Flex>

          <Flex
            display={{
              base: base2,
              lg: "block",
            }}
            flex={{ base: "1", md: "none" }}
            // width={{ base: "100vh", md: "10vh", lg: "60vh" }}
            width="96"
            direction="column"
            overflowY="auto"
            borderLeftWidth="1px"
            pl={{ base: "10", md: "0" }}
            h="93vh"
          >
            {" "}
            <SlideFade
              offsetY="20px"
              SlideDirection="top"
              in={showPanel == true ? true : true}
            >
              <Box
                rounded="base"
                h={{ base: "88vh", md: "90vh" }}
                bg="white"
                shadow={{
                  base: "xl",
                  md: "none",
                }}
                borderRadius={{ base: "none", md: "5" }}
                borderLeftRadius={{ base: "3xl", md: "5" }}
              >
                <Box boxShadow={"sm"} py="2">
                  <HStack height={{ base: "16", md: "12" }}>
                    <Box display="flex" justifyContent={"center"} width="full">
                      <label>Published </label>
                    </Box>

                    <IconButton
                      colorScheme="brand.100"
                      onClick={rightSideOffclick}
                      display={{ md: "none" }}
                      size={"lg"}
                    >
                      <Icon color="brand.900" as={RiMenuUnfoldFill}></Icon>
                    </IconButton>
                  </HStack>
                </Box>
                <Box
                  overflowY="auto"
                  h={{ base: "78vh", md: "84vh", lg: "82vh" }}
                >
                  <Stack m={6}>
                    {isGetPending == "pending" ? (
                      <SkeletonMessage />
                    ) : (
                      <SentMassConf />
                    )}
                  </Stack>
                </Box>
              </Box>
            </SlideFade>
          </Flex>
        </Flex>
      ) : (
        <div>
          <Redirect to="/home"></Redirect>
        </div>
      )}
    </div>
  );
}
