import React from "react";
import {
  Box,
  Divider,
  Stack,
  HStack,
  Checkbox,
  Skeleton,
  SkeletonText,
} from "@chakra-ui/react";

export function SignupRequestSkeleton() {
  const skeletonNums = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div>
      {skeletonNums.map((skeletonNum) => (
        <div key={skeletonNum}>
          <Box lineHeight="1" _hover={{ bg: "brand.50" }} py="3">
            <HStack justifyContent="space-between" mx="6">
              <Checkbox></Checkbox>
              <Stack pl="4" flex="1">
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <SkeletonText noOfLines={1} />
              </Stack>
            </HStack>
          </Box>
          <Divider />
        </div>
      ))}
    </div>
  );
}

export function SkeletonMessage() {
  const skeletonNums = [1, 2, 3, 4, 5];
  return (
    <div>
      {skeletonNums.map((skeletonNum) => (
        <div key={skeletonNum}>
          <Stack p={4}>
            <Skeleton height="20px" />
            <Skeleton width={"60px"} height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Divider />
            <Skeleton height="20px" />
          </Stack>
        </div>
      ))}
    </div>
  );
}
