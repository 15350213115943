import jwtDecode from "jwt-decode";
import { store } from "../store";
import { logoutUser } from "../features/user/userSlice";
import moment from "moment";

export function notificationData(head, details, col, BiErrorCircle) {
  return {
    head: head,
    details: details,
    col: col,
    noticon: BiErrorCircle,
  };
}

export const textColorChange = (e, regex, vbrand, ebrand) => {
  if (regex.test(e)) {
    return vbrand;
  } else {
    return ebrand;
  }
};

export function IsSessionExpired() {
  const token = localStorage.FBIdToken;
  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      store.dispatch(logoutUser());
      window.location.href = "/login";
    }
  }
}

export function IsBackEndSessionExpired() {
  store.dispatch(logoutUser());
  window.location.href = "/login";
}

export function DateConvert(date) {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return new Date(date).toLocaleDateString("en-US", options);
}

export function StartEnd(date) {
  return new Date(date).toLocaleDateString("en-US", "mm/dd/yyyy");
}

export function OccassionDate(date) {
  return moment(date).format("YYYY/MM/DD");
}

export function ConvertStartEndDate(date) {
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return new Date(date).toLocaleDateString("en-US", options);
}
