import { React, useEffect } from "react";
import {
  Text,
  HStack,
  IconButton,
  Stack,
  Divider,
  Link,
  useColorModeValue as mode,
  Input,
  Button,
  Spinner,
  Textarea,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  Box,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteMessage,
  deleteParishEvent,
  isSelectedMessage,
  removeErrorParishEvent,
  updateMessage,
  updateParishEvent,
} from "../../features/dataAll/dataMegAnnEvtSlice";
import { DeleteIcon } from "@chakra-ui/icons";
import { useDisclosure } from "@chakra-ui/hooks";
import { useState } from "react";
import { useToast } from "@chakra-ui/toast";
import {
  ConvertStartEnd,
  ConvertStartEndDate,
  DateConvert,
  IsBackEndSessionExpired,
  notificationData,
} from "../../util/functions";
import { NotifyIndex } from "../notification/notifyIndex";
import { BiErrorCircle, BiCheckDouble } from "react-icons/bi";
import { useForm } from "react-hook-form";
import { logoutUser } from "../../features/user/userSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const SentParishEvent = () => {
  const [isDisableEvent, setIsDisableEvent] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const parishEvents = useSelector((state) => state.dataMegAnnEvt.parishEvents);
  const selected = useSelector((state) => state.dataMegAnnEvt.selectedMessage);
  const isUpdating = useSelector((state) => state.dataMegAnnEvt.statusUpdate);
  const isDeleting = useSelector((state) => state.dataMegAnnEvt.statusDelete);
  const isError = useSelector((state) => state.dataMegAnnEvt.errorParishEvent);
  const [selectedEvent, setSelectedEvent] = useState({
    eventTitle: "",
    eventDetails: "",
    startDate: "",
    endDate: "",
    eventId: "",
  });
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [quillValue, setQuillValue] = useState("");

  const dispatch = useDispatch();
  const toast = useToast();

  //handle click of posted parishEvents
  const postedClick = (event, e) => {
    const thisIsSelected = {
      eventTitle: event.eventTitle,
      eventDetails: event.eventDetails,
      startDate: event.startDate,
      endDate: event.endDate,
      eventId: event.eventId,
    };
    dispatch(isSelectedMessage(thisIsSelected));
    setSelectedEvent({
      ...selectedEvent,
      eventTitle: event.eventTitle,
      // eventDetails: event.eventDetails,
      // startDate: event.startDate,
      // endDate: event.endDate,
      eventId: event.eventId,
      // countMessageBody: message.messageBody.length,
    });
    setDateRange([new Date(event.startDate), new Date(event.endDate)]);
    setQuillValue(event.eventDetails);
    dispatch(removeErrorParishEvent());
    onOpen();
  };

  //count the characters and set its quantity to useState
  //check if characters is less than 250
  // const count = (e) => {
  //   let inputValue = e.target.value;
  //   setSelectedEvent({
  //     ...selectedEvent,
  //     countMessageBody: inputValue.length,
  //   });
  //   if (inputValue.length > 250) {
  //     setIsDisableEvent(true);
  //   } else {
  //     setIsDisableEvent(false);
  //   }
  // };
  function EditChangeTitle(event) {
    //e.preventDefault();
    setSelectedEvent({
      ...selectedEvent,
      eventTitle: event.target.value,
    });
  }

  // function EditChangeMessage(event) {
  //   //e.preventDefault();
  //   setSelectedEvent({
  //     ...selectedEvent,
  //     eventDetails: event.target.value,
  //   });
  // }

  function EditSend(e) {
    e.preventDefault();
    const theStartDate = startDate == null ? "" : startDate;
    const theEndDate = endDate == null ? "" : endDate;
    const theQuillValue = quillValue === "<p><br></p>" ? "" : quillValue;
    var rawQuillValue = quillValue
      .replace(/<[^>]+>/g, "")
      .replace(/\&nbsp;/g, "");
    const newEvent = {
      eventTitle: selectedEvent.eventTitle,
      eventDetails: theQuillValue,
      startDate: theStartDate,
      endDate: theEndDate,
      eventId: selectedEvent.eventId,
      rawEventDetails: rawQuillValue,
    };

    dispatch(updateParishEvent(newEvent))
      .unwrap()
      .then((originalPromiseResult) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Parish Event",
            "Update Complete",
            "green",
            BiCheckDouble
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
          onClose();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Parish Event",
            rejectedValueOrSerializedError,
            "red",
            BiErrorCircle
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
        } else if (
          rejectedValueOrSerializedError?.code == "auth/id-token-expired"
        ) {
          dispatch(logoutUser());
        }
      });
  }
  function CloseModel() {
    setIsDisableEvent(false);
    setConfirmDelete(false);
    dispatch(removeErrorParishEvent());
    onClose();
  }
  function IsDelete() {
    setConfirmDelete(true);
  }
  function YesDelete() {
    const newEvent = {
      eventId: selectedEvent.eventId,
    };
    dispatch(deleteParishEvent(newEvent))
      .unwrap()
      .then((originalPromiseResult) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Parish Event",
            "Delete Complete",
            "green",
            BiCheckDouble
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });

          setConfirmDelete(false);
          onClose();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Parish Event",
            rejectedValueOrSerializedError,
            "red",
            BiErrorCircle
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
        } else if (
          rejectedValueOrSerializedError?.code == "auth/id-token-expired"
        ) {
          dispatch(logoutUser());
        }
      });
  }

  return (
    <>
      {parishEvents.map((event) => (
        <Link
          key={event.eventId}
          _hover={{ bg: "brand.50" }}
          // bg={
          //   signupRequestData.requestId === request.requestId &&
          //   "brand.400"
          // }
          _active={{
            bg: mode("gray.200", "gray.700"),
            color: "inherit",
          }}
          bg="brand.100"
          borderRadius={5}
          onClick={(e) => postedClick(event, e)}
        >
          <Stack p={4}>
            <HStack>
              <Text fontWeight={"semibold"} fontSize="xs">
                Title:
              </Text>
              <Text isTruncated color={"brand.900"} fontSize="xs">
                {event.eventTitle}
              </Text>
            </HStack>
            <Text fontWeight={"semibold"} fontSize="xs">
              Details:
            </Text>
            <Text color={"brand.900"} fontSize="xs" noOfLines={3}>
              {event.eventDetails
                .replace(/<[^>]+>/g, "")
                .replace(/\&nbsp;/g, "")}
            </Text>
            <HStack>
              <Text fontWeight={"semibold"} fontSize="xs">
                Start Date:
              </Text>
              <Text isTruncated color={"brand.900"} fontSize="xs">
                {ConvertStartEndDate(event.startDate)}
              </Text>
            </HStack>
            <HStack>
              <Text fontWeight={"semibold"} fontSize="xs">
                End Date:
              </Text>
              <Text isTruncated color={"brand.900"} fontSize="xs">
                {ConvertStartEndDate(event.endDate)}
              </Text>
            </HStack>
            <Divider />
            <HStack justifyContent={"space-between"}>
              <Text color={"brand.900"} fontSize="xs">
                Crated on: {DateConvert(event.createdAt)}
              </Text>
              <IconButton
                variant={"ghost"}
                size={"sm"}
                aria-label="Delete"
                icon={<DeleteIcon />}
                onClick={IsDelete}
              />
            </HStack>
          </Stack>
        </Link>
      ))}
      {/* {modal} */}
      <Modal isOpen={isOpen} onClose={CloseModel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {" "}
            {confirmDelete == true ? "Confirm Delete" : "Eidt Event"}{" "}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {confirmDelete == true ? (
              <div>
                <Stack spacing={"4"} justifyContent={"center"}>
                  <Text py="4">Are you sure you want to Delete this Event</Text>
                  <HStack>
                    <Button
                      variant={"outline"}
                      color="brand.900"
                      mr={3}
                      width={"full"}
                      onClick={CloseModel}
                      borderColor={"brand.900"}
                    >
                      NO
                    </Button>
                    <Button
                      variant={"outline"}
                      color="brand.900"
                      mr={3}
                      width={"full"}
                      onClick={YesDelete}
                    >
                      {isDeleting == "pending" ? <Spinner size="sm" /> : "YES"}
                    </Button>
                  </HStack>
                </Stack>
              </div>
            ) : (
              <div>
                <Text as="sub" color={"red.500"}>
                  {isError?.eventTitle}
                </Text>
                <Input
                  mb="4"
                  size="lg"
                  name="title"
                  type="text"
                  required
                  bg={mode("white", "gray.700")}
                  fontSize="md"
                  defaultValue={selected.eventTitle}
                  onChange={EditChangeTitle}
                ></Input>
                <Stack mb={"6"}>
                  {
                    <Text as="sub" color={"red.500"}>
                      {isError?.endDate}
                    </Text>
                  }
                  <Box
                    borderColor={"gray.300"}
                    borderWidth={"1px"}
                    maxWidth={"fit-content"}
                  >
                    <DatePicker
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="Start and End Date"
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      isClearable={true}
                    />
                  </Box>
                </Stack>
                <Text as="sub" color={"red.500"}>
                  {isError?.eventDetails}
                </Text>
                <ReactQuill
                  modules={Event.modules}
                  formats={Event.formats}
                  onChange={setQuillValue}
                  theme="snow"
                  value={quillValue}
                  placeholder="Parish Event Details"
                />
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            {confirmDelete == false && (
              <HStack width={"full"}>
                {" "}
                <Button
                  variant={"outline"}
                  color="brand.900"
                  mr={3}
                  onClick={CloseModel}
                >
                  Close
                </Button>
                {/* <Button
                  variant={"outline"}
                  color="brand.900"
                  mr={3}
                  onClick={IsDelete}
                >
                  Delete
                </Button> */}
                <Button
                  colorScheme="brand.700"
                  bg="brand.700"
                  isDisabled={isDisableEvent}
                  onClick={EditSend}
                  w="full"
                >
                  {isUpdating == "pending" ? (
                    <Spinner size="sm" />
                  ) : (
                    "Save Edit"
                  )}
                </Button>
              </HStack>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

// (
//   parishEvents.map((message) => (
//     <Link
//       key={message.messageId}
//       _hover={{ bg: "brand.50" }}
//       // bg={
//       //   signupRequestData.requestId === request.requestId &&
//       //   "brand.400"
//       // }
//       _active={{
//         bg: mode("gray.200", "gray.700"),
//         color: "inherit",
//       }}
//       bg="brand.100"
//       borderRadius={5}
//       onClick={(e) => postedClick(message, e)}
//     >
//       <Stack p={4}>
//         <HStack>
//           <Text fontWeight={"semibold"} fontSize="xs">
//             Title:
//           </Text>
//           <Text
//             isTruncated
//             color={"brand.900"}
//             fontSize="xs"
//           >
//             {message.messageTitle}
//           </Text>
//         </HStack>
//         <Text fontWeight={"semibold"} fontSize="xs">
//           Details:
//         </Text>
//         <Text
//           color={"brand.900"}
//           fontSize="xs"
//           noOfLines={3}
//         >
//           {message.messageBody}
//         </Text>
//         <Divider />
//         <HStack justifyContent={"space-between"}>
//           <Text color={"brand.900"} fontSize="xs">
//             Crated on: {message.createdAt}
//           </Text>
//           <IconButton
//             variant={"ghost"}
//             size={"sm"}
//             aria-label="Delete"
//             icon={<DeleteIcon />}
//           />
//         </HStack>
//       </Stack>
//     </Link>
//   ))
// )
