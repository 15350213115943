import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { StartEnd } from "../../util/functions";

//send message
export const sendMessage = createAsyncThunk(
  "dataMegAnnEvt/message",
  async (message, { rejectWithValue }) => {
    try {
      const res = await axios.post("/message", message);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//send ParishEvent
export const sendParishEvent = createAsyncThunk(
  "dataMegAnnEvt/parishEvent",
  async (event, { rejectWithValue }) => {
    try {
      const res = await axios.post("/myparish/parishevent", event);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//send Announcement
export const sendAnnouncement = createAsyncThunk(
  "dataMegAnnEvt/announcement",
  async (announcement, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        "/announcement/postannouncement",
        announcement
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//get messages
export const getMessage = createAsyncThunk(
  "dataMegAnnEvt/getMessages",
  async (id = null, { rejectWithValue }) => {
    try {
      const res = await axios.get("/messages");
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//get parish Events
export const getParishEvent = createAsyncThunk(
  "dataMegAnnEvt/getParishEvent",
  async (id = null, { rejectWithValue }) => {
    try {
      const res = await axios.get("/allEvent");
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//get Announcements
export const getAnnouncement = createAsyncThunk(
  "dataMegAnnEvt/getAnnouncement",
  async (id = null, { rejectWithValue }) => {
    try {
      const res = await axios.get("/announcement/getannouncement");
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//get Occassion
//`http://calapi.inadiutorium.cz/api/v0/en/calendars/default/${date}`
export const getOccassion = createAsyncThunk(
  "dataMegAnnEvt/getOccassion",
  async (thedate, { rejectWithValue }) => {
    try {
      const res = await axios.post("/getOccassion", thedate);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

///generateOccassion
export const generateOccassion = createAsyncThunk(
  "dataMegAnnEvt/generateOccassion",
  async (nextSunday, { rejectWithValue }) => {
    try {
      // var now = date.format(new Date(), "YYYY/MM/DD");
      var resData = [];

      // for (var i = 0; i <= 98; i += 7) {
      //   var nextSunday = date.addDays(now, i);
      //   console.log(nextSunday);
      axios
        .get(
          `http://calapi.inadiutorium.cz/api/v0/en/calendars/default/${nextSunday}`
        )
        .then((response) => {
          resData = response.data.celebrations;
          const theTitle = resData.map((occassion) => occassion.title);
          const theColour = resData.map((occassion) => occassion.colour);
          console.log(theTitle.toString());
          console.log(theColour.toString());
          console.log(nextSunday);

          const setData = {
            sundayDate: nextSunday,
            title: theTitle.toString(),
            colour: theColour.toString(),
          };
          return axios.post("/generateOccassion", setData);
        })
        .catch((error) => {
          return rejectWithValue(error.response.data);
        });
      //  }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//update Message
export const updateMessage = createAsyncThunk(
  "dataMegAnnEvt/updateMessage",
  async (message, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `/message/${message.messageId}/updateMessage`,
        message
      );
      return message;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//update Parish Event
export const updateParishEvent = createAsyncThunk(
  "dataMegAnnEvt/updateParishEvent",
  async (event, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `/myparish/parishevent/${event.eventId}/updateEvent`,
        event
      );
      const theEvent = {
        eventTitle: event.eventTitle,
        eventDetails: event.eventDetails,
        startDate: StartEnd(event.startDate),
        endDate: StartEnd(event.endDate),
        eventId: event.eventId,
      };
      return theEvent;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//update Announcement
export const updateAnnouncement = createAsyncThunk(
  "dataMegAnnEvt/updateAnnouncement",
  async (announcement, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `/announcement/${announcement.announcementId}/updateannouncement`,
        announcement
      );
      const theAnnouncement = {
        announcementId: announcement.announcementId,
        sundayDate: StartEnd(announcement.sundayDate),
        announcementDetails: announcement.announcementDetails,
      };
      return theAnnouncement;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//delete Message
export const deleteMessage = createAsyncThunk(
  "dataMegAnnEvt/deleteMessage",
  async (message, { rejectWithValue }) => {
    try {
      const res = await axios.delete(
        `/message/${message.messageId}/deleteMessage`
      );
      return message;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//delete Parish Event
export const deleteParishEvent = createAsyncThunk(
  "dataMegAnnEvt/deleteParishEvent",
  async (event, { rejectWithValue }) => {
    try {
      const res = await axios.delete(
        `/myparish/parishevent/${event.eventId}/deleteEvent`
      );

      return event;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//delete Announcement
export const deleteAnnouncement = createAsyncThunk(
  "dataMegAnnEvt/deleteAnnouncement",
  async (announcement, { rejectWithValue }) => {
    try {
      const res = await axios.delete(
        `/announcement/${announcement.announcementId}/deleteannouncement`
      );

      return announcement;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  statusMessage: null,
  statusParishEvent: null,
  statusAnnouncement: null,
  statusGet: null,
  statusUpdate: null,
  statusDelete: null,
  statusGetOccassion: null,
  messages: [],
  parishEvents: [],
  announcements: [],
  errorParishEvent: null,
  errorMessage: null,
  errorAnnouncement: null,
  errorOccassion: null,
  selectedMessage: {},
  occassion: null,
};

export const dataMegAnnEvtSlice = createSlice({
  name: "dataMegAnnEvt",
  initialState,
  reducers: {
    isSelectedMessage: (state, action) => {
      state.selectedMessage = action.payload;
    },
    removeErrorParishEvent: (state, action) => {
      state.errorParishEvent = null;
    },
    removeErrorMessage: (state, action) => {
      state.errorMessage = null;
    },
    removeErrorAnnouncement: (state, action) => {
      state.errorAnnouncement = null;
    },
    isErrorAnnouncement: (state, action) => {
      state.errorAnnouncement = action.payload;
    },
    removeOccassion: (state, action) => {
      state.occassion = null;
    },
  },
  extraReducers: {
    //send message
    [sendMessage.pending]: (state, action) => {
      state.statusMessage = "pending";
    },
    [sendMessage.fulfilled]: (state, action) => {
      state.statusMessage = "success";

      if (state.messages.length >= 5) {
        state.messages.pop();
        state.messages.unshift(action.payload);
      } else {
        state.messages.unshift(action.payload);
      }
    },
    [sendMessage.rejected]: (state, action) => {
      state.statusMessage = "rejected";
      state.errorMessage = action.payload;
    },

    //send parishEvent
    [sendParishEvent.pending]: (state, action) => {
      state.statusParishEvent = "pending";
    },
    [sendParishEvent.fulfilled]: (state, action) => {
      state.statusParishEvent = "success";

      if (state.parishEvents.length >= 5) {
        state.parishEvents.pop();
        state.parishEvents.unshift(action.payload);
      } else {
        state.parishEvents.unshift(action.payload);
      }
    },
    [sendParishEvent.rejected]: (state, action) => {
      state.statusParishEvent = "rejected";
      state.errorParishEvent = action.payload;
    },

    //send Announcement
    [sendAnnouncement.pending]: (state, action) => {
      state.statusAnnouncement = "pending";
    },
    [sendAnnouncement.fulfilled]: (state, action) => {
      state.statusAnnouncement = "success";

      if (state.announcements.length >= 5) {
        state.announcements.pop();
        state.announcements.unshift(action.payload);
      } else {
        state.announcements.unshift(action.payload);
      }
    },
    [sendAnnouncement.rejected]: (state, action) => {
      state.statusAnnouncement = "rejected";
      state.errorAnnouncement = action.payload;
    },

    //get messages
    [getMessage.pending]: (state, action) => {
      state.statusGet = "pending";
    },
    [getMessage.fulfilled]: (state, action) => {
      state.statusGet = "success";
      state.messages = action.payload;
    },
    [getMessage.rejected]: (state, action) => {
      state.statusGet = "rejected";
      state.errorMessage = action.payload;
    },

    //get events
    [getParishEvent.pending]: (state, action) => {
      state.statusGet = "pending";
    },
    [getParishEvent.fulfilled]: (state, action) => {
      state.statusGet = "success";
      state.parishEvents = action.payload;
    },
    [getParishEvent.rejected]: (state, action) => {
      state.statusGet = "rejected";
      state.errorParishEvent = action.payload;
    },

    //get announcement
    [getAnnouncement.pending]: (state, action) => {
      state.statusGet = "pending";
    },
    [getAnnouncement.fulfilled]: (state, action) => {
      state.statusGet = "success";
      state.announcements = action.payload;
    },
    [getAnnouncement.rejected]: (state, action) => {
      state.statusGet = "rejected";
      state.errorAnnouncement = action.payload;
    },

    //get occassionn
    [getOccassion.pending]: (state, action) => {
      state.statusGetOccassion = "pending";
    },
    [getOccassion.fulfilled]: (state, action) => {
      state.statusGetOccassion = "success";
      state.occassion = action.payload;
    },
    [getOccassion.rejected]: (state, action) => {
      state.statusGetOccassion = "rejected";
      state.errorOccassion = action.payload;
    },

    //generate occassionn
    [generateOccassion.pending]: (state, action) => {
      state.statusGetOccassion = "pending";
    },
    [generateOccassion.fulfilled]: (state, action) => {
      state.statusGetOccassion = "success";
      // state.occassion = action.payload?.celebrations;
    },
    [generateOccassion.rejected]: (state, action) => {
      state.statusGetOccassion = "rejected";
      state.errorOccassion = action.payload;
    },

    //update messages
    [updateMessage.pending]: (state, action) => {
      state.statusUpdate = "pending";
    },
    [updateMessage.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      let new_array = state.messages.map((mes) =>
        mes.messageId == action.payload.messageId
          ? {
              ...mes,
              messageTitle: action.payload.messageTitle,
              messageBody: action.payload.messageBody,
            }
          : mes
      );
      state.messages = new_array;
    },
    [updateMessage.rejected]: (state, action) => {
      state.statusUpdate = "rejected";
      state.errorMessage = action.payload;
    },

    //update Parish Event
    [updateParishEvent.pending]: (state, action) => {
      state.statusUpdate = "pending";
    },
    [updateParishEvent.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      let new_array = state.parishEvents.map((pevt) =>
        pevt.eventId == action.payload.eventId
          ? {
              ...pevt,
              eventTitle: action.payload.eventTitle,
              startDate: action.payload.startDate,
              endDate: action.payload.endDate,
              eventDetails: action.payload.eventDetails,
            }
          : pevt
      );
      state.parishEvents = new_array;
    },
    [updateParishEvent.rejected]: (state, action) => {
      state.statusUpdate = "rejected";
      state.errorParishEvent = action.payload;
    },

    //update Announcement
    [updateAnnouncement.pending]: (state, action) => {
      state.statusUpdate = "pending";
    },
    [updateAnnouncement.fulfilled]: (state, action) => {
      state.statusUpdate = "success";
      let new_array = state.announcements.map((ann) =>
        ann.announcementId == action.payload.announcementId
          ? {
              ...ann,
              sundayDate: action.payload.sundayDate,
              announcementDetails: action.payload.announcementDetails,
            }
          : ann
      );
      state.announcements = new_array;
    },
    [updateAnnouncement.rejected]: (state, action) => {
      state.statusUpdate = "rejected";
      state.errorAnnouncement = action.payload;
    },

    //delete messages
    [deleteMessage.pending]: (state, action) => {
      state.statusDelete = "pending";
    },
    [deleteMessage.fulfilled]: (state, action) => {
      state.statusDelete = "success";
      let new_array = state.messages.filter(
        (me) => me.messageId !== action.payload.messageId
      );
      state.messages = new_array;
    },
    [deleteMessage.rejected]: (state, action) => {
      state.statusDelete = "rejected";
      state.errorMessage = action.payload;
    },

    //delete Parish Event
    [deleteParishEvent.pending]: (state, action) => {
      state.statusDelete = "pending";
    },
    [deleteParishEvent.fulfilled]: (state, action) => {
      state.statusDelete = "success";
      let new_array = state.parishEvents.filter(
        (pevent) => pevent.eventId !== action.payload.eventId
      );
      state.parishEvents = new_array;
    },
    [deleteParishEvent.rejected]: (state, action) => {
      state.statusDelete = "rejected";
      state.errorParishEvent = action.payload;
    },

    //delete Announcement
    [deleteAnnouncement.pending]: (state, action) => {
      state.statusDelete = "pending";
    },
    [deleteAnnouncement.fulfilled]: (state, action) => {
      state.statusDelete = "success";
      let new_array = state.announcements.filter(
        (ann) => ann.announcementId !== action.payload.announcementId
      );
      state.announcements = new_array;
    },
    [deleteAnnouncement.rejected]: (state, action) => {
      state.statusDelete = "rejected";
      state.errorAnnouncement = action.payload;
    },
  },
});
export const {
  isSelectedMessage,
  removeErrorParishEvent,
  removeErrorMessage,
  removeErrorAnnouncement,
  isErrorAnnouncement,
  removeOccassion,
} = dataMegAnnEvtSlice.actions;
export default dataMegAnnEvtSlice.reducer;
