import { Box, Button, Flex, Heading, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import { DurationSwitch } from '../components/chooseSubcription/DurationSwitch'
import { PricingCard } from '../components/chooseSubcription/PricingCard'

export const ChooseSubcription = () => (
  <Box as="section" bg={useColorModeValue('brand.50', 'gray.800')} py="10">
    <Box
      maxW={{
        base: 'xl',
        md: '7xl',
      }}
      mx="auto"
      px={{
        base: '6',
        md: '8',
      }}
    >
      <Box
        textAlign={{
          base: 'center',
          lg: 'start',
        }}
        px = {{base:"none",lg:"40"}}
      >
        <Heading
          size="xl"
          lineHeight="normal"
          color={useColorModeValue('brand.700', 'blue.400')}
          letterSpacing="tight"
          fontWeight="bold"
        >
          Choose a subcription plan <p>for your Parish</p>
        </Heading>
        <Text  size="2xl"
          lineHeight="normal"
          color={useColorModeValue('brand.700', 'blue.400')}
          letterSpacing="tight"
          fontWeight="normal">Note, you can change anytime</Text>
       
      </Box>
      <Flex
        align="center"
        justify={{
          base: 'center',
          lg: 'flex-start',
        }}
        my="10"
      >
        {/* <DurationSwitch defaultValue="yearly" /> */}
      </Flex>
      <SimpleGrid
        mt="10"
        px = {{base:"5",md:"10",lg:"40"}}
        columns={{
          base: 1,
          md: 2,
        }}
        spacing={{base:"10",md:"20",lg:"40"}}
      >
        <PricingCard
         bg="gray.700"
        // bg="brand.400"
          color="white"
          button={
            <Button w="full" size="lg" h="16" colorScheme="teal">
              Start for Free
            </Button>
          }
          data={{
            name: 'Free',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            price: 0,
            duration: 'monthly',
            benefits: [
              'No credit card needed',
              'Full access to all features',
              'Demo components',
              'Free Figma sources',
              'Support',
            ],
          }}
        />
        <PricingCard
      bg="gray.700"
         // bg="brand.500"
          color="white"
          button={
            <Button w="full" h="16" colorScheme="teal" size="lg">
              Select
            </Button>
          }
          data={{
            name: 'Freelancer',
            description: 'At erat pellentesque adipiscing commodo elit at imperdiet.',
            price: 29,
            duration: 'monthly',
            benefits: [
              'No credit card needed',
              'Full access to all features',
              'Demo components',
              'Free Figma sources',
              'Support',
            ],
          }}
        />
        {/* <PricingCard
          bg="blue.600"
          color="white"
          button={
            <Button w="full" color="revert" h="16" size="lg">
              Add to cart
            </Button>
          }
          data={{
            name: 'Agency',
            description: 'Proin sagittis nisl rhoncus mattis rhoncus urna neque.',
            price: 39,
            duration: 'monthly',
            benefits: [
              'No credit card needed',
              'Full access to all features',
              'Demo components',
              'Free Figma sources',
              'Support',
            ],
          }}
        /> */}
      </SimpleGrid>
    </Box>
  </Box>
)