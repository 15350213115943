import React from "react";
import {
  Flex,
  Box,
  Text,
  Icon,
  SlideFade,
  HStack,
  IconButton,
  Stack,
  Divider,
  Link,
  useColorModeValue as mode,
  Input,
  FormControl,
  Button,
  Spinner,
  Textarea,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { Redirect, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { RiMenuFoldFill, RiMenuUnfoldFill } from "react-icons/ri";
import { DeleteIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import {
  getMessage,
  sendMessage,
} from "../features/dataAll/dataMegAnnEvtSlice";
import { useToast } from "@chakra-ui/toast";
import { IsBackEndSessionExpired, notificationData } from "../util/functions";
import { NotifyIndex } from "../components/notification/notifyIndex";
import { BiErrorCircle, BiCheckDouble } from "react-icons/bi";
import { logoutUser } from "../features/user/userSlice";
import { SkeletonMessage } from "../components/common/skeleton";
import { SentMessages } from "../components/message/sentMessages";

export function Message() {
  const [showPanel, setShowPanel] = useState(true);
  const [countValue, setCountValue] = useState("");
  const [isDisableMessage, setIsDisableMessage] = useState(false);

  const userSubcription = useSelector(
    (state) => state.user.credentials.subscription
  );
  const isformSubmitting = useSelector(
    (state) => state.dataMegAnnEvt.statusMessage
  );
  const isGetMessagesPending = useSelector(
    (state) => state.dataMegAnnEvt.statusGet
  );
  const messages = useSelector((state) => state.dataMegAnnEvt.messages);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMessage());
  }, []);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();
  const toast = useToast();
  const { search } = useLocation();
  const match = search.match(/status=(.*)/);
  const status = match?.[1];

  const onSubmit = (data, e) => {
    // add submit logic here
    e.preventDefault();
    const newMessage = {
      messageTitle: data.title,
      messageBody: data.message,
    };
    dispatch(sendMessage(newMessage))
      .unwrap()
      .then((originalPromiseResult) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Parish Message",
            "Sent",
            "green",
            BiCheckDouble
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
          reset();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Parish Message",
            rejectedValueOrSerializedError,
            "red",
            BiErrorCircle
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
        } else if (
          rejectedValueOrSerializedError?.code == "auth/id-token-expired"
        ) {
          dispatch(logoutUser());
          //IsBackEndSessionExpired();
        }
      });
  };
  //count the characters and set its quantity to useState
  //check if characters is less than 250
  const count = (e) => {
    let inputValue = e.target.value;
    setCountValue(inputValue.length);
    if (inputValue.length > 250) {
      setIsDisableMessage(true);
    } else {
      setIsDisableMessage(false);
    }
  };

  const base1 = () => {
    if (showPanel === true) {
      return "block";
    } else {
      return "none";
    }
  };

  const base2 = () => {
    if (showPanel === true) {
      return "none";
    } else {
      return "block";
    }
  };

  function rightSideOnclick() {
    setShowPanel(false);
  }

  function rightSideOffclick() {
    setShowPanel(true);
  }
  return (
    <div>
      {status === "message" && userSubcription?.message ? (
        <Flex>
          <Flex
            flex="1"
            bg="brand.20"
            h={{ base: "94vh", md: "90vh" }}
            display={{
              base: base1,
              md: "block",
            }}
          >
            <Box rounded="base" h="full" w="full">
              <Box py="2" bg="white" boxShadow={"sm"}>
                <HStack height={{ base: "16", md: "12" }}>
                  <Box display="flex" justifyContent={"center"} width="full">
                    <Heading size={"sm"} color="brand.800">
                      Send Message to Parishioners
                    </Heading>
                  </Box>

                  <IconButton
                    size="lg"
                    colorScheme="brand.100"
                    onClick={rightSideOnclick}
                    display={{ md: "none" }}
                  >
                    <Icon color="brand.900" as={RiMenuFoldFill}></Icon>
                  </IconButton>
                </HStack>
              </Box>
              <Box m="0.5" h="full" bg="white">
                <Box p="10">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack>
                      <FormControl id="title">
                        <Input
                          mb="4"
                          size="lg"
                          name="title"
                          type="text"
                          required
                          placeholder="Message Title"
                          bg={mode("white", "gray.700")}
                          fontSize="md"
                          {...register("title")}
                        />
                        {/* {status.status ==='rejected' && <NotifyIndex/> } */}
                      </FormControl>
                      <FormControl>
                        <Textarea
                          onKeyUp={(e) => count(e)}
                          type="text"
                          name="message"
                          required
                          placeholder="Enter your Message"
                          size="sm"
                          {...register("message")}
                        />{" "}
                        <Text as="sub">max character 250/{countValue}</Text>
                      </FormControl>
                    </Stack>
                    <Button
                      size="lg"
                      type="submit"
                      mt="8"
                      w="full"
                      bg="brand.700"
                      colorScheme="white"
                      isDisabled={isDisableMessage}
                      fontSize="md"
                      fontWeight="bold"
                    >
                      {isformSubmitting === "pending" ? (
                        <Spinner size="xs" />
                      ) : (
                        "SEND "
                      )}
                    </Button>
                  </form>
                </Box>
              </Box>
            </Box>
          </Flex>

          <Flex
            display={{
              base: base2,
              lg: "block",
            }}
            flex={{ base: "1", md: "none" }}
            // width={{ base: "100vh", md: "10vh", lg: "60vh" }}
            width="96"
            direction="column"
            borderLeftWidth="1px"
            pl={{ base: "10", md: "0" }}
            h="93vh"
          >
            {" "}
            <SlideFade
              offsetY="20px"
              SlideDirection="top"
              in={showPanel == true ? true : true}
            >
              <Box
                rounded="base"
                h={{ base: "88vh", md: "92vh", lg: "92vh" }}
                bg="white"
                shadow={{
                  base: "xl",
                  md: "none",
                }}
                borderRadius={{ base: "none", md: "5" }}
                borderLeftRadius={{ base: "3xl", md: "5" }}
              >
                <Box boxShadow={"sm"} py="2">
                  <HStack height={{ base: "16", md: "12" }}>
                    <Box display="flex" justifyContent={"center"} width="full">
                      <HStack spacing={1}>
                        <Heading size={"sm"} color="brand.800">
                          Sent Messages
                        </Heading>
                        <Text as="i" fontSize="8">
                          {"(first 5)"}
                        </Text>
                      </HStack>
                    </Box>

                    <IconButton
                      colorScheme="brand.100"
                      onClick={rightSideOffclick}
                      display={{ md: "none" }}
                      size={"lg"}
                    >
                      <Icon color="brand.900" as={RiMenuUnfoldFill}></Icon>
                    </IconButton>
                  </HStack>
                </Box>
                <Box
                  overflowY="auto"
                  h={{ base: "74vh", md: "84vh", lg: "82vh" }}
                >
                  <Stack m={6}>
                    {isGetMessagesPending == "pending" ? (
                      <SkeletonMessage />
                    ) : (
                      <SentMessages />
                    )}
                  </Stack>
                </Box>
              </Box>
            </SlideFade>
          </Flex>
        </Flex>
      ) : (
        <div>
          <Redirect to="/home"></Redirect>
        </div>
      )}
    </div>
  );
}
