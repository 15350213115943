import { Box, HStack } from "@chakra-ui/react";
import * as React from "react";

import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../features/user/userSlice";
import { useHistory } from "react-router";
import { unwrap } from "@reduxjs/toolkit";
import { IsBackEndSessionExpired } from "../util/functions";

const DesktopNavMenuItem = (props) => {
  const dispatch = useDispatch();
  const hostory = useHistory();

  const onclick = (e) => {
    e.preventDefault();
    IsBackEndSessionExpired();
  };
  const { icon, label, href = "#", active } = props;
  return (
    <HStack
      as="a"
      href={href}
      aria-current={active ? "page" : undefined}
      spacing="2"
      px="3"
      py="2"
      rounded="md"
      transition="all 0.2s"
      color="gray.900"
      _hover={{
        bg: "brand.600",
        color: "white",
      }}
      onClick={onclick}
    >
      {icon && (
        <Box aria-hidden fontSize="md">
          {icon}
        </Box>
      )}
      <Box fontWeight="semibold">{label}</Box>
    </HStack>
  );
};

// const MobileNavMenuItem = (props) => {
//   const { label, href = '#', active } = props
//   return (
//     <Box
//       as="a"
//       display="block"
//       href={href}
//       px="3"
//       py="3"
//       rounded="md"
//       fontWeight="semibold"
//       aria-current={active ? 'page' : undefined}
//       _hover={{
//         bg: 'whiteAlpha.200',
//       }}
//       _activeLink={{
//         bg: 'blackAlpha.300',
//         color: 'white',
//       }}
//     >
//       {label}
//     </Box>
//   )
// }

export const NavMenuItem = {
  Desktop: DesktopNavMenuItem,
  // Mobile: MobileNavItem,
};

// _activeLink={{
//   bg: 'blackAlpha.300',
//   color: 'white',
// }}
