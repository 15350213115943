import React from "react";
import {
  Flex,
  Box,
  HStack,
  Text,
  Input,
  SimpleGrid,
  GridItem,
  Divider,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import ModelGenApproveReject from "./modelGenApproveReject";

function SignupRequestDetails() {
  const signupRequestData = useSelector(
    (state) => state.ui.readSignupRequestData
  );

  return (
    <Flex direction="column">
      <Flex
        display={{ base: "block", md: "block" }}
        bg="white"
        my="4"
        mx="4"
        borderRadius="5"
      >
        <HStack justifyContent="space-between" px="6" py="2">
          <Text fontSize="xs" color="brand.900">
            Parish Details
          </Text>
          <Text fontSize="xs">Created at: {signupRequestData.createdAt}</Text>
        </HStack>
        <Divider bg="white" />
        <Flex
          pb="2"
          justifyContent="space-around"
          w="full"
          h="max-content"
          direction={{ base: "column", md: "row" }}
          overflow={{ base: "auto", md: "hidden" }}
        >
          <Box
            mx="2"
            my="2"
            w="max"
            p="4"
            borderColor="white"
            borderRadius="5"
            // w="full"
          >
            <SimpleGrid columns={2} columnGap={3} rowGap={2} w="full">
              <GridItem colSpan={1}>
                <Text fontSize="sm" color="brand.900">
                  Plan:
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Input
                  size="sm"
                  variant="outline"
                  isReadOnly={true}
                  value={signupRequestData.plan}
                ></Input>
              </GridItem>
              <GridItem colSpan={1}>
                <Text fontSize="sm" color="brand.900">
                  Name:
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Input
                  size="sm"
                  variant="outline"
                  isReadOnly={true}
                  value={signupRequestData.parishName}
                ></Input>
              </GridItem>
              <GridItem colSpan={1}>
                <Text fontSize="sm" color="brand.900">
                  Email:
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Input
                  size="sm"
                  variant="outline"
                  isReadOnly={true}
                  value={signupRequestData.parishEmail}
                ></Input>
              </GridItem>
              <GridItem colSpan={1}>
                <Text fontSize="sm" color="brand.900">
                  Address:
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Input
                  size="sm"
                  variant="outline"
                  isReadOnly={true}
                  value={signupRequestData.parishAddress}
                ></Input>
              </GridItem>
            </SimpleGrid>
          </Box>
          <Box
            mx="2"
            my="2"
            //w="max"
            p="4"
            borderColor="white"
            borderRadius="5"
            w="full"
          >
            <SimpleGrid columns={2} columnGap={3} rowGap={2} w="full">
              <GridItem colSpan={1}>
                <Text fontSize="sm" color="brand.900">
                  City/Town:
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Input
                  size="sm"
                  variant="outline"
                  isReadOnly={true}
                  value={signupRequestData.parishCityTown}
                ></Input>
              </GridItem>
              <GridItem colSpan={1}>
                <Text fontSize="sm" color="brand.900">
                  Country:
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Input
                  size="sm"
                  variant="outline"
                  isReadOnly={true}
                  value={signupRequestData.country}
                ></Input>
              </GridItem>
              <GridItem colSpan={1}>
                <Text fontSize="sm" color="brand.900">
                  Priest Name:
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Input
                  size="sm"
                  variant="outline"
                  isReadOnly={true}
                  value={signupRequestData.parishPriestName}
                ></Input>
              </GridItem>
              <GridItem colSpan={1}>
                <Text fontSize="sm" color="brand.900">
                  Priest Number:
                </Text>
              </GridItem>
              <GridItem colSpan={1}>
                <Input
                  size="sm"
                  variant="outline"
                  isReadOnly={true}
                  value={signupRequestData.priestPhoneNumber}
                ></Input>
              </GridItem>
            </SimpleGrid>
          </Box>
        </Flex>

        <Flex display={{ base: "block", md: "block" }} my="1" mx="4" pb="2">
          <HStack px="4" py="2">
            <Box mt="2" color="GrayText" fontSize="xs">
              Action
            </Box>
            <Box></Box>
          </HStack>
          <Divider bg="white" />

          <Flex display={{ base: "block", md: "block" }} px="4">
            <ModelGenApproveReject />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignupRequestDetails;
