import React from "react";
import {
  Text,
  Input,
  Stack,
  FormControl,
  HStack,
  Box,
  Button,
  Flex,
} from "@chakra-ui/react";
import { GoPrimitiveDot } from "react-icons/go";
import { useForm } from "react-hook-form";
import { textColorChange } from "../../util/functions";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { BiErrorCircle, BiCheckDouble } from "react-icons/bi";
import { NotifyIndex } from "../notification/notifyIndex";
import { notificationData } from "../../util/functions";
import { changePassword, logoutUser } from "../../features/user/userSlice";

export const ChangePasswordForm = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  //   const history = useHistory();
  const status = useSelector((state) => state.user.status);
  const credentials = useSelector(
    (state) => state.user.credentials.credentials
  );
  const { register, handleSubmit } = useForm();
  const [state, setState] = useState("");
  const [inputvaue, setInputvaue] = useState("");

  //regular expression
  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  const capital = new RegExp(/[A-Z]/);
  const small = new RegExp(/[a-z]/);
  const num = new RegExp(/[0-9]/);
  const special = new RegExp(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
  const max = new RegExp(/^.{8,16}$/);

  //check if strongRegex is met
  //set the input value to state to be use to check for the other exps.
  //if strongRegex is true setState to vbrand
  //else setState to ebrand
  const onChange = (e, vbrand, ebrand, regex) => {
    setInputvaue(e.currentTarget.value);
    if (regex.test(e.currentTarget.value)) {
      setState(vbrand);
    } else {
      setState(ebrand);
    }
  };

  //Submit the password reset
  const onSubmit = (data, e) => {
    e.preventDefault();
    const password = {
      password: data.currentPassword,
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
      parishUserId: credentials.parishUserId,
    };

    if (data.newPassword === data.confirmPassword) {
      dispatch(changePassword(password))
        .unwrap()
        .then((originalPromiseResult) => {
          if (typeof originalPromiseResult != "object") {
            const notificationErrorData = notificationData(
              "Change Password",
              originalPromiseResult,
              "green",
              BiCheckDouble
            );
            toast({
              position: "bottom-left",
              render: () => (
                <div>
                  {" "}
                  <NotifyIndex notificationdata={notificationErrorData} />
                </div>
              ),
            });
            dispatch(logoutUser());
          }
        })
        .catch((rejectedValueOrSerializedError) => {
          if (typeof rejectedValueOrSerializedError != "object") {
            const notificationErrorData = notificationData(
              "Change Password",
              rejectedValueOrSerializedError,
              "red",
              BiErrorCircle
            );

            toast({
              position: "bottom-left",
              render: () => (
                <div>
                  {" "}
                  <NotifyIndex notificationdata={notificationErrorData} />
                </div>
              ),
            });
          }
        });
    } else if (data.password == "") {
      const notificationErrorData = notificationData(
        "Change Password",
        "Current Password can't be empty",
        "red",
        BiErrorCircle
      );
      toast({
        position: "bottom-left",
        render: () => (
          <div>
            {" "}
            <NotifyIndex notificationdata={notificationErrorData} />
          </div>
        ),
      });
    } else if (data.newPassword == "") {
      const notificationErrorData = notificationData(
        "Change Password",
        "New Password can't be empty",
        "red",
        BiErrorCircle
      );
      toast({
        position: "bottom-left",
        render: () => (
          <div>
            {" "}
            <NotifyIndex notificationdata={notificationErrorData} />
          </div>
        ),
      });
    } else {
      const notificationErrorData = notificationData(
        "Change Password",
        "Passwords do not match",
        "red",
        BiErrorCircle
      );
      toast({
        position: "bottom-left",
        render: () => (
          <div>
            {" "}
            <NotifyIndex notificationdata={notificationErrorData} />
          </div>
        ),
      });
    }
  };

  return (
    <Flex justify="center">
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing="4" my={6}>
            <FormControl id="password">
              <Input
                name="currentPassword"
                type="password"
                autoComplete="password"
                required
                placeholder="Current Password"
                size="lg"
                {...register("currentPassword")}
                focusBorderColor={state}
              />
            </FormControl>
            <FormControl id="newPassword">
              <Input
                name="newPassword"
                type="password"
                autoComplete="password"
                required
                placeholder="New Password"
                size="lg"
                // isDisabled={status === "rejected" ? true : false}
                {...register("newPassword", {
                  onChange: (e) =>
                    onChange(e, "brand.300", "brand.200", strongRegex),
                })}
                focusBorderColor={state}
              />
            </FormControl>

            <Stack spacing="1">
              {textColorChange(inputvaue, capital, "brand.300", "gray.800") ===
              "brand.300" ? (
                <HStack>
                  <GoPrimitiveDot color="green" />{" "}
                  <Text
                    fontSize="x-small"
                    fontWeight="semibold"
                    textColor="black"
                  >
                    One uppercase letter
                  </Text>
                </HStack>
              ) : (
                <HStack>
                  <GoPrimitiveDot color="grey" />{" "}
                  <Text
                    fontSize="x-small"
                    fontWeight="semibold"
                    textColor="gray.300"
                  >
                    One uppercase letter
                  </Text>
                </HStack>
              )}
              {textColorChange(inputvaue, small, "brand.300", "gray.800") ===
              "brand.300" ? (
                <HStack>
                  <GoPrimitiveDot color="green" />{" "}
                  <Text
                    fontSize="x-small"
                    fontWeight="semibold"
                    textColor="black"
                  >
                    One lowercase letter
                  </Text>
                </HStack>
              ) : (
                <HStack>
                  <GoPrimitiveDot color="grey" />{" "}
                  <Text
                    fontSize="x-small"
                    fontWeight="semibold"
                    textColor="gray.300"
                  >
                    One lowercase letter
                  </Text>
                </HStack>
              )}
              {textColorChange(inputvaue, num, "brand.300", "gray.800") ===
              "brand.300" ? (
                <HStack>
                  <GoPrimitiveDot color="green" />{" "}
                  <Text
                    fontSize="x-small"
                    fontWeight="semibold"
                    textColor="black"
                  >
                    One number
                  </Text>
                </HStack>
              ) : (
                <HStack>
                  <GoPrimitiveDot color="grey" />{" "}
                  <Text
                    fontSize="x-small"
                    fontWeight="semibold"
                    textColor="gray.300"
                  >
                    One number
                  </Text>
                </HStack>
              )}
              {textColorChange(inputvaue, special, "brand.300", "gray.800") ===
              "brand.300" ? (
                <HStack>
                  <GoPrimitiveDot color="green" />{" "}
                  <Text
                    fontSize="x-small"
                    fontWeight="semibold"
                    textColor="black"
                  >
                    One special character
                  </Text>
                </HStack>
              ) : (
                <HStack>
                  <GoPrimitiveDot color="grey" />{" "}
                  <Text
                    fontSize="x-small"
                    fontWeight="semibold"
                    textColor="gray.300"
                  >
                    One special character
                  </Text>
                </HStack>
              )}
              {textColorChange(inputvaue, max, "brand.300", "gray.800") ===
              "brand.300" ? (
                <HStack>
                  <GoPrimitiveDot color="green" />{" "}
                  <Text
                    fontSize="x-small"
                    fontWeight="semibold"
                    textColor="black"
                  >
                    8 characters minimum
                  </Text>
                </HStack>
              ) : (
                <HStack>
                  <GoPrimitiveDot color="grey" />{" "}
                  <Text
                    fontSize="x-small"
                    fontWeight="semibold"
                    textColor="gray.300"
                  >
                    8 characters minimum
                  </Text>
                </HStack>
              )}
            </Stack>

            {state === "brand.300" && (
              <FormControl id="confirmPassword">
                <Input
                  name="confirmPassword"
                  type="password"
                  autoComplete="confirmPassword"
                  required
                  placeholder="Confirm Password"
                  size="lg"
                  {...register("confirmPassword")}
                />
              </FormControl>
            )}
            <Button
              type="submit"
              bg="brand.700"
              colorScheme="white"
              size="lg"
              fontSize="md"
              isDisabled={state === "brand.200" ? true : false}
            >
              Change Password
            </Button>
          </Stack>
        </form>
      </Box>
    </Flex>
  );
};
