import React from "react";
import {
  SimpleGrid,
  GridItem,
  FormControl,
  FormLabel,
  useBreakpointValue,
} from "@chakra-ui/react";
import { InfoEditableInput } from "./infoEditableInput";
import { useSelector } from "react-redux";

export const BottomAccordionPanel = () => {
  const col = useBreakpointValue({ base: "1", md: "2" });
  const credentials = useSelector(
    (state) => state.user.credentials.credentials
  );
  return (
    <div>
      <SimpleGrid columns={col} columnGap={3} rowGap={6} w="full">
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel fontSize="xs" color="brand.900" lineHeight={"0"}>
              {" "}
              Parish Address
            </FormLabel>

            <InfoEditableInput
              val={credentials?.parishAddress}
              name={"parishAddress"}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel fontSize="xs" color="brand.900" lineHeight={"0"}>
              {" "}
              GHPost Address
            </FormLabel>

            <InfoEditableInput
              val={credentials?.gpostAddress}
              name={"gpostAddress"}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel fontSize="xs" color="brand.900" lineHeight={"0"}>
              {" "}
              Alternative Email
            </FormLabel>

            <InfoEditableInput val={credentials?.altEmail} name={"altEmail"} />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel fontSize="xs" color="brand.900" lineHeight={"0"}>
              {" "}
              Parish Number
            </FormLabel>

            <InfoEditableInput
              val={credentials?.parishNumber}
              name={"parishNumber"}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel fontSize="xs" color="brand.900" lineHeight={"0"}>
              {" "}
              Alternative Number
            </FormLabel>

            <InfoEditableInput
              val={credentials?.altNumber}
              name={"altNumber"}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel fontSize="xs" color="brand.900" lineHeight={"0"}>
              {" "}
              Parish Priest Name
            </FormLabel>

            <InfoEditableInput
              val={credentials?.parishPriestName}
              name={"parishPriestName"}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel fontSize="xs" color="brand.900" lineHeight={"0"}>
              {" "}
              Priest Phone Number
            </FormLabel>
            <InfoEditableInput
              val={credentials?.priestPhoneNumber}
              name={"priestPhoneNumber"}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel fontSize="xs" color="brand.900" lineHeight={"0"}>
              {" "}
              Associate Priests
            </FormLabel>

            <InfoEditableInput
              val={credentials?.associatePriest}
              name={"associatePriest"}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel fontSize="xs" color="brand.900" lineHeight={"0"}>
              {" "}
              OutStations
            </FormLabel>

            <InfoEditableInput
              val={credentials?.outStation}
              name={"outStation"}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <FormLabel fontSize="xs" color="brand.900" lineHeight={"0"}>
              {" "}
              Website
            </FormLabel>

            <InfoEditableInput val={credentials?.website} name={"website"} />
          </FormControl>
        </GridItem>
      </SimpleGrid>
    </div>
  );
};
