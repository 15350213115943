import * as React from "react";
import { Card } from "../components/common/Card";
import { Link } from "../components/common/Link";
import Logo from "../util/logo";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  Button,
  chakra,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Box,
  Text,
  useColorModeValue,
  useToast,
  HStack,
  Icons,
} from "@chakra-ui/react";
import {
  checkPasswordResetToken,
  forgottenPasswordReset,
} from "../features/user/userSlice";
import { CircleIcon, PhoneIcon } from "@chakra-ui/icons";
import { BiErrorCircle, BiCheckDouble } from "react-icons/bi";
import { NotifyIndex } from "../components/notification/notifyIndex";
import { notificationData } from "../util/functions";
import { GoPrimitiveDot } from "react-icons/go";
import { useForm } from "react-hook-form";
import { textColorChange } from "../util/functions";

export const ChangePasswordFromEmail = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const toast = useToast();
  const history = useHistory();
  const status = useSelector((state) => state.user.status);
  const tokenDetails = useSelector((state) => state.user.tokenDetails);
  const { register, handleSubmit } = useForm();
  const [state, setState] = useState("");
  const [inputvaue, setInputvaue] = useState("");

  //regular expression
  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  const capital = new RegExp(/[A-Z]/);
  const small = new RegExp(/[a-z]/);
  const num = new RegExp(/[0-9]/);
  const special = new RegExp(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/);
  const max = new RegExp(/^.{8,16}$/);

  //check if strongRegex is met
  //set the input value to state to be use to check for the other exps.
  //if strongRegex is true setState to vbrand
  //else setState to ebrand
  const onChange = (e, vbrand, ebrand, regex) => {
    setInputvaue(e.currentTarget.value);
    if (regex.test(e.currentTarget.value)) {
      setState(vbrand);
    } else {
      setState(ebrand);
    }
  };

  //check if the resetToken is valid
  useEffect(() => {
    const resetToken = {
      token: token,
    };
    dispatch(checkPasswordResetToken(resetToken))
      .unwrap()
      .then((originalPromiseResult) => {})
      .catch((rejectedValueOrSerializedError) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Password Reset",
            rejectedValueOrSerializedError,
            "red",
            BiErrorCircle
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
        }
      });
  }, []);

  //Submit the password reset
  const onSubmit = (data, e) => {
    e.preventDefault();
    const password = {
      newPassword: data.password,
      confirmPassword: data.confirmPassword,
      parishUserId: tokenDetails.parishUserId,
      parishUserName: tokenDetails.parishUserName,
    };

    if (data.password === data.confirmPassword) {
      dispatch(forgottenPasswordReset(password))
        .unwrap()
        .then((originalPromiseResult) => {
          if (typeof originalPromiseResult != "object") {
            const notificationErrorData = notificationData(
              "Password Reset",
              originalPromiseResult,
              "green",
              BiCheckDouble
            );
            toast({
              position: "bottom-left",
              render: () => (
                <div>
                  {" "}
                  <NotifyIndex notificationdata={notificationErrorData} />
                </div>
              ),
            });
            history.push("/login");
          }
        })
        .catch((rejectedValueOrSerializedError) => {
          if (typeof rejectedValueOrSerializedError != "object") {
            const notificationErrorData = notificationData(
              "Password Reset",
              rejectedValueOrSerializedError,
              "red",
              BiErrorCircle
            );

            toast({
              position: "bottom-left",
              render: () => (
                <div>
                  {" "}
                  <NotifyIndex notificationdata={notificationErrorData} />
                </div>
              ),
            });
          }
        });
    } else if (data.password == "") {
      const notificationErrorData = notificationData(
        "Password Reset",
        "Password can't be empty",
        "red",
        BiErrorCircle
      );
      toast({
        position: "bottom-left",
        render: () => (
          <div>
            {" "}
            <NotifyIndex notificationdata={notificationErrorData} />
          </div>
        ),
      });
    } else {
      const notificationErrorData = notificationData(
        "Password Reset",
        "Passwords do not match",
        "red",
        BiErrorCircle
      );
      toast({
        position: "bottom-left",
        render: () => (
          <div>
            {" "}
            <NotifyIndex notificationdata={notificationErrorData} />
          </div>
        ),
      });
    }
  };

  //textColorChange is a function that accepts reg exp, and return vbrand or ebrand base on exp
  return (
    <Box
      bg={"brand.800"}
      minH="100vh"
      py="4"
      px={{
        base: "4",
        lg: "8",
      }}
    >
      <Box maxW="md" mx="auto" my={4}>
        <Logo
          mx="auto"
          h="8"
          mb={{
            base: "10",
            md: "10",
          }}
          iconColor="white"
          color="white"
        />

        <Card>
          <Text align="center" maxW="md" mb="4" lineHeight={1}>
            RESET YOUR PASSWORD
          </Text>
          <Text align="center" maxW="md" mb="4" lineHeight={1}>
            Please Enter your new Password to continue
          </Text>

          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing="4" my={6}>
              <FormControl id="password">
                <Input
                  name="password"
                  type="password"
                  autoComplete="password"
                  required
                  placeholder="New Password"
                  size="lg"
                  isDisabled={status === "rejected" ? true : false}
                  {...register("password", {
                    onChange: (e) =>
                      onChange(e, "brand.300", "brand.200", strongRegex),
                  })}
                  focusBorderColor={state}
                />
              </FormControl>

              <Stack spacing="1">
                {textColorChange(
                  inputvaue,
                  capital,
                  "brand.300",
                  "gray.800"
                ) === "brand.300" ? (
                  <HStack>
                    <GoPrimitiveDot color="green" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="black"
                    >
                      One uppercase letter
                    </Text>
                  </HStack>
                ) : (
                  <HStack>
                    <GoPrimitiveDot color="grey" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="gray.300"
                    >
                      One uppercase letter
                    </Text>
                  </HStack>
                )}
                {textColorChange(inputvaue, small, "brand.300", "gray.800") ===
                "brand.300" ? (
                  <HStack>
                    <GoPrimitiveDot color="green" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="black"
                    >
                      One lowercase letter
                    </Text>
                  </HStack>
                ) : (
                  <HStack>
                    <GoPrimitiveDot color="grey" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="gray.300"
                    >
                      One lowercase letter
                    </Text>
                  </HStack>
                )}
                {textColorChange(inputvaue, num, "brand.300", "gray.800") ===
                "brand.300" ? (
                  <HStack>
                    <GoPrimitiveDot color="green" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="black"
                    >
                      One number
                    </Text>
                  </HStack>
                ) : (
                  <HStack>
                    <GoPrimitiveDot color="grey" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="gray.300"
                    >
                      One number
                    </Text>
                  </HStack>
                )}
                {textColorChange(
                  inputvaue,
                  special,
                  "brand.300",
                  "gray.800"
                ) === "brand.300" ? (
                  <HStack>
                    <GoPrimitiveDot color="green" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="black"
                    >
                      One special character
                    </Text>
                  </HStack>
                ) : (
                  <HStack>
                    <GoPrimitiveDot color="grey" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="gray.300"
                    >
                      One special character
                    </Text>
                  </HStack>
                )}
                {textColorChange(inputvaue, max, "brand.300", "gray.800") ===
                "brand.300" ? (
                  <HStack>
                    <GoPrimitiveDot color="green" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="black"
                    >
                      8 characters minimum
                    </Text>
                  </HStack>
                ) : (
                  <HStack>
                    <GoPrimitiveDot color="grey" />{" "}
                    <Text
                      fontSize="x-small"
                      fontWeight="semibold"
                      textColor="gray.300"
                    >
                      8 characters minimum
                    </Text>
                  </HStack>
                )}
              </Stack>

              {state === "brand.300" && (
                <FormControl id="confirmPassword">
                  <Input
                    name="confirmPassword"
                    type="password"
                    autoComplete="confirmPassword"
                    required
                    required
                    placeholder="Confirm Password"
                    size="lg"
                    {...register("confirmPassword")}
                  />
                </FormControl>
              )}
              <Button
                type="submit"
                bg="brand.700"
                colorScheme="white"
                size="lg"
                fontSize="md"
                isDisabled={state === "brand.200" ? true : false}
              >
                Change Password
              </Button>
            </Stack>
          </chakra.form>
        </Card>

        <Text mt="1" mb="1" align="center" maxW="md" fontWeight="medium">
          <Text as="span" color="brand.50">
            Remembered your Password?
          </Text>
          <Link href="/login">Login</Link>
        </Text>
      </Box>
    </Box>
  );
};
