import {
  Stack,
  Link,
  Text,
  VStack,
  Image,
  useBreakpointValue,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { removeOccassion } from "../../features/dataAll/dataMegAnnEvtSlice";
import { activeLink } from "../../features/ui/uiSlice";
import { IsSessionExpired } from "../../util/functions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import mes_gif from "../../image/message.gif";
import evt_gif from "../../image/event.gif";
import bull_gif from "../../image/bulletin.gif";
import ann_gif from "../../image/announcement.gif";
import myp_gif from "../../image/myParish.gif";

const PlanItemCard = (props) => {
  const { name, isActive } = props;
  const dispatch = useDispatch();
  // const [isColor, setColor] = useState("brand.100");
  const history = useHistory();
  //const col = useBreakpointValue({ base: "2", md: "2" });

  const des = (params) => {
    if (params === "Parish Event") {
      return {
        word: "Create Event by setting Start, End Date, Event title and Details",
        img: evt_gif,
      };
    } else if (params === "Announcement") {
      return {
        word: "Create  Announcement by setting sunday Date and Details",
        img: ann_gif,
      };
    } else if (params === "Bulletin") {
      return {
        word: "upload a PDF copy of your Parish bulletin by Sunday Dates",
        img: bull_gif,
      };
    } else if (params === "Message") {
      return {
        word: "Send a Message to your parishioners by providing message title and Details",
        img: mes_gif,
      };
    } else if (params === "My Parish") {
      return {
        word: "Provide neccessary  parish information, confession and mass Times",
        img: myp_gif,
      };
    } else if (params === "Signup Request") {
      return {
        word: "Handle signup request",
        img: mes_gif,
      };
    } else {
      return "";
    }
  };

  const HandleCard = (linkname) => {
    switch (linkname) {
      case "Parish Event":
        IsSessionExpired();
        dispatch(activeLink(linkname));
        dispatch(removeOccassion());
        history.push("/parishevent?status=parishEvent");
        break;
      case "Announcement":
        IsSessionExpired();
        dispatch(activeLink(linkname));
        dispatch(removeOccassion());
        history.push("/announcement?status=announcement");
        break;
      case "Bulletin":
        IsSessionExpired();
        dispatch(activeLink(linkname));
        dispatch(removeOccassion());
        history.push("/bulletin?status=bulletin");
        break;
      case "Message":
        IsSessionExpired();
        dispatch(activeLink(linkname));
        dispatch(removeOccassion());
        history.push("/message?status=message");
        break;
      case "My Parish":
        IsSessionExpired();
        dispatch(activeLink(linkname));
        dispatch(removeOccassion());
        history.push("/myparish?status=myparish");
        break;
      case "Signup Request":
        IsSessionExpired();
        dispatch(activeLink(linkname));
        dispatch(removeOccassion());
        history.push("/parish/signupRequest");
        break;

      default:
        break;
    }
  };

  return (
    <div>
      <VStack spacing={"0"} w="full">
        <Link
          bg="gray.100"
          _activeLink={{
            bg: mode("brand.800", "gray.700"),
          }}
          _hover={{
            bg: mode("brand.30", "gray.700"),
            color: mode("gray.900", "white"),
          }}
          // pb="4"
          h={"30vh"}
          borderRadius={"6"}
          //width={"inherit"}
          aria-current={isActive ? "page" : undefined}
          onClick={(e) => HandleCard(name, e)}
        >
          {" "}
          {/* <Container position="relative" textAlign={"center"}>
            <Image w="100%" h={"100%"} src={des(name).img}></Image> */}
          <Stack justify={"space-between"} my="2" h="full">
            <Text
              mx="2"
              fontWeight={"semibold"}
              color={"brand.700"}
              // position="absolute"
              // top={"8px"}
              // left="16px"
            >
              {name}
            </Text>
            {/* <HStack justify={"center"}> */}
            <Image maxW="50%" maxH={"70%"} src={des(name).img}></Image>
            {/* </HStack> */}

            <Text
              as="sub"
              px="2"
              pb="4"
              fontWeight={"thin"}
              color={"gray.400"}
              lineHeight="4"
              // position="absolute"
              // bottom={"8px"}
              // right="16px"
            >
              {des(name).word}
            </Text>
          </Stack>{" "}
          {/* </Container> */}
        </Link>
      </VStack>
    </div>
  );
};

export default PlanItemCard;
