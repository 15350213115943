import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Axios from 'axios';
import {store} from './store';
import { Provider } from 'react-redux';

Axios.defaults.baseURL = process.env.React_App_BASE_URL


ReactDOM.render(
 
  <React.StrictMode>
     <Provider store={store}>
    <App />
  </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

//https://us-central1-bulletinmain.cloudfunctions.net/api
//http://localhost:5001/bulletinmain/us-central1/api
reportWebVitals();
