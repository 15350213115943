import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import * as React from "react";
import { Notification } from "./notification";

export const NotifyIndex = (props) => {
  const { notificationdata } = props;

  return (
    <Box>
      <Flex direction="row-reverse">
        <Notification notificationdata={notificationdata}>
          <Stack spacing="1">
            <Heading as="h3" fontSize="sm">
              {notificationdata.head}
            </Heading>
            <Text fontSize="xs">{notificationdata.details}</Text>
          </Stack>
        </Notification>
      </Flex>
    </Box>
  );
};
