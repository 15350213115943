import {
  Box,
  Center,
  Flex,
  Icon,
  useColorModeValue,
  Stack,
  StackDivider,
  CircleIcon,
  CloseIcon,
  IconButton,
  Button,
} from "@chakra-ui/react";
import * as React from "react";
import { FiInfo } from "react-icons/fi";
import { GrFormClose } from "react-icons/gr";
import { useToast } from "@chakra-ui/react";

export const Notification = (props) => {
  const { children, ...flexProps } = props;
  const { notificationdata } = props;
  const toast = useToast();

  function closeAll() {
    toast.closeAll();
  }
  return (
    <Flex
      direction={{
        base: "column",
        sm: "row",
      }}
      width="sm"
      boxShadow="lg"
      bg={useColorModeValue("white", "gray.700")}
      borderRadius={{
        base: "none",
        sm: "base",
      }}
      overflow="hidden"
      borderTopWidth={{
        base: "4px",
        sm: "0",
      }}
      borderColor={useColorModeValue(notificationdata.col, "red.300")}
      {...flexProps}
    >
      <Center
        display={{
          base: "none",
          sm: "flex",
        }}
        bg={useColorModeValue(notificationdata.col, "red.300")}
        px="4"
      >
        <Icon
          as={notificationdata.noticon}
          boxSize="9"
          color={useColorModeValue("white", "gray.900")}
        />
      </Center>
      <Stack
        direction={{ base: "column", sm: "row" }}
        divider={<StackDivider />}
        spacing="3"
      >
        <Box px="4" py="3">
          {children}
        </Box>
        <Stack
          //   direction={{ base: 'row', sm: 'column' }}
          height="full"
          divider={<StackDivider />}
          spacing="0"
        >
          <Flex py="6" px="2">
            <Button variant="link" size="sm" onClick={closeAll}>
              Close
            </Button>
          </Flex>
        </Stack>
      </Stack>
    </Flex>
  );
};
//onClick={closeAll}
