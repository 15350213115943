import React from "react";
import {
  Flex,
  Box,
  Text,
  Icon,
  SlideFade,
  HStack,
  IconButton,
  Stack,
  FormControl,
  Button,
  SimpleGrid,
  Spinner,
  Heading,
  useColorModeValue as mode,
  Skeleton,
} from "@chakra-ui/react";
import { Redirect, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { RiMenuFoldFill, RiMenuUnfoldFill } from "react-icons/ri";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getAnnouncement,
  getOccassion,
  isErrorAnnouncement,
  removeErrorAnnouncement,
  removeOccassion,
  sendAnnouncement,
  generateOccassion,
} from "../features/dataAll/dataMegAnnEvtSlice";
import { useToast } from "@chakra-ui/toast";
import { notificationData, OccassionDate } from "../util/functions";
import { NotifyIndex } from "../components/notification/notifyIndex";
import { BiErrorCircle, BiCheckDouble } from "react-icons/bi";
import { logoutUser } from "../features/user/userSlice";
import { SkeletonMessage } from "../components/common/skeleton";
import { useForm } from "react-hook-form";
import { SentAnnouncement } from "../components/announcement/sentAnnouncement";
import dateAndTime from "date-and-time";

export function Announcement() {
  const userSubcription = useSelector(
    (state) => state.user.credentials.subscription
  );
  const isGetPending = useSelector((state) => state.dataMegAnnEvt.statusGet);
  const isformSubmitting = useSelector(
    (state) => state.dataMegAnnEvt.statusAnnouncement
  );

  const isError = useSelector((state) => state.dataMegAnnEvt.errorAnnouncement);
  const isStatusOccassion = useSelector(
    (state) => state.dataMegAnnEvt.statusGetOccassion
  );
  const isOccassion = useSelector((state) => state.dataMegAnnEvt.occassion);
  const [showPanel, setShowPanel] = useState(true);
  const [sundayDate, setSundayDate] = useState(null);
  const [quillValue, setQuillValue] = useState("");
  const toast = useToast();

  const { handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const match = search.match(/status=(.*)/);
  const status = match?.[1];

  useEffect(() => {
    dispatch(getAnnouncement());
  }, []);

  const base1 = () => {
    if (showPanel === true) {
      return "block";
    } else {
      return "none";
    }
  };

  const base2 = () => {
    if (showPanel === true) {
      return "none";
    } else {
      return "block";
    }
  };

  function rightSideOnclick() {
    setShowPanel(false);
  }

  function rightSideOffclick() {
    setShowPanel(true);
  }

  function generateOcc(e) {
    //This function was used to generate a one time occassions
    e.preventDefault();
    const today = dateAndTime.parse("02-01-2022", "DD-MM-YYYY");

    for (var i = 0; i <= 1456; i += 7) {
      var now = dateAndTime.addDays(today, i);
      var nextSunday = dateAndTime.format(now, "YYYY/MM/DD");

      dispatch(generateOccassion(nextSunday));
    }
  }

  function onSubmit(data, e) {
    //isOccassion.map((occassion) => occassion.title)
    e.preventDefault();
    const theSundayDate = sundayDate == null ? "" : sundayDate;
    const occassion = isOccassion;
    const occassionString = occassion.toString();
    var rawQuillValue = quillValue
      .replace(/<[^>]+>/g, "")
      .replace(/\&nbsp;/g, "");
    const newAnnouncement = {
      sundayDate: theSundayDate,
      announcementDetails: quillValue,
      occassion: occassionString,
      rawAnnouncementDetails: rawQuillValue,
    };
    // check for sunday date
    if (newAnnouncement.sundayDate == "") {
      dispatch(
        isErrorAnnouncement({ sundayDate: "Sunday Date cannot be empty" })
      );
    } else if (newAnnouncement.sundayDate.getDay() != 0) {
      dispatch(isErrorAnnouncement({ sundayDate: "Should be a Sunday Date" }));
    } else {
      dispatch(sendAnnouncement(newAnnouncement))
        .unwrap()
        .then((originalPromiseResult) => {
          if (typeof rejectedValueOrSerializedError != "object") {
            const notificationErrorData = notificationData(
              "Sunday Announcement",
              "Sent",
              "green",
              BiCheckDouble
            );

            toast({
              position: "bottom-left",
              render: () => (
                <div>
                  {" "}
                  <NotifyIndex notificationdata={notificationErrorData} />
                </div>
              ),
            });
            setSundayDate(null);
            setQuillValue("");
            dispatch(removeErrorAnnouncement());
            dispatch(removeOccassion());
          }
        })
        .catch((rejectedValueOrSerializedError) => {
          if (typeof rejectedValueOrSerializedError != "object") {
            const notificationErrorData = notificationData(
              "Sunday Announcement",
              rejectedValueOrSerializedError,
              "red",
              BiErrorCircle
            );

            toast({
              position: "bottom-left",
              render: () => (
                <div>
                  {" "}
                  <NotifyIndex notificationdata={notificationErrorData} />
                </div>
              ),
            });
          } else if (
            rejectedValueOrSerializedError?.code == "auth/id-token-expired"
          ) {
            dispatch(logoutUser());
            //IsBackEndSessionExpired();
          }
        });
    }
  }

  function HandleSundayDate(e) {
    setSundayDate(e);
    const theData = {
      sundayDate: OccassionDate(e),
    };
    dispatch(getOccassion(theData));
  }

  return (
    <div>
      {status === "announcement" && userSubcription?.announcement ? (
        <Flex>
          <Flex
            flex="1"
            bg="brand.20"
            h={{ base: "94vh", md: "90vh" }}
            display={{
              base: base1,
              md: "block",
            }}
          >
            <Box rounded="base" h="full" w="full">
              <Box py="2" bg="white" boxShadow={"sm"}>
                <HStack height={{ base: "16", md: "12" }}>
                  <Box display="flex" justifyContent={"center"} width="full">
                    <Heading size={"sm"} color="brand.800">
                      Send Sunday Announcement
                    </Heading>
                  </Box>

                  <IconButton
                    size="lg"
                    colorScheme="brand.100"
                    onClick={rightSideOnclick}
                    display={{ md: "none" }}
                  >
                    <Icon color="brand.900" as={RiMenuFoldFill}></Icon>
                  </IconButton>
                </HStack>
              </Box>
              <Box m="0.5" h="full" bg="white">
                <Box pl="10" pr="10" pt="4">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                      m="0.5"
                      h={{ base: "60vh", md: "60vh" }}
                      overflowY="auto"
                    >
                      <Stack>
                        <FormControl>
                          <SimpleGrid
                            columns={{ base: 1, md: 2 }}
                            spacing={{ base: 4, md: 10 }}
                            mb={{ base: "0", md: "2" }}
                            mt={{ base: "2", md: "2" }}
                          >
                            <Stack>
                              {
                                <Text as="sub" color={"red.500"}>
                                  {isError?.sundayDate}
                                </Text>
                              }
                              <Box
                                borderColor={"gray.300"}
                                borderWidth={"1px"}
                                maxWidth={"fit-content"}
                              >
                                <DatePicker
                                  selected={sundayDate}
                                  // onChange={(date) => setSundayDate(date)}
                                  onChange={HandleSundayDate}
                                  placeholderText="Select Sunday Date"
                                />
                              </Box>
                            </Stack>

                            <Box
                              justifySelf={{ base: "normal", md: "flex-end" }}
                            ></Box>
                          </SimpleGrid>
                        </FormControl>
                        <FormControl>
                          <Box
                            fontSize={"sm"}
                            color={"geen.500"}
                            mb={{ base: "4", md: "4" }}
                          >
                            {isStatusOccassion == "pending" ? (
                              <Skeleton height="20px" />
                            ) : (
                              isOccassion
                              // isOccassion.map((occassion) => occassion.title)
                            )}
                          </Box>
                        </FormControl>
                        <FormControl>
                          <Text as="sub" color={"red.500"}>
                            {isError?.announcementDetails}
                            {isError?.occassion}
                          </Text>
                          <ReactQuill
                            value={quillValue}
                            modules={Announcement.modules}
                            formats={Announcement.formats}
                            onChange={setQuillValue}
                            theme="snow"
                            placeholder=" Your Announcement Details"
                          />
                        </FormControl>
                      </Stack>
                    </Box>
                    <Button
                      size="lg"
                      type="submit"
                      mt="8"
                      w="full"
                      bg="brand.700"
                      colorScheme="white"
                      fontSize="md"
                      fontWeight="bold"
                      // onClick={generateOcc}
                    >
                      {isformSubmitting === "pending" ? (
                        <Spinner size="xs" />
                      ) : (
                        "SEND "
                      )}
                    </Button>
                  </form>
                </Box>
              </Box>
            </Box>
          </Flex>

          <Flex
            display={{
              base: base2,
              lg: "block",
            }}
            flex={{ base: "1", md: "none" }}
            width="96"
            direction="column"
            borderLeftWidth="1px"
            pl={{ base: "10", md: "0" }}
            h="93vh"
          >
            {" "}
            <SlideFade
              offsetY="20px"
              SlideDirection="top"
              in={showPanel == true ? true : true}
            >
              <Box
                rounded="base"
                h={{ base: "88vh", md: "92vh", lg: "92vh" }}
                bg="white"
                shadow={{
                  base: "xl",
                  md: "none",
                }}
                borderRadius={{ base: "none", md: "5" }}
                borderLeftRadius={{ base: "3xl", md: "5" }}
              >
                <Box boxShadow={"sm"} py="2">
                  <HStack height={{ base: "16", md: "12" }}>
                    <Box display="flex" justifyContent={"center"} width="full">
                      <HStack spacing={1}>
                        <Heading size={"sm"} color="brand.800">
                          Sent Sunday Announcements
                        </Heading>
                        <Text as="i" fontSize="8">
                          {"(first 5)"}
                        </Text>
                      </HStack>
                    </Box>

                    <IconButton
                      colorScheme="brand.100"
                      onClick={rightSideOffclick}
                      display={{ md: "none" }}
                      size={"lg"}
                    >
                      <Icon color="brand.900" as={RiMenuUnfoldFill}></Icon>
                    </IconButton>
                  </HStack>
                </Box>
                <Box
                  overflowY="auto"
                  h={{ base: "78vh", md: "84vh", lg: "82vh" }}
                >
                  <Stack m={6}>
                    {isGetPending == "pending" ? (
                      <SkeletonMessage />
                    ) : (
                      <SentAnnouncement />
                    )}
                  </Stack>
                </Box>
              </Box>
            </SlideFade>
          </Flex>
        </Flex>
      ) : (
        <div>
          <Redirect to="/home"></Redirect>
        </div>
      )}
    </div>
  );
}

Announcement.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill Announcement formats
 * See https://quilljs.com/docs/formats/
 */
Announcement.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];
