import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const loginUser = createAsyncThunk(
  "users/loginUser",
  async (userData, { rejectWithValue }) => {
    try {
      const res = await axios.post("/login", userData);
      const FBIdToken = `Bearer ${res.data.token}`;
      localStorage.setItem("FBIdToken", FBIdToken);
      axios.defaults.headers.common["Authorization"] = FBIdToken;
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "users/logoutUser",
  async (id = null, { rejectWithValue }) => {
    try {
      localStorage.removeItem("FBIdToken");
      delete axios.defaults.headers.common["Authorization"];
      const res = await axios.post("/logout");

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//signup
export const signupUser = createAsyncThunk(
  "users/signupUser",
  async (userData, { rejectWithValue }) => {
    try {
      const res = await axios.post("/signup", userData);
      const FBIdToken = `Bearer ${res.data.theToken}`;
      localStorage.setItem("FBIdToken", FBIdToken);
      axios.defaults.headers.common["Authorization"] = FBIdToken;
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Get Data of login user
export const getData = createAsyncThunk(
  "users/getData",
  async (id = null, { rejectWithValue }) => {
    try {
      const res = await axios.get("/parishuser");
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "users/forgotPassword",
  async (email, { rejectWithValue }) => {
    try {
      const res = await axios.post("/forgotpassword", email);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkPasswordResetToken = createAsyncThunk(
  "users/checkPasswordResetToken",
  async (token, { rejectWithValue }) => {
    try {
      const res = await axios.post("/passwordReset/verifylink", token);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Get Started
export const getStarted = createAsyncThunk(
  "users/getStarted",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post("/getStartedData", data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Reject user(Parish) getStarted request
export const getStartedReject = createAsyncThunk(
  "users/getStartedReject",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post("/getStartedRejects", data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Approve to Create parishuser for getStarted request
export const createParishUser = createAsyncThunk(
  "users/createParishUser",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post("/createParishUser", data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//user provides newpassword and confirmpassword to be use to reset password
//note: this is where user has forgotten his password
export const forgottenPasswordReset = createAsyncThunk(
  "users/forgottenPasswordReset",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post("/forgottenPasswordReset", data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//user provides currentPassword, newpassword and confirmpassword to be use to change password
//note: this is where user wants to change password
export const changePassword = createAsyncThunk(
  "users/forgottenPasswordReset",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post("/confirmCurrentPassword", data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//realtime check of parishusername
export const realTimeParishUsernameCheck = createAsyncThunk(
  "users/realTimeParishUsernameCheck",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post("/realTimeCheckParishUserName", data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  statusLogout: null,
  status: null,
  statusRealTimeCheck: null,
  error: null,
  credentials: {},
  tokenDetails: {},
  authenticated: false,
  realTimeParishUsernameCheck: null,
};

export const userSlce = createSlice({
  name: "user",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.authenticated = action.payload;
    },
  },
  extraReducers: {
    //login user
    [loginUser.pending]: (state, action) => {
      state.status = "pending";
    },
    [loginUser.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [loginUser.rejected]: (state, action) => {
      state.status = "rejected";
    },

    //logout user
    [logoutUser.pending]: (state, action) => {
      state.statusLogout = "pending";
    },
    [logoutUser.fulfilled]: (state, action) => {
      state.statusLogout = "success";
      state.authenticated = false;
    },
    [logoutUser.rejected]: (state, action) => {
      state.statusLogout = "rejected";
    },

    //signup user
    [signupUser.pending]: (state, action) => {
      state.status = "pending";
    },
    [signupUser.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [signupUser.rejected]: (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    },

    //Get UserData
    [getData.pending]: (state, action) => {
      state.status = "pending";
    },
    [getData.fulfilled]: (state, action) => {
      state.status = "success";
      state.credentials = action.payload;
    },
    [getData.rejected]: (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    },

    [forgotPassword.pending]: (state, action) => {
      state.status = "pending";
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.status = "success";
      //state.credentials = action.payload;
    },
    [forgotPassword.rejected]: (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    },

    [checkPasswordResetToken.pending]: (state, action) => {
      state.status = "pending";
    },
    [checkPasswordResetToken.fulfilled]: (state, action) => {
      state.status = "success";

      state.tokenDetails = action.payload.tokenDetails;
    },
    [checkPasswordResetToken.rejected]: (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    },

    //Reset Password
    [forgottenPasswordReset.pending]: (state, action) => {
      state.status = "pending";
    },
    [forgottenPasswordReset.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [forgottenPasswordReset.rejected]: (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    },

    //change Password
    [changePassword.pending]: (state, action) => {
      state.status = "pending";
    },
    [changePassword.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [changePassword.rejected]: (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    },

    //getStarted
    [getStarted.pending]: (state, action) => {
      state.status = "pending";
    },
    [getStarted.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getStarted.rejected]: (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    },

    //getStarted reject
    [getStartedReject.pending]: (state, action) => {
      state.status = "pending";
    },
    [getStartedReject.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getStartedReject.rejected]: (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    },

    //create parishUser
    [createParishUser.pending]: (state, action) => {
      state.status = "pending";
    },
    [createParishUser.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [createParishUser.rejected]: (state, action) => {
      state.status = "rejected";
      state.error = action.payload;
    },

    //real time parishUsername check
    [realTimeParishUsernameCheck.pending]: (state, action) => {
      state.statusRealTimeCheck = "pending";
    },
    [realTimeParishUsernameCheck.fulfilled]: (state, action) => {
      state.statusRealTimeCheck = "success";
      state.realTimeParishUsernameCheck = action.payload;
    },
    [realTimeParishUsernameCheck.rejected]: (state, action) => {
      state.statusRealTimeCheck = "rejected";
      state.error = action.payload;
    },
  },
});
export const { authenticated } = userSlce.actions;
export default userSlce.reducer;
