import React from "react";
import {
  Flex,
  Box,
  Icon,
  Heading,
  HStack,
  IconButton,
  TabPanel,
  TabPanels,
  Tabs,
  Tab,
  TabList,
} from "@chakra-ui/react";
import { Redirect, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useState } from "react";
import { RiMenuFoldFill } from "react-icons/ri";
import { ChangePasswordForm } from "../components/account/changePasswordForm";
import { SubcriptionTypes } from "../components/account/subcriptionTypes";

export function Account() {
  const [showPanel, setShowPanel] = useState(true);
  const userSubcription = useSelector(
    (state) => state.user.credentials.subscription
  );
  const { search } = useLocation();
  const match = search.match(/status=(.*)/);
  const status = match?.[1];

  const base1 = () => {
    if (showPanel === true) {
      return "block";
    } else {
      return "none";
    }
  };

  // const base2 = () => {
  //   if (showPanel === true) {
  //     return "none";
  //   } else {
  //     return "block";
  //   }
  // };

  function rightSideOnclick() {
    setShowPanel(false);
  }

  // function rightSideOffclick() {
  //   setShowPanel(true);
  // }
  return (
    <div>
      {status === "account" && userSubcription?.account ? (
        <Flex>
          <Flex
            flex="1"
            bg="brand.20"
            h={{ base: "94vh", md: "90vh" }}
            display={{
              base: base1,
              md: "block",
            }}
          >
            <Box rounded="base" h="full" w="full">
              <Box py="2" bg="white" boxShadow={"sm"}>
                <HStack height={{ base: "16", md: "12" }}>
                  <Box
                    display="flex"
                    justifyContent={"start"}
                    width="full"
                    ml="4"
                  >
                    <Heading size={"sm"} color="brand.800">
                      Account
                    </Heading>
                  </Box>

                  <IconButton
                    size="lg"
                    colorScheme="brand.100"
                    onClick={rightSideOnclick}
                    display={{ base: "none", md: "none" }}
                  >
                    <Icon color="brand.900" as={RiMenuFoldFill}></Icon>
                  </IconButton>
                </HStack>
              </Box>
              <Box m="0.5" h="full" bg="white" overflowY="auto">
                <Box pl="2" pr="2" pt="1" marginBottom="20">
                  <Tabs isFitted variant="enclosed">
                    <TabList mb="1em">
                      <Tab
                        _selected={{ color: "white", bg: "brand.700" }}
                        _focus={{ borderColor: "white", boxShadow: "none" }}
                      >
                        Subcription
                      </Tab>
                      <Tab
                        _selected={{ color: "white", bg: "brand.700" }}
                        _focus={{ borderColor: "white", boxShadow: "none" }}
                      >
                        Change Password
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <SubcriptionTypes />
                      </TabPanel>
                      <TabPanel>
                        <ChangePasswordForm />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Box>
              </Box>
            </Box>
          </Flex>

          {/* <Flex
            display={{
              base: base2,
              lg: "block",
            }}
            flex={{ base: "1", md: "none" }}
            // width={{ base: "100vh", md: "10vh", lg: "60vh" }}
            width="96"
            direction="column"
            overflowY="auto"
            borderLeftWidth="1px"
            pl={{ base: "10", md: "0" }}
            h="93vh"
          >
            {" "}
            <SlideFade
              offsetY="20px"
              SlideDirection="top"
              in={showPanel == true ? true : true}
            >
              <Box
                rounded="base"
                h={{ base: "88vh", md: "90vh" }}
                bg="white"
                shadow={{
                  base: "xl",
                  md: "none",
                }}
                borderRadius={{ base: "none", md: "5" }}
                borderLeftRadius={{ base: "3xl", md: "5" }}
              >
                <Box boxShadow={"sm"} py="2">
                  <HStack height={{ base: "16", md: "12" }}>
                    <Box display="flex" justifyContent={"center"} width="full">
                      <label>Published </label>
                    </Box>

                    <IconButton
                      colorScheme="brand.100"
                      onClick={rightSideOffclick}
                      display={{ md: "none" }}
                      size={"lg"}
                    >
                      <Icon color="brand.900" as={RiMenuUnfoldFill}></Icon>
                    </IconButton>
                  </HStack>
                </Box>
                <Box>
                  <p>Hi</p>
                </Box>
              </Box>
            </SlideFade>
          </Flex> */}
        </Flex>
      ) : (
        <div>
          <Redirect to="/home"></Redirect>
        </div>
      )}
    </div>
  );
}
