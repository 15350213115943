import * as React from "react";
import { Card } from "../components/common/Card";
import { Link } from "../components/common/Link";
import Logo from "../util/logo";
import {
  Button,
  chakra,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Box,
  Text,
  useColorModeValue,
  Spinner,
  HStack,
  CircleIcon,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../features/user/userSlice";
import { NotifyIndex } from "../components/notification/notifyIndex";
import { useToast } from "@chakra-ui/react";
import { BiErrorCircle, BiCheckDouble } from "react-icons/bi";
import { notificationData } from "../util/functions";

export const ForgotPassword = () => {
  const { register, handleSubmit, watch } = useForm();
  const dispatch = useDispatch();
  const toast = useToast();
  const isformSubmitting = useSelector((state) => state.user.status);

  const onSubmit = (data, e) => {
    // add submit logic here
    e.preventDefault();
    const email = {
      parishEmail: data.email,
    };
    dispatch(forgotPassword(email))
      .unwrap()
      .then((originalPromiseResult) => {
        if (typeof originalPromiseResult != "object") {
          const notificationErrorData = notificationData(
            "Reset Link Sent",
            originalPromiseResult,
            "green",
            BiCheckDouble
          );
          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Could not Reset Password",
            rejectedValueOrSerializedError,
            "red",
            BiErrorCircle
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
        }
      });
  };

  return (
    <Box
      bg={useColorModeValue("brand.800", "brand.900")}
      minH="100vh"
      py="4"
      px={{
        base: "4",
        lg: "8",
      }}
    >
      <Box maxW="md" mx="auto" mt={4} mb={2}>
        <Logo
          mx="auto"
          h="8"
          mb={{
            base: "10",
            md: "10",
          }}
          iconColor="white"
          color="white"
        />
        <Card>
          <Text align="center" maxW="md" mt={4} mb={2} lineHeight={1}>
            REQUEST PASSWORD RESET
          </Text>

          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing="6" my={6}>
              <FormControl id="email">
                <Input
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  {...register("email")}
                  placeholder="Email Address"
                  size="lg"
                />
              </FormControl>

              <Button
                type="submit"
                bg="brand.700"
                colorScheme="white"
                size="lg"
                fontSize="md"
              >
                {isformSubmitting === "pending" ? (
                  <Spinner size="xs" />
                ) : (
                  "Request Reset"
                )}
              </Button>
            </Stack>
          </chakra.form>
        </Card>

        <Text mt="1" mb="1" align="center" maxW="md" fontWeight="medium">
          <Text as="span" color="brand.50">
            Remembered your Password?
          </Text>
          <Link href="/login">Login</Link>
        </Text>
      </Box>
    </Box>
  );
};
