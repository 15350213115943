import {
  Box,
  Button,
  CloseButton,
  Container,
  Icon,
  Square,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import * as React from "react";
import { FiInfo } from "react-icons/fi";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../features/user/userSlice";
import {
  EmptySuccessPage,
  EmptyvarifiedTransaction,
  getParishSubscribedData,
} from "../features/dataAll/dataSubscriptionSlice";

export const Banner = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      localStorage.setItem("PayStackPaymentSuccess", "");
      dispatch(EmptySuccessPage(""));
    }, 40000);
  }, []);

  const Refresh = () => {
    dispatch(getData());
    dispatch(getParishSubscribedData());
  };
  const CloseBanner = () => {
    localStorage.setItem("PayStackPaymentSuccess", "");
    dispatch(EmptySuccessPage(""));
    //dispatch(EmptyvarifiedTransaction());
  };
  return (
    <Box
      as="section"
      mx={{ base: "2", md: "6" }}
      pt={{ base: "4", md: "8" }}
      pb={{ base: "12", md: "24" }}
    >
      <Box
        bg="brand.100"
        color="brand.800"
        px={{ base: "4", md: "3" }}
        py={{ base: "4", md: "2.5" }}
        position="relative"
        borderRadius="xl"
      >
        <CloseButton
          display={{ sm: "none" }}
          position="absolute"
          right="2"
          top="2"
        />
        <Stack
          direction={{ base: "column", sm: "row" }}
          justify="space-between"
          spacing={{ base: "3", md: "2" }}
          pb="0.5"
        >
          <Stack
            spacing="4"
            direction={{ base: "column", md: "row" }}
            align={{ base: "start", md: "center" }}
          >
            {!isMobile && (
              <Square size="12" bg="brand.10" borderRadius="md">
                <Icon as={FiInfo} boxSize="6" />
              </Square>
            )}
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "0.5", md: "1.5" }}
              pe={{ base: "4", sm: "0" }}
            >
              <Text fontWeight="medium">
                Click on Refresh if your paid Plan is not showing.
              </Text>
            </Stack>
          </Stack>
          <Stack
            direction={{ base: "column", sm: "row" }}
            spacing={{ base: "3", sm: "2" }}
            align={{ base: "stretch", sm: "center" }}
          >
            <Button
              variant="solid"
              color="brand.100"
              bg="brand.600"
              onClick={Refresh}
            >
              Refresh
            </Button>
            <CloseButton
              onClick={CloseBanner}
              display={{ base: "none", sm: "inline-flex" }}
            />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};
