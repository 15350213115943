import React from "react";
import { useToast } from "@chakra-ui/react";
import { NotifyIndex } from "./notifyIndex";
import { createStandaloneToast } from "@chakra-ui/react";

export const Toast = (params) => {
  const toast = createStandaloneToast();
  return toast({
    position: "bottom-left",
    render: () => (
      <div>
        {" "}
        <NotifyIndex notificationdata={params} />
      </div>
    ),
  });
};
