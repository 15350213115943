import { Box, Flex, HStack } from "@chakra-ui/react";
import * as React from "react";
import { MdOutlineLogout } from "react-icons/md";
import Logo from "../util/logo";
import { NavMenuItem } from "./NavMenuItem";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import { IsSessionExpired } from "../util/functions";
import { activeLink } from "../features/ui/uiSlice";
import { removeOccassion } from "../features/dataAll/dataMegAnnEvtSlice";
import { useDispatch } from "react-redux";

// const MobileNavMenu = (props: { isOpen?: boolean }) => {
//   const { isOpen } = props
//   return (
//     <Flex
//       hidden={!isOpen}
//       as="nav"
//       direction="column"
//       bg="blue.600"
//       position="fixed"
//       height="calc(100vh - 4rem)"
//       top="16"
//       insetX="0"
//       zIndex={10}
//       w="full"
//     >
//       <Box px="4">
//         <NavItem.Mobile active label="Dashboard" />
//         <NavItem.Mobile label="Campaigns" />
//         <NavItem.Mobile label="Forms" />
//         <NavItem.Mobile label="Sites" />
//         <NavItem.Mobile label="Automation" />
//       </Box>
//     </Flex>
//   )
// }

const DesktopNavMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const logoClick = () => {
    IsSessionExpired();
    dispatch(activeLink("Home"));
    dispatch(removeOccassion());
    history.push("/Home");
  };
  return (
    // <HStack  display={{ base: 'none', md: 'flex' }}>
    <Flex
      justify="space-between"
      display={{ base: "none", md: "flex" }}
      py="3"
      px="7"
      w="full"
    >
      <Link to="/Home">
        <Logo h="10" iconColor="brand.700" onClick={logoClick} />
      </Link>
      <NavMenuItem.Desktop active icon={<MdOutlineLogout />} label="Logout" />
    </Flex>
    // </HStack>
  );
};
export default DesktopNavMenu;
// export const NavMenu = {
//  // Mobile: MobileNavMenu,
//   Desktop: DesktopNavMenu,
// }  spacing="3" flex="1"  direction="row"
