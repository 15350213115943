import { GridItem, Text, useColorModeValue as mode } from "@chakra-ui/react";
import React from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";

const FeaturesText = (props) => {
  const feat = props.featureName;
  // const keys = Object.keys(feat);
  return feat.map((feature) => (
    <GridItem colSpan={1} key={feature.k}>
      {" "}
      <Text
        fontSize={"xs"}
        color={"brand.900"}
        _activeLink={{
          color: mode("brand.50", "gray.700"),
        }}
        aria-current={props.isActive ? "page" : undefined}
      >
        <CheckCircleIcon color="green.500" size={2} /> {feature.n}
      </Text>
    </GridItem>
  ));
};

export default FeaturesText;
