import React from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spinner,
  useBreakpointValue,
  Heading,
} from "@chakra-ui/react";
import { TopAccordionPanel } from "./topAccordionPanel";
import { BottomAccordionPanel } from "./bottomAccordionPanel";
import { useSelector } from "react-redux";

export const AccordionParishInfo = () => {
  const isUpdating = useSelector((state) => state.dataBullMyP.statusUpdate);
  const col = useBreakpointValue({ base: "1", md: "2" });

  return (
    <Box>
      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton _focus={{ boxShadow: "none" }}>
              <Box flex="1" textAlign="left">
                <Heading as="h5" size="sm">
                  {" "}
                  Primary Information
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <TopAccordionPanel />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _focus={{ boxShadow: "none" }}>
              <Box flex="1" textAlign="left">
                {isUpdating === "pending" ? (
                  <Spinner size="xs" />
                ) : (
                  <Heading as="h5" size="sm">
                    Secondary Information
                  </Heading>
                )}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <BottomAccordionPanel />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
