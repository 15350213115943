import {
  HStack,
  Text,
  Box,
  SimpleGrid,
  GridItem,
  Heading,
  Button,
  Spinner,
} from "@chakra-ui/react";
import React from "react";
import { useBreakpointValue } from "@chakra-ui/react";
import SubscriptionCard from "./subscriptionCard";
import { useSelector } from "react-redux";
import { CancelSubscription } from "./cancelSubscription";
import { SelectPayment } from "./selectPayment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  plusSubscriptionRequest,
  selectPlan,
} from "../../features/dataAll/dataSubscriptionSlice";
import { SubscriptionInfo } from "./subscriptionInfo";
import { BiErrorCircle, BiCheckDouble } from "react-icons/bi";
import { NotifyIndex } from "../../components/notification/notifyIndex";
import { notificationData } from "../../util/functions";
import { useToast } from "@chakra-ui/toast";

export const SubcriptionTypes = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const col = useBreakpointValue({ base: "1", md: "3" });
  const credentials = useSelector(
    (state) => state.user.credentials.credentials
  );
  useEffect(() => {
    dispatch(selectPlan(credentials.plan));
  }, []);
  const dataSubscription = useSelector((state) => state.dataSubscription);
  const parishSubscribedData = useSelector(
    (state) => state.dataSubscription.parishSubscribedData
  );

  const free = {
    plan: "Free",
    amount: "0.00",
    isColor: "brand.500",
    features: [
      { n: "Announcement", k: 7 },
      { n: "My Parish", k: 8 },
    ],
  };
  const standard = {
    plan: "Standard",
    amount: "99.00",
    isColor: "brand.600",
    features: [
      { n: "Bulletin", k: 3 },
      { n: "Massage (InApp)", k: 4 },
      { n: "Parish Event", k: 5 },
      { n: "Announcement", k: 7 },
      { n: "My Parish", k: 8 },
    ],
  };
  const plus = {
    plan: "Plus",
    amount: "199.00",
    isColor: "brand.700",
    features: [
      { n: "Customized Parish App", k: 1 },
      { n: "SMS Message", k: 2 },
      { n: "Bulletin", k: 3 },
      { n: "Massage (InApp)", k: 4 },
      { n: "Parish Event", k: 5 },
      { n: "Announcement", k: 7 },
      { n: "My Parish", k: 8 },
    ],
  };

  const notifyPlus = (e) => {
    e.preventDefault();
    const newPlusRequest = {
      emailAddress: credentials.parishEmail,
    };
    dispatch(plusSubscriptionRequest(newPlusRequest))
      .unwrap()
      .then((originalPromiseResult) => {
        if (typeof originalPromiseResult != "object") {
          const notificationErrorData = notificationData(
            "Plus Subscription",
            originalPromiseResult,
            "green",
            BiCheckDouble
          );
          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Plus Subscription",
            rejectedValueOrSerializedError,
            "red",
            BiErrorCircle
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
        }
      });
  };
  return (
    <Box mx={{ base: "2", md: "6" }}>
      <HStack spacing={"2"} pb="2">
        <Heading size="sm">Currently subscribed to</Heading>
        <Heading size="sm" color="brand.600">
          {credentials.plan}
        </Heading>
        <Heading size="sm">plan</Heading>
      </HStack>
      <SubscriptionInfo />

      <Heading size="md" pb="2" mt="2" color={"brand.800"}>
        Upgrade Subscription
      </Heading>
      <SimpleGrid columns={col} columnGap={3} rowGap={6} w="full">
        <GridItem colSpan={1}>
          {" "}
          <SubscriptionCard
            name={free}
            isActive={dataSubscription.selectedPlan === "Free" ? true : false}
          />
        </GridItem>
        <GridItem colSpan={1}>
          {" "}
          <SubscriptionCard
            name={standard}
            isActive={
              dataSubscription.selectedPlan === "Standard" ? true : false
            }
          />
        </GridItem>
        <GridItem colSpan={1}>
          {" "}
          <SubscriptionCard
            name={plus}
            isActive={dataSubscription.selectedPlan === "Plus" ? true : false}
          />
        </GridItem>
      </SimpleGrid>
      <Box bg="gray.50" my="4" p="4">
        <CancelSubscription />
        {/* {dataSubscription.userExpiresAtStatus === "Keep Plan same" &&
        dataSubscription.parishSubscribedData.channel === "card" ? (
          <div></div>
        ) : ( */}
        <SelectPayment />
        {/* )} */}

        {dataSubscription.selectedPlan === "Plus" && (
          <Box>
            <Text>
              {" "}
              This Plan is currently not available. Click the "Notify Me" Button
              below to receive Email message when available
            </Text>
            <Button mt="4" onClick={notifyPlus}>
              {" "}
              {dataSubscription.statusGet === "pending" ? (
                <Spinner size="xs" />
              ) : (
                " Notify Me"
              )}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

// { n: "Daily Mass Reading", k: 6 },
