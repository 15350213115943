import {
  Box,
  Divider,
  Flex,
  Spacer,
  Stack,
  Progress,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import * as React from "react";

import { Link } from "react-router-dom";
import { FaRegBell, FaRegQuestionCircle, FaChurch } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { GiChurch, GiNewspaper } from "react-icons/gi";
import { BsCalendar4Event, BsInputCursor } from "react-icons/bs";
import { BiMessageRoundedDots } from "react-icons/bi";
import { MdOutlineSwitchAccount } from "react-icons/md";
import { ImBullhorn } from "react-icons/im";
import { AccountSwitcher } from "./AccountSwitcher";
import SideNavLink from "./SideNavLink";
import { useDispatch, useSelector } from "react-redux";
import { activeLink } from "../features/ui/uiSlice";
import { IsSessionExpired } from "../util/functions";
import { removeOccassion } from "../features/dataAll/dataMegAnnEvtSlice";

const Sidebar = (props) => {
  const { onClose } = props;
  const dispatch = useDispatch();
  const userSubcription = useSelector(
    (state) => state.user.credentials.subscription
  );
  const isActiveLink = useSelector((state) => state.ui.activeLink);

  const getCredentialsStatus = useSelector((state) => state.user.status);

  function ActiveLink(linkname, e, onClose) {
    IsSessionExpired();
    dispatch(activeLink(linkname));
    dispatch(removeOccassion());
    onClose();
  }
  return (
    <Flex
      bg="brand.800"
      direction="column"
      borderRightWidth="1px"
      width="64"
      {...props}
    >
      <Flex direction="column" flex="1" pt="5" pb="4" overflowY="auto" px="4">
        <Box mb="6">
          <AccountSwitcher />
          {/* <Logo color={mode('blue.600', 'blue.400')} h="6" /> */}
        </Box>

        <Box mb="6">{/* <SearchField /> */}</Box>

        <Stack spacing="6" as="nav" aria-label="Sidebar Navigation">
          <Stack spacing="1">
            {userSubcription?.home && (
              <Link to="/home" onClick={(e) => ActiveLink("Home", e, onClose)}>
                {" "}
                <SideNavLink
                  as="li"
                  label="Home"
                  icon={AiOutlineHome}
                  isActive={isActiveLink == "Home" ? true : false}
                />
              </Link>
            )}
            {userSubcription?.signupRequest && (
              <Link
                to="/parish/signupRequest"
                onClick={(e) => ActiveLink("Signup Request", e, onClose)}
              >
                {" "}
                <SideNavLink
                  as="li"
                  label="Signup Request"
                  icon={BsInputCursor}
                  isActive={isActiveLink == "Signup Request" ? true : false}
                />
              </Link>
            )}
            {userSubcription?.myParish && (
              <Link
                to="/myparish?status=myparish"
                onClick={(e) => ActiveLink("My Parish", e, onClose)}
              >
                {" "}
                <SideNavLink
                  as="li"
                  label="My Parish"
                  icon={FaChurch}
                  isActive={isActiveLink == "My Parish" ? true : false}
                />
              </Link>
            )}

            {userSubcription?.parishEvent && (
              <Link
                to="/parishevent?status=parishEvent"
                onClick={(e) => ActiveLink("Parish Event", e, onClose)}
              >
                <SideNavLink
                  as="li"
                  label="Parish Event"
                  icon={BsCalendar4Event}
                  isActive={isActiveLink == "Parish Event" ? true : false}
                />
              </Link>
            )}
            {userSubcription?.announcement && (
              <Link
                to="/announcement?status=announcement"
                onClick={(e) => ActiveLink("Announcement", e, onClose)}
              >
                <SideNavLink
                  as="li"
                  label="Announcement"
                  icon={ImBullhorn}
                  isActive={isActiveLink == "Announcement" ? true : false}
                />
              </Link>
            )}
            {userSubcription?.bulletin && (
              <Link
                to="/bulletin?status=bulletin"
                onClick={(e) => ActiveLink("Bulletin", e, onClose)}
              >
                <SideNavLink
                  as="li"
                  label="Bulletin"
                  icon={GiNewspaper}
                  isActive={isActiveLink == "Bulletin" ? true : false}
                />
              </Link>
            )}
            {userSubcription?.message && (
              <Link
                to="/message?status=message"
                onClick={(e) => ActiveLink("Message", e, onClose)}
              >
                <SideNavLink
                  as="li"
                  label="Message"
                  icon={BiMessageRoundedDots}
                  isActive={isActiveLink == "Message" ? true : false}
                />
              </Link>
            )}
            {userSubcription?.account && (
              <Link
                to="/account?status=account"
                onClick={(e) => ActiveLink("Account", e, onClose)}
              >
                <SideNavLink
                  as="li"
                  label="Account"
                  icon={MdOutlineSwitchAccount}
                  isActive={isActiveLink == "Account" ? true : false}
                />
              </Link>
            )}

            {/* <SideNavLink label="Statistics" icon={FaRegChartBar} /> */}
          </Stack>

          {getCredentialsStatus === "pending" ? (
            <Progress height="1px" isIndeterminate />
          ) : (
            <Divider />
          )}

          <Stack spacing="1">
            <SideNavLink as="li" label="Notifications" icon={FaRegBell} />
            <SideNavLink
              as="li"
              label="Help Center"
              icon={FaRegQuestionCircle}
            />
          </Stack>
        </Stack>
        <Spacer />
      </Flex>

      {/* <UserProfile
        name="Cindy Winston"
        image="https://images.unsplash.com/photo-1521296797187-726205347ca9?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NjR8fGxhZHklMjBzbWlsaW5nfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
        email="cindy@example.com"
      /> */}
    </Flex>
  );
};

export default Sidebar;

//{mode('gray.50', 'gray.800')}
