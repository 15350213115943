import React from "react";
import { useSelector } from "react-redux";
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Box,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { cancelOrUpdateSubscription } from "../../features/dataAll/dataSubscriptionSlice";

export const CancelSubscription = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const credentials = useSelector(
    (state) => state.user.credentials.credentials
  );
  const dataSubscription = useSelector((state) => state.dataSubscription);

  const handleYesButton = () => {
    const authorizationCode = {
      authorizationCode:
        dataSubscription.parishSubscribedData.authorizationCode,
      firstName: credentials.parishName,
      lastName: credentials.parishUserName,
    };
    return dispatch(cancelOrUpdateSubscription(authorizationCode))
      .unwrap()
      .then((originalPromiseResult) => {
        window.location.href = originalPromiseResult;
      })
      .catch((rejectedValueOrSerializedError) => {});
  };
  return (
    <>
      {dataSubscription.selectedPlan === "Standard" &&
        dataSubscription.parishSubscribedData.channel === "card" && (
          <Button size="xs" onClick={onOpen} color="white" bg="brand.600">
            Cancel Subscription
          </Button>
        )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cancel Subscription</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box bg="brand.100" p="3" borderRadius={"4"}>
              <Text fontSize={"sm"}>
                {" "}
                Canceling your current plan will move your subscription to Free
                after your subscription period is expired.{" "}
              </Text>
            </Box>
            <Text fontSize="md" fontWeight={"semibold"} my="4">
              {" "}
              Are you sure you want to Cancel your Subscription?
            </Text>
          </ModalBody>

          <ModalFooter justifyContent={"space-between"}>
            <Button colorScheme="blue" variant="solid" mr={3} onClick={onClose}>
              Dont Cancel
            </Button>
            <Button variant="outline" onClick={handleYesButton}>
              YES
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
