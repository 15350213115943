import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getData } from "../features/user/userSlice";
import { useSelector } from "react-redux";
import { Banner } from "../util/banner";
import { useState } from "react";
import { SuccessPage } from "../features/dataAll/dataSubscriptionSlice";
import {
  Box,
  GridItem,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import SubscriptionCard from "../components/account/subscriptionCard";
import PlanItemCard from "../components/home/planItemCard";
import { subscription } from "../features/ui/uiSlice";

export function Home() {
  const userSubcription = useSelector((state) => state.user.credentials);
  const dispatch = useDispatch();
  const col = useBreakpointValue({ base: "1", md: "3", lg: "4" });
  const varifiedTransaction = useSelector(
    (state) => state.dataSubscription.varifiedTransaction
  );

  useEffect(() => {
    const PayStackPSRef = localStorage.PayStackPaymentSuccess;
    dispatch(SuccessPage(PayStackPSRef));
  }, []);

  useEffect(() => {
    dispatch(getData())
      .unwrap()
      .then((originalPromiseResult) => {})
      .catch((rejectedValueOrSerializedError) => {});
  }, [varifiedTransaction]);

  useEffect(() => {
    const subscriptions = userSubcription?.subscription;
    if (typeof subscriptions === "object") {
      const objArray = [];
      Object.keys(subscriptions).forEach((key) =>
        objArray.push({
          name: subscriptions[key],
          id: Math.random().toString(16).slice(2),
        })
      );
      return dispatch(
        subscription(objArray.sort((a, b) => (a.name > b.name ? 1 : -1)))
      );
    }
  }, [userSubcription]);
  const fromSuccessPage = useSelector(
    (state) => state.dataSubscription.fromSuccessPage
  );
  const subscriptions = useSelector((state) => state.ui.subscriptions);

  return (
    <Box overflowY="auto" h={{ base: "100vh", md: "100vh", lg: "100vh" }}>
      {/* <Banner></Banner> */}
      {fromSuccessPage === "YES" ? <Banner></Banner> : <div></div>}
      <Box w="full" h="100vh" py="10" px={{ base: "6", md: "10", lg: "20" }}>
        <Text fontWeight={"semibold"} fontSize="lg" color={"brand.900"}>
          Welcome...
        </Text>
        <Heading pt="4" color={"brand.900"}>
          {userSubcription?.credentials?.parishName}
        </Heading>
        <Text fontWeight={"semibold"} color={"brand.900"} pt="-3">
          {userSubcription?.credentials?.parishCityTown}
        </Text>
        <Box py="6" w="full" h="full">
          <SimpleGrid columns={col} columnGap={12} rowGap={6}>
            {subscriptions.map((result) => (
              <GridItem colSpan={1} key={result.id}>
                <PlanItemCard
                  name={result.name}
                  // isActive={dataSubscription.selectedPlan === "Free" ? true : false}
                />
              </GridItem>
            ))}{" "}
            {/* <GridItem colSpan={1}>
            {" "}
            <PlanItemCard
            // name={standard}
            // isActive={
            //   dataSubscription.selectedPlan === "Standard" ? true : false
            // }
            />
          </GridItem>
          <GridItem colSpan={1}>
            {" "}
            <PlanItemCard
            //  name={plus}
            // isActive={dataSubscription.selectedPlan === "Plus" ? true : false}
            />
          </GridItem>
          <GridItem colSpan={1}>
            {" "}
            <PlanItemCard
            //  name={plus}
            // isActive={dataSubscription.selectedPlan === "Plus" ? true : false}
            />
          </GridItem>
          <GridItem colSpan={1}>
            {" "}
            <PlanItemCard
            //  name={plus}
            // isActive={dataSubscription.selectedPlan === "Plus" ? true : false}
            />
          </GridItem>
          <GridItem colSpan={1}>
            {" "}
            <PlanItemCard
            //  name={plus}
            // isActive={dataSubscription.selectedPlan === "Plus" ? true : false}
            />
          </GridItem> */}
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  );
}
