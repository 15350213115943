import {
  HStack,
  Icon,
  Link,
  useColorModeValue as mode,
  Text,
} from "@chakra-ui/react";
import * as React from "react";

const SideNavLink = (props) => {
  const { icon, isActive, label, ...rest } = props;
  return (
    <Link
      display="block"
      py="2"
      px="3"
      borderRadius="md"
      transition="all 0.3s"
      fontWeight="medium"
      fontSize="sm"
      userSelect="none"
      aria-current={isActive ? "page" : undefined}
      color={mode("white", "gray.400")}
      _hover={{
        bg: mode("gray.100", "gray.700"),
        color: mode("gray.900", "white"),
      }}
      _activeLink={{
        bg: mode("gray.200", "gray.700"),
        color: "inherit",
      }}
      {...rest}
    >
      <HStack spacing="4">
        <Icon as={icon} fontSize="lg" opacity={0.64} />
        <Text as="span">{label}</Text>
      </HStack>
    </Link>
  );
};
export default SideNavLink;
