import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

//get All Bulletin
export const getVerifiedTransaction = createAsyncThunk(
  "dataSubscription/getVerifyPayStackTransaction",
  async (ref, { rejectWithValue }) => {
    try {
      const res = await axios.post("/verifyPastackTransaction", ref);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//create a payStack plan
export const createPlan = createAsyncThunk(
  "dataSubscription/createPlan",
  async (createPlanData, { rejectWithValue }) => {
    try {
      const res = await axios.post("/createPlan/payStack", createPlanData);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//create a payStack subscription
export const createSubscriptionPayStack = createAsyncThunk(
  "dataSubscription/createSubscriptionPayStack",
  async (subscriptionData, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        "/createSubscription/payStack",
        subscriptionData
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//create a payStack Momo payment
export const InitializeMomoPayStack = createAsyncThunk(
  "dataSubscription/InitializeMomoPayStack",
  async (InitializeMData, { rejectWithValue }) => {
    try {
      const res = await axios.post("/InitializeMomo/payStack", InitializeMData);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Get plan update of user
export const userExpiresAtStatus = createAsyncThunk(
  "dataSubscription/userExpiresAtStatus",
  async (id = null, { rejectWithValue }) => {
    try {
      const res = await axios.get("/userExpiresAtStatus/payStack");
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Get parish subscribed Data
export const getParishSubscribedData = createAsyncThunk(
  "dataSubscription/getParishSubscribedData",
  async (id = null, { rejectWithValue }) => {
    try {
      const res = await axios.get("/getParishSubscribedData/payStack");
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//cancel or update paystack subscription
export const cancelOrUpdateSubscription = createAsyncThunk(
  "dataSubscription/cancelOrUpdateSubscription",
  async (code, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        "/cancelORUpdateSubscription/payStack",
        code
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Request for Plus Subscription when ready
export const plusSubscriptionRequest = createAsyncThunk(
  "dataSubscription/plusSubscriptionRequest",
  async (requestData, { rejectWithValue }) => {
    try {
      const res = await axios.post("/plusRequest", requestData);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  selectedSubData: null,
  selectedPlan: null,
  momoAmount: "",
  noMonths: "",
  fromSuccessPage: "",
  disableMomoPay: true,
  statusGet: null,
  varifiedTransaction: "",
  authorizationUrl: null,
  planCode: null,
  userExpiresAtStatus: null,
  parishSubscribedData: {},
  cancelOrUpdateLink: null,
  isPlusSubscriptionRequest: "",
  errorVarifiedTransaction: null,
  errorCreateSubscription: null,
  errorCreatePlan: null,
  errorcancelOrUpdate: null,
  error: null,
  isPlusSubscriptionRequestError: null,
};

export const dataSubscriptionSlice = createSlice({
  name: "dataSubscription",
  initialState,
  reducers: {
    selectedSubscription: (state, action) => {
      state.selectedSubData = action.payload;
    },
    selectPlan: (state, action) => {
      state.selectedPlan = action.payload;
    },
    isMomoAmount: (state, action) => {
      state.momoAmount = action.payload;
    },
    isDisableMomoPay: (state, action) => {
      state.disableMomoPay = action.payload;
    },
    isNoMonths: (state, action) => {
      state.noMonths = action.payload;
    },
    EmptyvarifiedTransaction: (state, action) => {
      state.varifiedTransaction = "";
    },
    SuccessPage: (state, action) => {
      state.fromSuccessPage = action.payload;
    },
    EmptySuccessPage: (state, action) => {
      state.fromSuccessPage = action.payload;
    },
  },
  extraReducers: {
    //get verify Transaction
    [getVerifiedTransaction.pending]: (state, action) => {
      state.statusGet = "pending";
    },
    [getVerifiedTransaction.fulfilled]: (state, action) => {
      state.statusGet = "success";
      state.varifiedTransaction = action.payload;
    },
    [getVerifiedTransaction.rejected]: (state, action) => {
      state.statusGet = "rejected";
      state.errorVarifiedTransaction = action.payload;
    },

    //create plan
    [createPlan.pending]: (state, action) => {
      state.statusGet = "pending";
    },
    [createPlan.fulfilled]: (state, action) => {
      state.statusGet = "success";
      state.planCode = action.payload;
    },
    [createPlan.rejected]: (state, action) => {
      state.statusGet = "rejected";
      state.errorCreatePlan = action.payload;
    },

    //create Subscription
    [createSubscriptionPayStack.pending]: (state, action) => {
      state.statusGet = "pending";
    },
    [createSubscriptionPayStack.fulfilled]: (state, action) => {
      state.statusGet = "success";
      state.authorizationUrl = action.payload;
    },
    [createSubscriptionPayStack.rejected]: (state, action) => {
      state.statusGet = "rejected";
      state.errorCreateSubscription = action.payload;
    },

    //initialize momo transaction
    [InitializeMomoPayStack.pending]: (state, action) => {
      state.statusGet = "pending";
    },
    [InitializeMomoPayStack.fulfilled]: (state, action) => {
      state.statusGet = "success";
      state.authorizationUrl = action.payload;
    },
    [InitializeMomoPayStack.rejected]: (state, action) => {
      state.statusGet = "rejected";
      state.errorCreateSubscription = action.payload;
    },

    //Get plan update of user
    [userExpiresAtStatus.pending]: (state, action) => {
      state.statusGet = "pending";
    },
    [userExpiresAtStatus.fulfilled]: (state, action) => {
      state.statusGet = "success";
      state.userExpiresAtStatus = action.payload;
    },
    [userExpiresAtStatus.rejected]: (state, action) => {
      state.statusGet = "rejected";
      state.error = action.payload;
    },

    //Get parish subscribed Data
    [getParishSubscribedData.pending]: (state, action) => {
      state.statusGet = "pending";
    },
    [getParishSubscribedData.fulfilled]: (state, action) => {
      state.statusGet = "success";
      state.parishSubscribedData = action.payload;
    },
    [getParishSubscribedData.rejected]: (state, action) => {
      state.statusGet = "rejected";
      state.error = action.payload;
    },

    //cancel or update payStack subscription
    [cancelOrUpdateSubscription.pending]: (state, action) => {
      state.statusGet = "pending";
    },
    [cancelOrUpdateSubscription.fulfilled]: (state, action) => {
      state.statusGet = "success";
      state.cancelOrUpdateLink = action.payload;
    },
    [cancelOrUpdateSubscription.rejected]: (state, action) => {
      state.statusGet = "rejected";
      state.errorcancelOrUpdate = action.payload;
    },

    //Request for Plus subscription when ready
    [plusSubscriptionRequest.pending]: (state, action) => {
      state.statusGet = "pending";
    },
    [plusSubscriptionRequest.fulfilled]: (state, action) => {
      state.statusGet = "success";
      state.isPlusSubscriptionRequest = action.payload;
    },
    [plusSubscriptionRequest.rejected]: (state, action) => {
      state.statusGet = "rejected";
      state.isPlusSubscriptionRequestError = action.payload;
    },
  },
});
export const {
  selectedSubscription,
  selectPlan,
  isMomoAmount,
  isDisableMomoPay,
  isNoMonths,
  EmptyvarifiedTransaction,
  SuccessPage,
  EmptySuccessPage,
} = dataSubscriptionSlice.actions;
export default dataSubscriptionSlice.reducer;
