import { React } from "react";
import {
  Text,
  HStack,
  IconButton,
  Stack,
  Divider,
  Link,
  useColorModeValue as mode,
  Input,
  Button,
  Spinner,
  Textarea,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteMessage,
  isSelectedMessage,
  removeErrorMessage,
  updateMessage,
} from "../../features/dataAll/dataMegAnnEvtSlice";
import { DeleteIcon } from "@chakra-ui/icons";
import { useDisclosure } from "@chakra-ui/hooks";
import { useState } from "react";
import { useToast } from "@chakra-ui/toast";
import { DateConvert, notificationData } from "../../util/functions";
import { NotifyIndex } from "../notification/notifyIndex";
import { BiErrorCircle, BiCheckDouble } from "react-icons/bi";
import { logoutUser } from "../../features/user/userSlice";

export const SentMessages = () => {
  const [isDisableMessage, setIsDisableMessage] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const messages = useSelector((state) => state.dataMegAnnEvt.messages);
  const selected = useSelector((state) => state.dataMegAnnEvt.selectedMessage);
  const isUpdating = useSelector((state) => state.dataMegAnnEvt.statusUpdate);
  const isDeleting = useSelector((state) => state.dataMegAnnEvt.statusDelete);
  const isError = useSelector((state) => state.dataMegAnnEvt.errorMessage);
  const [selectedMessage, setSelectedMessage] = useState({
    messageTitle: "",
    messageBody: "",
    messageId: "",
    countMessageBody: "",
  });

  //handle click of posted messages
  const postedMessageClick = (message, e) => {
    const thisIsSelected = {
      messageTitle: message.messageTitle,
      messageBody: message.messageBody,
      messageId: message.messageId,
    };
    dispatch(isSelectedMessage(thisIsSelected));
    setSelectedMessage({
      ...selectedMessage,
      messageTitle: message.messageTitle,
      messageBody: message.messageBody,
      messageId: message.messageId,
      countMessageBody: message.messageBody.length,
    });
    onOpen();
  };

  //count the characters and set its quantity to useState
  //check if characters is less than 250
  const count = (e) => {
    let inputValue = e.target.value;
    setSelectedMessage({
      ...selectedMessage,
      countMessageBody: inputValue.length,
    });
    if (inputValue.length > 250) {
      setIsDisableMessage(true);
    } else {
      setIsDisableMessage(false);
    }
  };
  function EditChangeTitle(event) {
    //e.preventDefault();
    setSelectedMessage({
      ...selectedMessage,
      messageTitle: event.target.value,
    });
  }

  function EditChangeMessage(event) {
    //e.preventDefault();
    setSelectedMessage({
      ...selectedMessage,
      messageBody: event.target.value,
    });
  }

  function EditSend(e) {
    e.preventDefault();
    const newMessage = {
      messageTitle: selectedMessage.messageTitle,
      messageBody: selectedMessage.messageBody,
      messageId: selectedMessage.messageId,
    };

    dispatch(updateMessage(newMessage))
      .unwrap()
      .then((originalPromiseResult) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Parish Message",
            "Update Complete",
            "green",
            BiCheckDouble
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
          dispatch(removeErrorMessage());
          onClose();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Parish Message",
            rejectedValueOrSerializedError,
            "red",
            BiErrorCircle
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
        } else if (
          rejectedValueOrSerializedError?.code === "auth/id-token-expired"
        ) {
          dispatch(logoutUser());
        }
      });
  }
  function CloseModel() {
    setIsDisableMessage(false);
    setConfirmDelete(false);
    dispatch(removeErrorMessage());
    onClose();
  }
  function IsDelete() {
    setConfirmDelete(true);
  }
  function YesDelete() {
    const newMessage = {
      messageId: selectedMessage.messageId,
    };
    dispatch(deleteMessage(newMessage))
      .unwrap()
      .then((originalPromiseResult) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Parish Message",
            "Delete Complete",
            "green",
            BiCheckDouble
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });

          setConfirmDelete(false);
          onClose();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Parish Message",
            rejectedValueOrSerializedError,
            "red",
            BiErrorCircle
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
        } else if (
          rejectedValueOrSerializedError?.code === "auth/id-token-expired"
        ) {
          dispatch(logoutUser());
        }
      });
  }

  return (
    <>
      {messages.map((message) => (
        <Link
          key={message.messageId}
          _hover={{ bg: "brand.50" }}
          // bg={
          //   signupRequestData.requestId === request.requestId &&
          //   "brand.400"
          // }
          _active={{
            bg: mode("gray.200", "gray.700"),
            color: "inherit",
          }}
          bg="brand.100"
          borderRadius={5}
          onClick={(e) => postedMessageClick(message, e)}
        >
          <Stack p={4}>
            <HStack>
              <Text fontWeight={"semibold"} fontSize="xs">
                Title:
              </Text>
              <Text isTruncated color={"brand.900"} fontSize="xs">
                {message.messageTitle}
              </Text>
            </HStack>
            <Text fontWeight={"semibold"} fontSize="xs">
              Details:
            </Text>
            <Text color={"brand.900"} fontSize="xs" noOfLines={3}>
              {message.messageBody}
            </Text>
            <Divider />
            <HStack justifyContent={"space-between"}>
              <Text color={"brand.900"} fontSize="xs">
                Crated on: {DateConvert(message.createdAt)}
              </Text>
              <IconButton
                variant={"ghost"}
                size={"sm"}
                aria-label="Delete"
                icon={<DeleteIcon />}
                onClick={IsDelete}
              />
            </HStack>
          </Stack>
        </Link>
      ))}
      {/* {modal} */}
      <Modal isOpen={isOpen} onClose={CloseModel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {" "}
            {confirmDelete === true ? "Confirm Delete" : "Eidt Message"}{" "}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {confirmDelete === true ? (
              <div>
                <Stack spacing={"4"} justifyContent={"center"}>
                  <Text py="4">
                    Are you sure you want to Delete this message
                  </Text>
                  <HStack>
                    <Button
                      variant={"outline"}
                      color="brand.900"
                      mr={3}
                      width={"full"}
                      onClick={CloseModel}
                      borderColor={"brand.900"}
                    >
                      NO
                    </Button>
                    <Button
                      variant={"outline"}
                      color="brand.900"
                      mr={3}
                      width={"full"}
                      onClick={YesDelete}
                    >
                      {isDeleting === "pending" ? <Spinner size="sm" /> : "YES"}
                    </Button>
                  </HStack>
                </Stack>
              </div>
            ) : (
              <div>
                <Text as="sub" color={"red.500"}>
                  {isError?.messageTitle}
                </Text>
                <Input
                  mb="4"
                  size="lg"
                  name="title"
                  type="text"
                  required
                  bg={mode("white", "gray.700")}
                  fontSize="md"
                  defaultValue={selected.messageTitle}
                  onChange={EditChangeTitle}
                ></Input>
                <Text as="sub" color={"red.500"}>
                  {isError?.messageBody}
                </Text>
                <Textarea
                  onKeyUp={(e) => count(e)}
                  onChange={EditChangeMessage}
                  type="text"
                  name="message"
                  required
                  placeholder="Enter your Message"
                  size="sm"
                  _focus={{ borderColor: "brand.800", boxShadow: "none" }}
                  h="32"
                  defaultValue={selected.messageBody}
                  // {...register("message")}
                />{" "}
                <Text as="sub">
                  max character 250/{selectedMessage.countMessageBody}
                </Text>
              </div>
            )}
          </ModalBody>

          <ModalFooter>
            {confirmDelete === false && (
              <HStack width={"full"}>
                {" "}
                <Button
                  variant={"outline"}
                  color="brand.900"
                  mr={3}
                  onClick={CloseModel}
                >
                  Close
                </Button>
                {/* <Button
                  variant={"outline"}
                  color="brand.900"
                  mr={3}
                  onClick={IsDelete}
                >
                  Delete
                </Button> */}
                <Button
                  colorScheme="brand.700"
                  bg="brand.700"
                  isDisabled={isDisableMessage}
                  onClick={EditSend}
                  w="full"
                >
                  {isUpdating === "pending" ? (
                    <Spinner size="sm" />
                  ) : (
                    "Save Edit"
                  )}
                </Button>
              </HStack>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

// (
//   messages.map((message) => (
//     <Link
//       key={message.messageId}
//       _hover={{ bg: "brand.50" }}
//       // bg={
//       //   signupRequestData.requestId === request.requestId &&
//       //   "brand.400"
//       // }
//       _active={{
//         bg: mode("gray.200", "gray.700"),
//         color: "inherit",
//       }}
//       bg="brand.100"
//       borderRadius={5}
//       onClick={(e) => postedMessageClick(message, e)}
//     >
//       <Stack p={4}>
//         <HStack>
//           <Text fontWeight={"semibold"} fontSize="xs">
//             Title:
//           </Text>
//           <Text
//             isTruncated
//             color={"brand.900"}
//             fontSize="xs"
//           >
//             {message.messageTitle}
//           </Text>
//         </HStack>
//         <Text fontWeight={"semibold"} fontSize="xs">
//           Details:
//         </Text>
//         <Text
//           color={"brand.900"}
//           fontSize="xs"
//           noOfLines={3}
//         >
//           {message.messageBody}
//         </Text>
//         <Divider />
//         <HStack justifyContent={"space-between"}>
//           <Text color={"brand.900"} fontSize="xs">
//             Crated on: {message.createdAt}
//           </Text>
//           <IconButton
//             variant={"ghost"}
//             size={"sm"}
//             aria-label="Delete"
//             icon={<DeleteIcon />}
//           />
//         </HStack>
//       </Stack>
//     </Link>
//   ))
// )
