import * as React from "react";
import { Card } from "../components/common/Card";
import { Link } from "../components/common/Link";
import Logo from "../util/logo";
import {
  Button,
  chakra,
  FormControl,
  Input,
  Stack,
  Select,
  Box,
  Text,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getStarted } from "../features/user/userSlice";
import { BiErrorCircle, BiCheckDouble } from "react-icons/bi";
import { NotifyIndex } from "../components/notification/notifyIndex";
import { notificationData } from "../util/functions";
import { useToast } from "@chakra-ui/toast";

export const GetStarted = () => {
  const { register, handleSubmit, reset } = useForm();

  const isformSubmitting = useSelector((state) => state.user.status);
  const dispatch = useDispatch();
  const toast = useToast();

  const onSubmit = (data, e) => {
    e.preventDefault();
    const newData = {
      plan: "Free",
      selectedPlan: data.plan,
      parishName: data.parishName,
      parishEmail: data.parishEmail,
      parishAddress: data.parishAddress,
      parishCityTown: data.parishCityTown,
      country: data.country,
      parishPriestName: data.parishPriestName,
      priestPhoneNumber: data.priestPhoneNumber,
    };
    dispatch(getStarted(newData))
      .unwrap()
      .then((originalPromiseResult) => {
        if (typeof originalPromiseResult != "object") {
          const notificationErrorData = notificationData(
            "Get Started",
            originalPromiseResult,
            "green",
            BiCheckDouble
          );
          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
          reset();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          const notificationErrorData = notificationData(
            "Get Started",
            rejectedValueOrSerializedError,
            "red",
            BiErrorCircle
          );

          toast({
            position: "bottom-left",
            render: () => (
              <div>
                {" "}
                <NotifyIndex notificationdata={notificationErrorData} />
              </div>
            ),
          });
        }
      });
  };

  return (
    <Box
      bg={useColorModeValue("brand.800", "brand.900")}
      minH="100vh"
      py="4"
      px={{
        base: "4",
        lg: "8",
      }}
    >
      <Box maxW="md" mx="auto" mt={4} mb={2}>
        <Logo
          mx="auto"
          h="8"
          mb={{
            base: "10",
            md: "5",
          }}
          iconColor="white"
          color="white"
        />

        <Card>
          <Text
            fontWeight="bold"
            align="center"
            maxW="md"
            mb="4"
            lineHeight={1}
          >
            Get Started
          </Text>

          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing="3">
              <FormControl id="plan">
                <Select
                  name="plan"
                  type="text"
                  placeholder="Select Subcription type"
                  size="lg"
                  {...register("plan")}
                  required
                >
                  <option value="Free">Free</option>
                  <option value="Standard">Standard</option>
                  {/* <option value="Plus">Plus</option> */}
                </Select>
              </FormControl>
              <FormControl id="parishName">
                <Input
                  name="parishName"
                  type="text"
                  autoComplete="parishName"
                  required
                  placeholder="Parish Name"
                  size="lg"
                  {...register("parishName")}
                />
              </FormControl>
              <FormControl id="parishEmail">
                <Input
                  name="parishEmail"
                  type="email"
                  autoComplete="email"
                  required
                  placeholder="Email Address"
                  size="lg"
                  {...register("parishEmail")}
                />
              </FormControl>
              <FormControl id="parishAddress">
                <Input
                  name="parishAddress"
                  type="text"
                  autoComplete="parishAddress"
                  required
                  placeholder="Parish Address"
                  size="lg"
                  {...register("parishAddress")}
                />
              </FormControl>
              <FormControl id="parishCityTown">
                <Input
                  name="parishCityTown"
                  type="text"
                  autoComplete="parishCityTown"
                  required
                  placeholder="Parish City/Town"
                  size="lg"
                  {...register("parishCityTown")}
                />
              </FormControl>
              <FormControl id="country">
                <Select
                  name="country"
                  type="text"
                  placeholder="Select Country"
                  size="lg"
                  {...register("country")}
                  required
                >
                  <option value="Ghana">Ghana</option>
                  <option value="Nigeria">Nigeria</option>
                </Select>
              </FormControl>
              <FormControl id="parishPriestName">
                <Input
                  name="parishPriestName"
                  type="text"
                  autoComplete="parishPriestName"
                  required
                  placeholder="Parish PriestName"
                  size="lg"
                  {...register("parishPriestName")}
                />
              </FormControl>
              <FormControl id="priestPhoneNumber">
                <Input
                  name="priestPhoneNumber"
                  type="number"
                  autoComplete="priestPhoneNumber"
                  required
                  placeholder="Parish Priest PhoneNumber"
                  size="lg"
                  {...register("priestPhoneNumber")}
                />
              </FormControl>

              <Button
                type="submit"
                bg="brand.700"
                colorScheme="white"
                size="lg"
                fontSize="md"
              >
                {isformSubmitting === "pending" ? (
                  <Spinner size="xs" />
                ) : (
                  "Send Request"
                )}
              </Button>
            </Stack>
          </chakra.form>
        </Card>
        <Text mt={4} mb={2} align="center" maxW="md" fontWeight="medium">
          <Text as="span" color="brand.50">
            Already have account?
          </Text>
          <Link href="/login">Login</Link>
        </Text>
      </Box>
    </Box>
  );
};
