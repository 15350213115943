import {
  Box,
  Button,
  Heading,
  Img,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import * as React from "react";
import { useHistory, useLocation } from "react-router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BiErrorCircle, BiCheckDouble } from "react-icons/bi";
import { notificationData } from "../util/functions";
import {
  getVerifiedTransaction,
  SuccessPage,
} from "../features/dataAll/dataSubscriptionSlice";
import { Toast } from "../components/notification/toast";
import successImage from "../image/Thankyou.png";

export const Success = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const match = search.match(/reference=(.*)/);
  const ref = match?.[1];

  //check if the resetToken is valid

  useEffect(() => {
    localStorage.setItem("PayStackPaymentSuccess", "YES");
    //dispatch(SuccessPage("YES"));
  }, []);

  useEffect(() => {
    const resetToken = {
      reference: ref,
    };
    dispatch(getVerifiedTransaction(resetToken))
      .then((originalPromiseResult) => {
        Toast(
          notificationData(
            "Payment",
            originalPromiseResult,
            "green",
            BiCheckDouble
          )
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        if (typeof rejectedValueOrSerializedError != "object") {
          Toast(
            notificationData(
              "Transaction Verification Error",
              rejectedValueOrSerializedError,
              "red",
              BiErrorCircle
            )
          );
        }
      });
  }, []);

  function GoBack() {
    window.location.href = "http://localhost:3000/home";
    dispatch(SuccessPage("YES"));
  }

  return (
    <Box as="section" h="100vh" bg={mode("brand.10", "brand.50")} pt="6">
      <Box
        maxW={{ base: "xl", md: "7xl" }}
        mx="auto"
        px={{ base: "6", md: "16" }}
      >
        <Stack
          direction={{ base: "column-reverse", lg: "row" }}
          spacing={{ base: "3rem", lg: "2rem" }}
          align={{ lg: "center" }}
          justify="space-between"
        >
          <Box flex="1" maxW={{ lg: "520px" }}>
            <Heading
              as="h3"
              size="lg"
              color={mode("brand.600", "blue.300")}
              mt="8"
              fontWeight="extrabold"
              letterSpacing="tight"
            >
              Payment succesful
            </Heading>
            <Text
              color={mode("gray.600", "gray.400")}
              mt="4"
              fontSize="lg"
              fontWeight="medium"
            >
              Thank you for subscribing to Standard Plan
            </Text>
            <Stack direction={{ base: "column", md: "row" }} spacing="4" mt="8">
              <Button
                size="lg"
                minW="210px"
                colorScheme="blue"
                height="14"
                px="8"
                onClick={GoBack}
              >
                Back Home
              </Button>
            </Stack>
          </Box>
          <Box
            pos="relative"
            w={{ base: "full", lg: "560px" }}
            h={{ base: "auto", lg: "560px" }}
            pt={{ base: "16", md: "0" }}
          >
            <Img
              w="full"
              pos="relative"
              zIndex="1"
              h={{ lg: "100%" }}
              objectFit="cover"
              src={successImage}
              alt="Screening talent"
            />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
